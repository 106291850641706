import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Card, Fade, Grid, Icon, Modal, Skeleton, Tooltip } from "@mui/material";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import dataAspirantes from "../data/dataAspirantes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
};

export default function ModalAspiranteDetalle({ open, handleClose, idAspirante, refetch }) {
  const [aspirante, setAspirante] = useState(null);
  const { estados } = dataAspirantes;
  const { handleSnackbar } = useContext(MessageManager);
  const { menu } = useContext(MenuContext);
  const [expandEstado, setExpandEstado] = useState(false);
  const [getAspirantes, { loading }] = useLazyQuery(
    gql`
      query getAspirantes($filter: JSON) {
        aspirantesDocente(pagination: { page: 1, limit: 1 }, filter: $filter) {
          id
          nombre
          apellido
          estado
          oficio
          curriculum
          fechaNacimiento
          tipoEstudios
          titulo
          preferenciaHorario
          oficio
          telefono
          email
          localidad {
            nombre
            provincia {
              nombre
            }
          }
          sedes {
            id
            nombre
          }
          carreras {
            id
            nombre
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [cambiarEstado] = useMutation(
    gql`
      mutation cambiarEstadoAspiranteDocente($id: ID!, $estado: String!) {
        cambiarEstadoAspiranteDocente(id: $id, estado: $estado)
      }
    `
  );

  useEffect(() => {
    if (open && idAspirante) {
      getAspirantes({
        variables: {
          filter: {
            id: idAspirante,
          },
        },
      }).then((res) => {
        setAspirante(res.data.aspirantesDocente[0]);
      });
    }
  }, [open, idAspirante]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Detalles del aspirante</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox
              p={{
                xs: 0,
                sm: 3,
              }}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <SoftBox
                display="flex"
                justifyContent="space-between"
                p={{
                  xs: 3,
                  sm: 0,
                }}
              >
                <SoftBox display="flex" alignItems="center">
                  <SoftButton
                    variant="color"
                    color={
                      estados.find((estado) => estado.nombre === aspirante?.estado)?.color ||
                      getUproColor("uproBlue")
                    }
                    circular
                    sx={{
                      textWrap: "nowrap",
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    onClick={(event) => {
                      setExpandEstado(true);
                      menu({
                        open: event.currentTarget,
                        align: "left",
                        onClose: () => {
                          setExpandEstado(false);
                        },
                        options: estados.map((estado) => {
                          return {
                            name: `Cambiar a ${estado.nombre}`,
                            icon: { icon: "arrow_forward", color: estado.color },
                            onClick: () => {
                              cambiarEstado({
                                variables: {
                                  id: aspirante?.id,
                                  estado: estado.nombre,
                                },
                              })
                                .then(() => {
                                  handleSnackbar(
                                    "Se ha cambiado el estado del aspirante",
                                    "success"
                                  );
                                  getAspirantes({
                                    variables: {
                                      filter: {
                                        id: idAspirante,
                                      },
                                    },
                                  }).then((res) => {
                                    setAspirante(res.data.aspirantesDocente[0]);
                                    refetch();
                                  });
                                })
                                .catch((err) => {
                                  handleSnackbar(
                                    "Ha ocurrido un error al cambiar el estado",
                                    "error"
                                  );
                                });
                            },
                          };
                        }),
                      });
                    }}
                  >
                    <Icon>autorenew</Icon>
                    &nbsp;{aspirante?.estado}&nbsp;
                    <Icon
                      sx={{
                        rotate: expandEstado ? "180deg" : "0deg",
                        transition: "all 0.5s",
                      }}
                    >
                      expand_more
                    </Icon>
                  </SoftButton>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" gap={1}>
                  <Tooltip title="Descargar curriculum" placement="top">
                    <SoftButton
                      fullWidth
                      iconOnly
                      color="primary"
                      circular
                      onClick={async () => {
                        const file = await fetch(`${API_URL}/${aspirante?.curriculum}`);
                        const blob = await file.blob();
                        saveAs(blob, `Curriculum ${aspirante?.apellido} ${aspirante?.nombre}.pdf`);
                        handleSnackbar(
                          "Se ha descargado el curriculum a su dispositivo",
                          "success"
                        );
                      }}
                    >
                      <Icon>description</Icon>
                    </SoftButton>
                  </Tooltip>
                  <Tooltip title="Teléfono" placement="top">
                    <SoftButton
                      fullWidth
                      iconOnly
                      color="uproYellow"
                      circular
                      onClick={(event, item) => {
                        menu({
                          open: event.currentTarget,
                          align: "right",
                          options: [
                            {
                              name: "Copiar",
                              icon: { icon: "content_copy", color: getUproColor("uproPink") },
                              onClick: () => {
                                navigator.clipboard.writeText(aspirante?.telefono);
                                handleSnackbar("Número copiado al portapapeles", "success");
                              },
                            },
                            {
                              name: "Enviar WhatsApp",
                              icon: { icon: "chat", color: getUproColor("uproGreen") },
                              onClick: async () => {
                                window.open(`https://wa.me/+549${aspirante?.telefono}`);
                              },
                            },
                            {
                              name: "Llamar",
                              icon: { icon: "phone", color: getUproColor("uproYellow") },
                              onClick: async () => {
                                window.open(`tel:${aspirante?.telefono}`);
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }}
                    >
                      <Icon>phone</Icon>
                    </SoftButton>
                  </Tooltip>
                  <Tooltip title="Email" placement="top">
                    <SoftButton
                      fullWidth
                      iconOnly
                      color="uproGreen"
                      circular
                      onClick={(event, item) => {
                        menu({
                          open: event.currentTarget,
                          align: "right",
                          options: [
                            {
                              name: "Copiar",
                              icon: { icon: "content_copy", color: getUproColor("uproPink") },
                              onClick: () => {
                                navigator.clipboard.writeText(aspirante?.email);
                                handleSnackbar("Email copiado al portapapeles", "success");
                              },
                            },
                            {
                              name: "Enviar email",
                              icon: { icon: "email", color: getUproColor("uproYellow") },
                              onClick: async () => {
                                window.open(`mailto:${aspirante?.email}`);
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }}
                    >
                      <Icon>email</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
              <Card>
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                      <SoftTypography variant="h6" fontWeight="light">
                        Nombre completo
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {loading ? (
                          <Skeleton variant="text" width={100} />
                        ) : (
                          makeNiceText(`${aspirante?.nombre} ${aspirante?.apellido}`, "nombre")
                        )}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox textAlign="right">
                      <SoftTypography variant="h6" fontWeight="light">
                        Edad
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {loading ? (
                          <Skeleton variant="text" width={100} />
                        ) : (
                          `${dayjs().diff(aspirante?.fechaNacimiento, "year")} años`
                        )}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        title={{ text: "Nivel de educación alcanzado" }}
                        direction="left"
                        count={makeNiceText(
                          `${aspirante?.tipoEstudios} ${aspirante?.titulo || ""}`,
                          "nombre"
                        )}
                        icon={{ color: "dark", component: "school" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        direction="left"
                        title={{ text: "Oficio" }}
                        count={makeNiceText(`${aspirante?.oficio}`, "nombre")}
                        icon={{ color: "dark", component: "handyman" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        direction="left"
                        title={{ text: "Preferencia de horario" }}
                        count={makeNiceText(`${aspirante?.preferenciaHorario}`, "nombre")}
                        icon={{ color: "dark", component: "access_time" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MiniStatisticsCard
                        direction="left"
                        title={{ text: "Localidad de residencia" }}
                        count={makeNiceText(
                          `${aspirante?.localidad?.nombre}, ${aspirante?.localidad?.provincia?.nombre}`,
                          "nombre"
                        )}
                        icon={{ color: "dark", component: "access_time" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          aspirante?.sedes?.length > 2
                            ? makeNiceText(
                                `${aspirante?.sedes?.map((sede) => sede.nombre).join(", ")}`,
                                "nombre"
                              )
                            : ""
                        }
                        placement="top"
                      >
                        <SoftBox>
                        <MiniStatisticsCard
                          direction="left"
                          title={{ text: "Sedes de interés" }}
                          count={
                            aspirante?.sedes?.length > 2
                              ? `${makeNiceText(
                                  `${aspirante?.sedes
                                    ?.map((sede) => sede.nombre)
                                    .slice(0, 2)
                                    .join(", ")}`,
                                  "nombre"
                                )}... +${aspirante?.sedes?.length - 2}`
                              : makeNiceText(
                                  `${aspirante?.sedes?.map((sede) => sede.nombre).join(", ")}`,
                                  "nombre"
                                )
                          }
                          icon={{ color: "dark", component: "account_balance" }}
                        />
                        </SoftBox>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          aspirante?.carreras?.length > 2
                            ? makeNiceText(
                                `${aspirante?.carreras?.map((sede) => sede.nombre).join(", ")}`,
                                "nombre"
                              )
                            : ""
                        }
                        placement="top"
                      >
                        <SoftBox>
                        <MiniStatisticsCard
                          direction="left"
                          title={{ text: "Carreras de interés" }}
                          count={
                            aspirante?.carreras?.length > 2
                              ? `${makeNiceText(
                                  `${aspirante?.carreras
                                    ?.map((sede) => sede.nombre)
                                    .slice(0, 2)
                                    .join(", ")}`,
                                  "nombre"
                                )}... +${aspirante?.carreras?.length - 2}`
                              : makeNiceText(
                                  `${aspirante?.carreras?.map((sede) => sede.nombre).join(", ")}`,
                                  "nombre"
                                )
                          }
                          icon={{ color: "dark", component: "work" }}
                        />
                        </SoftBox>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftButton
                        fullWidth
                        color="primary"
                        circular
                        onClick={async () => {
                          const file = await fetch(`${API_URL}/${aspirante?.curriculum}`);
                          const blob = await file.blob();
                          saveAs(
                            blob,
                            `Curriculum ${aspirante?.apellido} ${aspirante?.nombre}.pdf`
                          );
                          handleSnackbar(
                            "Se ha descargado el curriculum a su dispositivo",
                            "success"
                          );
                        }}
                      >
                        <Icon>description</Icon>&nbsp; Ver Curriculum
                      </SoftButton>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftButton
                        fullWidth
                        color="uproYellow"
                        circular
                        onClick={(event, item) => {
                          menu({
                            open: event.currentTarget,
                            align: "right",
                            options: [
                              {
                                name: "Copiar",
                                icon: { icon: "content_copy", color: getUproColor("uproPink") },
                                onClick: () => {
                                  navigator.clipboard.writeText(aspirante?.telefono);
                                  handleSnackbar("Número copiado al portapapeles", "success");
                                },
                              },
                              {
                                name: "Enviar WhatsApp",
                                icon: { icon: "chat", color: getUproColor("uproGreen") },
                                onClick: async () => {
                                  window.open(`https://wa.me/+549${aspirante?.telefono}`);
                                },
                              },
                              {
                                name: "Llamar",
                                icon: { icon: "phone", color: getUproColor("uproYellow") },
                                onClick: async () => {
                                  window.open(`tel:${aspirante?.telefono}`);
                                },
                              },
                            ],
                            handleClose: () => {},
                          });
                        }}
                      >
                        <Icon>phone</Icon>&nbsp; {aspirante?.telefono}
                      </SoftButton>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftButton
                        fullWidth
                        color="uproGreen"
                        circular
                        onClick={(event, item) => {
                          menu({
                            open: event.currentTarget,
                            align: "right",
                            options: [
                              {
                                name: "Copiar",
                                icon: { icon: "content_copy", color: getUproColor("uproPink") },
                                onClick: () => {
                                  navigator.clipboard.writeText(aspirante?.email);
                                  handleSnackbar("Email copiado al portapapeles", "success");
                                },
                              },
                              {
                                name: "Enviar email",
                                icon: { icon: "email", color: getUproColor("uproYellow") },
                                onClick: async () => {
                                  window.open(`mailto:${aspirante?.email}`);
                                },
                              },
                            ],
                            handleClose: () => {},
                          });
                        }}
                      >
                        <Icon>email</Icon>&nbsp; {aspirante?.email}
                      </SoftButton>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAspiranteDetalle.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idAspirante: PropTypes.number,
  refetch: PropTypes.func,
};
