import { Backdrop, Card, Fade, Icon, Modal, Tooltip } from "@mui/material";
import SoftBox from "components/CheckBox";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90vw",
  maxHeight: "90vh",
  outline: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function ModalImage({ open, handleClose, src }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={{ ...style, backgroundColor: "transparent", boxShadow: "none" }}>
          <SoftBox
            p={2}
            pb={0}
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              width: "100%",
            }}
            justifyContent="flex-end"
          >
            <Tooltip title="Cerrar" placement="top">
              <SoftButton color="primary" onClick={handleClose} circular iconOnly>
                <Icon>close</Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>
          <img
            src={src}
            alt="Imagen"
            style={{
              minWidth: "90vw",
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
          <SoftBox
            p={2}
            sx={{
              width: "100%",
              display: {
                xs: "block",
                sm: "none",
              },
            }}
          >
            <SoftButton color="primary" onClick={handleClose} circular fullWidth>
              Cerrar
            </SoftButton>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalImage.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  src: PropTypes.string,
};
