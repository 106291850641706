// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { UserContext } from "context/user";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalSeleccionSede({ open, handleClose, handleNext, docente }) {
  const [idSede, setIdSede] = useState();
  const [evaluaciones, setEvaluaciones] = useState([]);
  const { verificarAcceso } = useContext(UserContext);

  const [getEvaluacionesDocente, { loading }] = useLazyQuery(
    gql`
      query getEvaluacionesDocente($idDocente: ID!) {
        evaluacionesDocente(idDocente: $idDocente) {
          id
          puntaje
          resultado
          createdAt
          docente {
            id
          }
          sede {
            id
          }
          detalles {
            id
            pregunta
            respuesta
            area
            tipo
            value
          }
        }
      }
    `,
    {
      cachePolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );

  const [getSedes, { data: sedesData }] = useLazyQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
        }
      }
    `
  );

  useEffect(() => {
    if (open) {
      getSedes();
      getEvaluacionesDocente({ variables: { idDocente: docente.id } }).then((res) => {
        console.log(res.data.evaluacionesDocente);
        setEvaluaciones(res.data.evaluacionesDocente);
      });
      setIdSede(null);
    } else {
      setEvaluaciones([]);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Sede donde se evaluará al docente</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={!evaluaciones.length && !verificarAcceso(25)}>
              <SoftBox py={3}>
                <SoftBox
                  bgColor="primary"
                  p={2}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    borderRadius: 4,
                  }}
                >
                  <SoftTypography variant="h6" color="white">
                    No se encontraron evaluaciones para este docente
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Collapse>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Sede
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Sede"
                    name="idSede"
                    value={idSede || -1}
                    onChange={(e) => {
                      setIdSede(e.target.value);
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una sede
                    </MenuItem>
                    {verificarAcceso(25)
                      ? sedesData?.sedes?.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{
                                width: "100%",
                              }}
                            >
                              <SoftBox mr={2}>
                                <SoftTypography variant="p">{sede.nombre}</SoftTypography>
                              </SoftBox>
                              {evaluaciones.filter((evaluacion) => evaluacion.sede.id === sede.id)
                                .length ? (
                                <SoftBox>
                                  <Icon
                                    fontSize="small"
                                    sx={{
                                      verticalAlign: "middle",
                                      color: getUproColor("uproGreen"),
                                    }}
                                  >
                                    done_all
                                  </Icon>
                                </SoftBox>
                              ) : null}
                            </SoftBox>
                          </MenuItem>
                        ))
                      : sedesData?.sedes
                          ?.filter((sede) =>
                            evaluaciones.some((evaluacion) => evaluacion.sede.id === sede.id)
                          )
                          ?.map((sede) => (
                            <MenuItem key={sede.id} value={sede.id}>
                              {sede.nombre}
                            </MenuItem>
                          ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={!idSede}
                  onClick={() => {
                    handleNext(idSede);
                  }}
                >
                  Siguiente&nbsp;
                  {false ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginLeft: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>arrow_forward</Icon>
                  )}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSeleccionSede.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  docente: PropTypes.object,
};
