// React
import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, MenuItem, Modal, Select, Tooltip } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
import { gql, useLazyQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import dataAspirantes from "../data/dataAspirantes";
import ModalAspiranteDetalle from "../ModalDetallesAspirante";
import { makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
};

export default function ModalAspirantesDocente({ open, handleClose }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [selectedAspirante, setSelectedAspirante] = useState(null);
  const [openModalDetalles, setOpenModalDetalles] = useState(false);
  const [carreras, setCarreras] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });
  const { columns, getRows } = dataAspirantes;
  const { menu } = useContext(MenuContext);

  const [getCarreras] = useLazyQuery(
    gql`
      query getCarreras {
        carrerasPublic(filter: { tipo: "Carrera" }) {
          id
          nombre
          sedes {
            id
            nombre
          }
        }
      }
    `
  );

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [getAspirantes, { loading, data }] = useLazyQuery(
    gql`
      query getAspirantes($search: String, $page: Int, $order: Order, $filter: JSON) {
        paginationInfo {
          pages
          total
        }
        aspirantesDocente(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          estado
          nombre
          apellido
          oficio
          curriculum
          sedes {
            id
            nombre
          }
          carreras {
            id
            nombre
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (data) {
      setRows(
        getRows(data.aspirantesDocente, (event, item) => {
          menu({
            open: event.currentTarget,
            align: "right",
            options: [
              {
                name: "Ver detalles",
                icon: { icon: "visibility", color: getUproColor("uproGreen") },
                onClick: () => {
                  setSelectedAspirante(item);
                  setOpenModalDetalles(true);
                },
              },
              {
                name: "Curriculum",
                icon: { icon: "file_open", color: getUproColor("uproYellow") },
                onClick: async () => {
                  const file = await fetch(`${API_URL}/${item.curriculum}`);
                  const blob = await file.blob();
                  saveAs(blob, `Curriculum ${item.apellido} ${item.nombre}.pdf`);
                },
              },
            ],
            handleClose: () => {},
          });
        })
      );
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      getAspirantes({
        variables: {
          search: search || null,
          page: page,
          order: { field: orderBy.campo, order: orderBy.orden },
          filter: {
            idsSedes: filtro?.sede
              ? {
                  _substring: `"${filtro.sede}"`,
                }
              : undefined,
            idsCarreras: filtro?.carrera
              ? {
                  _substring: `"${filtro.carrera}"`,
                }
              : undefined,
          },
        },
      });
      getCarreras().then((res) => {
        let carreras = res.data.carrerasPublic;
        carreras = carreras.map((carrera) => {
          return { id: carrera.id, nombre: carrera.nombre };
        });
        carreras.sort((a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        });
        setCarreras(carreras);
        const sedes = [];
        res.data.carrerasPublic.forEach((carrera) => {
          carrera.sedes.forEach((sede) => {
            if (!sedes.find((s) => s.id === sede.id)) {
              sedes.push(sede);
            }
          });
        });
        setSedes(sedes);
      });
    }
  }, [open, search, page, orderBy, filtro]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
              p={3}
            >
              <SoftBox
                mb={{
                  xs: 2,
                  sm: 0,
                }}
                display="flex"
                justifyContent={{
                  xs: "space-between",
                  sm: "flex-start",
                }}
              >
                <SoftTypography variant="h6">Aspirantes a Docentes</SoftTypography>
                <SoftBox
                  display={{
                    xs: "block",
                    sm: "none",
                  }}
                >
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="flex-end"
                alignItems="center"
              >
                <SoftBox
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  gap={2}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftBox
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    <Select
                      value={filtro.carrera || -1}
                      onChange={(e) => {
                        if (e.target.value === -1) {
                          setFiltro({
                            ...filtro,
                            carrera: null,
                          });
                        } else {
                          setFiltro({
                            ...filtro,
                            carrera: e.target.value,
                          });
                        }
                      }}
                    >
                      <MenuItem value={-1}>Todas las carreras</MenuItem>
                      {carreras.map((carrera) => (
                        <MenuItem key={carrera.id} value={carrera.id}>
                          {`${makeNiceText(
                            carrera.nombre.length > 30
                              ? carrera.nombre.slice(0, 30) + "..."
                              : carrera.nombre,
                            "nombre"
                          )}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </SoftBox>
                  <SoftBox
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    <Select
                      value={filtro.sede || -1}
                      onChange={(e) => {
                        if (e.target.value === -1) {
                          setFiltro({
                            ...filtro,
                            sede: null,
                          });
                        } else {
                          setFiltro({
                            ...filtro,
                            sede: e.target.value,
                          });
                        }
                      }}
                    >
                      <MenuItem value={-1}>Todas las sedes</MenuItem>
                      {sedes.map((sede) => (
                        <MenuItem key={sede.id} value={sede.id}>
                          {sede.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </SoftBox>
                  <SoftBox
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    <SoftInput
                      placeholder="Escribe aquí..."
                      icon={{ component: "search", direction: "left" }}
                      onChange={handleSearch}
                    />
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={data?.aspirantesDocente.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </SoftBox>
            <ModalAspiranteDetalle
              open={openModalDetalles}
              handleClose={() => setOpenModalDetalles(false)}
              idAspirante={selectedAspirante?.id}
              refetch={() => {
                getAspirantes({
                  variables: {
                    search: search || null,
                    page: page,
                    order: { field: orderBy.campo, order: orderBy.orden },
                  },
                });
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAspirantesDocente.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
