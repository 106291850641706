// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { validateDNI, validateEmail } from "utils/validations";
import { validatePhone } from "utils/validations";
import { UserContext } from "context/user";
import ModalComentarios from "../ModalComentarios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalAlumno({ open, handleClose, refetch, idUsuario, tipo }) {
  const [usuario, setUsuario] = useState();
  const [selectedUsuario, setSelectedUsuario] = useState();
  const [newTempPassword, setNewTempPassword] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleChanges = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const [getUsuario, { data: dataUsuario }] = useLazyQuery(
    gql`
      query getUsuario($id: ID!) {
        usuario(id: $id) {
          id
          username
          email
          tipoUser
          tempPassword
          connected
          nombre
          apellido
          nroLegajo
          dni
          fechaNacimiento
          pais {
            id
          }
          genero
          esDiscapacitado
          discapacidad
          preferenciaHorario
          poseeBeneficiosSociales
          tipoEstudios
          localidad {
            id
            provincia {
              id
            }
          }
          nombreEscuela
          escalaMatematicas
          escalaComprensionTextos
          telefono
          telefonoRespaldo
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: paises } = useQuery(
    gql`
      query getPaises {
        paises {
          id
          nombre
          nacionalidad
        }
      }
    `
  );

  const [saveUsuario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveUser($input: UserInput!) {
        saveUser(input: $input) {
          id
        }
      }
    `
  );

  const [restoreTempPassword] = useMutation(
    gql`
      mutation restoreTempPassword($id: ID!) {
        restoreTempPassword(id: $id) {
          id
          tempPassword
        }
      }
    `
  );

  const [
    getPlantillas,
    { data: plantillas, refetch: refetchPlantillas, loading: loadingPlantillas },
  ] = useLazyQuery(
    gql`
      query getPlantillas($page: Int, $order: Order, $filter: JSON) {
        plantillas(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          nombre
          user {
            id
            nombre
            apellido
            username
          }
          plantillaDetalles {
            id
            permiso {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);


  const handleSave = () => {
    let userNew = { ...usuario };
    delete userNew.localidad;
    delete userNew.provincia;
    if (userNew.esDiscapacitado) {
      userNew.esDiscapacitado = userNew.esDiscapacitado === "Si";
    }
    if (userNew.poseeBeneficiosSociales) {
      userNew.poseeBeneficiosSociales = userNew.poseeBeneficiosSociales === "Si";
    }
    if (!userNew.id) {
      userNew.username = userNew.email;
    }
    saveUsuario({
      variables: {
        input: { ...userNew, tipoUser: "Alumno" },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Usuario guardado correctamente", "success");
        handleClose();
      })
      .catch((e) => {
        if (e.message) {
          handleSnackbar(e.message, "error");
        } else {
          handleSnackbar("Error al guardar el usuario", "error");
        }
      });
  };

  const handleResetPassword = () => {
    restoreTempPassword({
      variables: {
        id: selectedUsuario.id,
      },
    })
      .then((data) => {
        refetch();
        handleSnackbar("Contraseña temporal restaurada correctamente", "success");
        setNewTempPassword(data.data.restoreTempPassword.tempPassword);
      })
      .catch((e) => {
        handleSnackbar("Error al restaurar la contraseña temporal", "error");
      });
  };

  useEffect(() => {
    if (idUsuario && open) {
      getUsuario({ variables: { id: idUsuario } });
    }
  }, [idUsuario, open]);

  useEffect(() => {
    if (usuario?.idProvincia) {
      getLocalidades({ variables: { provincia: usuario?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === usuario?.idLocalidad)) {
          let userNew = { ...usuario };
          delete userNew.idLocalidad;
          setUsuario(userNew);
        }
      });
    }
  }, [usuario?.idProvincia]);

  useEffect(() => {
    setClicked(false);
    if (dataUsuario && idUsuario && open) {
      setUsuario({
        username: dataUsuario.usuario.username,
        id: dataUsuario.usuario.id || null,
        email: dataUsuario.usuario.email,
        nombre: dataUsuario.usuario.nombre,
        apellido: dataUsuario.usuario.apellido,
        dni: dataUsuario.usuario.dni,
        fechaNacimiento: dataUsuario.usuario.fechaNacimiento,
        idPais: dataUsuario.usuario.pais?.id,
        genero: dataUsuario.usuario.genero,
        esDiscapacitado: dataUsuario.usuario.esDiscapacitado ? "Si" : "No",
        discapacidad: dataUsuario.usuario.discapacidad,
        idLocalidad: dataUsuario.usuario.localidad?.id,
        idProvincia: dataUsuario.usuario.localidad?.provincia?.id,
        tipoEstudios: dataUsuario.usuario.tipoEstudios,
        nombreEscuela: dataUsuario.usuario.nombreEscuela,
        preferenciaHorario: dataUsuario.usuario.preferenciaHorario,
        poseeBeneficiosSociales: dataUsuario.usuario.poseeBeneficiosSociales ? "Si" : "No",
        escalaMatematicas: dataUsuario.usuario.escalaMatematicas,
        escalaComprensionTextos: dataUsuario.usuario.escalaComprensionTextos,
        telefono: dataUsuario.usuario.telefono,
        telefonoRespaldo: dataUsuario.usuario.telefonoRespaldo,
      });
      setSelectedUsuario(dataUsuario.usuario);
    } else {
      setUsuario();
      setSelectedUsuario();
    }
  }, [dataUsuario, idUsuario, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedUsuario
                    ? `Editar ${tipo.toLowerCase()}`
                    : `Agregar ${tipo.toLowerCase()}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox mr={2}>
                  <Tooltip title="Ver observaciones" placement="top">
                    <SoftButton
                      circular
                      color="uproYellow"
                      onClick={() => setOpenModalObservaciones(true)}
                    >
                      Observaciones&nbsp;
                      <Icon>comment</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="username">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre de Usuario
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="username"
                    placeholder="Se generará automáticamente"
                    type="text"
                    name="username"
                    value={usuario?.username || usuario?.email}
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="username">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Email de usuario
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="email"
                    placeholder="Ingrese el email de usuario"
                    type="text"
                    name="email"
                    value={usuario?.email || ""}
                    error={usuario?.email?.length > 0 && !validateEmail(usuario?.email)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre del usuario"
                    type="text"
                    name="nombre"
                    value={usuario?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="apellido">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Apellido
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="apellido"
                    placeholder="Ingrese el apellido del usuario"
                    type="text"
                    name="apellido"
                    value={usuario?.apellido || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <InputLabel htmlFor="dni">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      DNI
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="dni"
                    placeholder="Ingrese el dni del usuario"
                    type="text"
                    name="dni"
                    value={usuario?.dni || ""}
                    error={usuario?.dni?.length > 0 && !validateDNI(usuario?.dni)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <InputLabel htmlFor="fechaNacimiento">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha de nacimiento
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="fechaNacimiento"
                    type="date"
                    name="fechaNacimiento"
                    value={usuario?.fechaNacimiento || ""}
                    error={
                      usuario?.fechaNacimiento &&
                      (dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year")) ||
                        dayjs(usuario?.fechaNacimiento).isBefore(dayjs().subtract(100, "year")))
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="nacionalidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nacionalidad
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="idPais"
                    value={usuario?.idPais || usuario?.pais?.id || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {paises?.paises?.map((pais) => (
                      <MenuItem key={pais.id} value={pais.id}>
                        {pais.nacionalidad}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="genero">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Genero
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select name="genero" value={usuario?.genero || -1} onChange={handleChanges}>
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Masculino"}>Masculino</MenuItem>
                    <MenuItem value={"Femenino"}>Femenino</MenuItem>
                    <MenuItem value={"X"}>X</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <InputLabel htmlFor="esDiscapacitado">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Posee discapacidad
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="esDiscapacitado"
                    value={usuario?.esDiscapacitado || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Si"}>Si</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={
                    usuario?.esDiscapacitado === "No" || !usuario?.esDiscapacitado
                      ? {
                          paddingTop: "0 !important",
                          transition: "padding 0.5s",
                        }
                      : {
                          transition: "padding 0.5s",
                        }
                  }
                >
                  <Collapse in={usuario?.esDiscapacitado === "Si"}>
                    <InputLabel htmlFor="discapacidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Discapacidad
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="discapacidad"
                      placeholder="Detalle la discapacidad"
                      type="text"
                      name="discapacidad"
                      value={usuario?.discapacidad || ""}
                      onChange={handleChanges}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Provincia de residencia
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="provincia"
                    value={usuario?.idProvincia || -1}
                    onChange={(e) =>
                      setUsuario({
                        ...usuario,
                        idProvincia: e.target.value,
                        provincia: provincias?.provincias.find(
                          (provincia) => provincia.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {provincias?.provincias?.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.id}>
                        {provincia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="localidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Localidad de residencia
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="localidad"
                    value={usuario?.idLocalidad || -1}
                    onChange={(e) =>
                      setUsuario({
                        ...usuario,
                        idLocalidad: e.target.value,
                        localidad: localidades?.localidades.find(
                          (localidad) => localidad.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      {usuario?.idProvincia ? "Seleccione una" : "Seleccione una provincia"}
                    </MenuItem>
                    {localidades?.localidades?.map((localidad) => (
                      <MenuItem key={localidad.id} value={localidad.id}>
                        {localidad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="tipoEstudios">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nivel de educación alcanzado
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="tipoEstudios"
                    value={usuario?.tipoEstudios || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Primario Incompleto"}>Primario Incompleto</MenuItem>
                    <MenuItem value={"Primario Completo"}>Primario Completo</MenuItem>
                    <MenuItem value={"Secundario Incompleto"}>Secundario Incompleto</MenuItem>
                    <MenuItem value={"Secundario Completo"}>Secundario Completo</MenuItem>
                    <MenuItem value={"Terciario Incompleto"}>Terciario Incompleto</MenuItem>
                    <MenuItem value={"Terciario Completo"}>Terciario Completo</MenuItem>
                    <MenuItem value={"Universitario Incompleto"}>Universitario Incompleto</MenuItem>
                    <MenuItem value={"Universitario Completo"}>Universitario Completo</MenuItem>
                    <MenuItem value={"Posgrado Incompleto"}>Posgrado Incompleto</MenuItem>
                    <MenuItem value={"Posgrado Completo"}>Posgrado Completo</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="nombreEscuela">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre de la institución donde asistió
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombreEscuela"
                    placeholder="Ingrese el nombre de la última institución"
                    type="text"
                    name="nombreEscuela"
                    value={usuario?.nombreEscuela || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="telefono">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Teléfono de contacto
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="telefono"
                    placeholder="Código de área + número"
                    type="text"
                    name="telefono"
                    value={usuario?.telefono || ""}
                    error={usuario?.telefono?.length > 0 && !validatePhone(usuario?.telefono)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="telefonoRespaldo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Teléfono de contacto de respaldo
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="telefonoRespaldo"
                    placeholder="Código de área + número"
                    type="text"
                    name="telefonoRespaldo"
                    value={usuario?.telefonoRespaldo || ""}
                    error={
                      usuario?.telefonoRespaldo?.length > 0 &&
                      !validatePhone(usuario?.telefonoRespaldo)
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="preferenciaHorario">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Preferencia de horario del curso de nivelación
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="preferenciaHorario"
                    value={usuario?.preferenciaHorario || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una
                    </MenuItem>
                    <MenuItem value={"Mañana"}>Mañana</MenuItem>
                    <MenuItem value={"Tarde"}>Tarde</MenuItem>
                    <MenuItem value={"Noche"}>Noche</MenuItem>
                    <MenuItem value={"Indistinto"}>Indistinto</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="poseeBeneficiosSociales">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Posee beneficios sociales
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="poseeBeneficiosSociales"
                    value={usuario?.poseeBeneficiosSociales || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una
                    </MenuItem>
                    <MenuItem value={"Si"}>Si</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="escalaMatematicas">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Escala de conocimiento en matemáticas (1 a 10)
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="escalaMatematicas"
                    value={usuario?.escalaMatematicas || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una
                    </MenuItem>
                    {[...Array(10).keys()].map((i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="escalaComprensionTextos">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Escala de conocimiento en comprensión de textos (1 a 10)
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="escalaComprensionTextos"
                    value={usuario?.escalaComprensionTextos || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una
                    </MenuItem>
                    {[...Array(10).keys()].map((i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <SoftBox>
                {verificarAcceso(22) &&
                  selectedUsuario &&
                  (selectedUsuario?.tempPassword || newTempPassword) &&
                  selectedUsuario?.tipoUser !== "Administrador" && (
                    <Tooltip title="Copiar contraseña temporal" placement="top">
                      <SoftButton
                        color="uproYellow"
                        circular
                        onClick={() => {
                          setClicked(true);
                          if (newTempPassword) {
                            navigator.clipboard.writeText(newTempPassword);
                          } else if (selectedUsuario.tempPassword) {
                            navigator.clipboard.writeText(selectedUsuario.tempPassword);
                          }
                          handleSnackbar("Contraseña temporal copiada", "success");
                        }}
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                      >
                        <Icon sx={{ fontWeight: "light" }}>copy</Icon>
                        &nbsp;
                        {clicked
                          ? `Contraseña temporal: ${
                              newTempPassword || selectedUsuario.tempPassword
                            }`
                          : "Contraseña temporal"}
                      </SoftButton>
                    </Tooltip>
                  )}
                {verificarAcceso(22) &&
                  selectedUsuario &&
                  !selectedUsuario?.tempPassword &&
                  !newTempPassword &&
                  selectedUsuario?.tipoUser !== "Administrador" && (
                    <Tooltip title="Generar contraseña temporal nueva" placement="top">
                      <SoftButton
                        color="uproGreen"
                        circular
                        onClick={handleResetPassword}
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                      >
                        <Icon sx={{ fontWeight: "light" }}>vpn_key</Icon>
                        &nbsp;Reiniciar contraseña
                      </SoftButton>
                    </Tooltip>
                  )}
              </SoftBox>
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={handleClose}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="uproGreen"
                    circular
                    disabled={
                      loadingSave ||
                      !usuario?.email ||
                      !usuario?.nombre ||
                      !usuario?.apellido ||
                      !usuario?.dni ||
                      !usuario?.fechaNacimiento ||
                      !usuario?.preferenciaHorario ||
                      !usuario?.idPais ||
                      !usuario?.genero ||
                      !usuario?.esDiscapacitado ||
                      !usuario?.idLocalidad ||
                      !usuario?.idProvincia ||
                      !usuario?.tipoEstudios ||
                      !usuario?.nombreEscuela ||
                      !usuario?.poseeBeneficiosSociales ||
                      !usuario?.escalaMatematicas ||
                      !usuario?.telefono ||
                      !usuario?.escalaComprensionTextos ||
                      (usuario?.esDiscapacitado === "Si" && !usuario?.discapacidad) ||
                      (usuario?.dni?.length > 0 && !validateDNI(usuario?.dni)) ||
                      (usuario?.email?.length > 0 && !validateEmail(usuario?.email)) ||
                      (usuario?.telefonoRespaldo && !validatePhone(usuario?.telefonoRespaldo)) ||
                      (usuario?.telefono && !validatePhone(usuario?.telefono)) ||
                      (dataUsuario?.usuario &&
                        usuario?.email === dataUsuario?.usuario.email &&
                        usuario?.nombre === dataUsuario?.usuario.nombre &&
                        usuario?.apellido === dataUsuario?.usuario.apellido &&
                        usuario?.telefonoRespaldo === dataUsuario?.usuario.telefonoRespaldo &&
                        usuario?.dni === dataUsuario?.usuario.dni &&
                        usuario?.fechaNacimiento === dataUsuario?.usuario.fechaNacimiento &&
                        usuario?.idPais === dataUsuario?.usuario.pais?.id &&
                        usuario?.genero === dataUsuario?.usuario.genero &&
                        usuario?.esDiscapacitado ===
                          (dataUsuario?.usuario.esDiscapacitado ? "Si" : "No") &&
                        usuario?.discapacidad === dataUsuario?.usuario.discapacidad &&
                        usuario?.idLocalidad === dataUsuario?.usuario.localidad?.id &&
                        usuario?.idProvincia === dataUsuario?.usuario.localidad?.provincia?.id &&
                        usuario?.tipoEstudios === dataUsuario?.usuario.tipoEstudios &&
                        usuario?.nombreEscuela === dataUsuario?.usuario.nombreEscuela &&
                        usuario?.preferenciaHorario === dataUsuario?.usuario.preferenciaHorario &&
                        usuario?.poseeBeneficiosSociales ===
                          (dataUsuario?.usuario.poseeBeneficiosSociales ? "Si" : "No") &&
                        usuario?.escalaMatematicas === dataUsuario?.usuario.escalaMatematicas &&
                        usuario?.escalaComprensionTextos ===
                          dataUsuario?.usuario.escalaComprensionTextos)
                    }
                    onClick={handleSave}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          marginRight: 0.5,
                        }}
                        color="inherit"
                      />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <ModalComentarios
              open={openModalObservaciones}
              handleClose={() => setOpenModalObservaciones(false)}
              alumno={usuario}
              setCountComentarios={() => {}}
              readOnly={false}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAlumno.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  idUsuario: PropTypes.string,
  tipo: PropTypes.string,
};
