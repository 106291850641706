// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCupos({ open, handleClose, inscripcion }) {
  const [cupos, setCupos] = useState([]);
  const [selectedCupo, setSelectedCupo] = useState();
  const [preferenciaHorario, setPreferenciaHorario] = useState();
  const [getCarrera, { loading }] = useLazyQuery(
    gql`
      query getCarrera($id: ID!) {
        carrera(id: $id) {
          id
          nombre
          currentCupos {
            id
            nombre
            capacidad
            cantidadInscriptos
            sede {
              id
              nombre
            }
          }
        }
      }
    `,
    {
      cachePolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );
  const { handleSnackbar } = useContext(MessageManager);

  const [confirmarInscripcion, { loading: loadingConfirmar }] = useMutation(gql`
    mutation confirmarInscripcion($idInscripcion: ID!, $idCupo: ID, $preferenciaHorario: String) {
      confirmarInscripcion(idInscripcion: $idInscripcion, idCupo: $idCupo, preferenciaHorario: $preferenciaHorario) {
        id
      }
    }
  `);

  useEffect(() => {
    if (open && inscripcion) {
      getCarrera({ variables: { id: inscripcion.carrera.id } }).then((data) => {
        const dataCupos = data.data.carrera.currentCupos || [];
        setCupos(dataCupos.filter((cupo) => cupo.sede.id === inscripcion.sede.id));
      });
    } else {
      setCupos([]);
      setSelectedCupo();
      setPreferenciaHorario();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Selección de cupo</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={open}>
              <SoftBox py={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SoftBox>
                      <Select
                        fullWidth
                        value={preferenciaHorario || -1}
                        onChange={(e) => setPreferenciaHorario(e.target.value)}
                      >
                        <MenuItem value={-1} disabled>
                          Seleccione un horario de preferencia
                        </MenuItem>
                        <MenuItem value={"Mañana"}>Mañana</MenuItem>
                        <MenuItem value={"Tarde"}>Tarde</MenuItem>
                        <MenuItem value={"Noche"}>Noche</MenuItem>
                        <MenuItem value={"Indistinto"}>Indistinto</MenuItem>
                      </Select>
                    </SoftBox>
                  </Grid>
                  {cupos.length > 0 ? (
                    cupos.map((cupo) => (
                      <Grid item xs={12} key={cupo.id}>
                        <Card>
                          <SoftBox
                            p={2}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <SoftBox display="flex" alignItems="center" gap={1}>
                              <SoftTypography variant="h6">{`${cupo.nombre} - ${cupo.sede.nombre}`}</SoftTypography>
                              <SoftBadge
                                variant="color"
                                color={
                                  cupo.cantidadInscriptos >= cupo.capacidad
                                    ? getUproColor("uproPink")
                                    : getUproColor("uproYellow")
                                }
                                badgeContent={`${cupo.cantidadInscriptos} / ${cupo.capacidad}`}
                              />
                            </SoftBox>
                            <Tooltip title="Seleccionar" placement="top">
                              <SoftButton
                                color={selectedCupo === cupo.id ? "uproGreen" : "secondary"}
                                circular
                                disabled={
                                  loadingConfirmar ||
                                  cupo.cantidadInscriptos >= cupo.capacidad ||
                                  !preferenciaHorario
                                }
                                iconOnly
                                onClick={() => setSelectedCupo(cupo.id)}
                              >
                                <Icon>done</Icon>
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <SinDatos />
                  )}
                </Grid>
              </SoftBox>
            </Collapse>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={!selectedCupo || loadingConfirmar}
                  onClick={() => {
                    confirmarInscripcion({
                      variables: {
                        idInscripcion: inscripcion.id,
                        idCupo: selectedCupo,
                        preferenciaHorario,
                      },
                    })
                      .then(() => {
                        handleSnackbar("Inscripción confirmada correctamente", "success");
                        handleClose();
                      })
                      .catch((error) => {
                        handleSnackbar(
                          error.message || "Error al confirmar la inscripción",
                          "error"
                        );
                      });
                  }}
                >
                  Inscribir&nbsp;
                  {loadingConfirmar ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginLeft: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>done</Icon>
                  )}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCupos.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  inscripcion: PropTypes.object,
};
