import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Card, CircularProgress, Collapse, Grid, Icon, InputLabel, Tooltip } from "@mui/material";
import logo from "assets/images/logo.png";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import Footer from "components/Footer";
import PageLayout from "components/LayoutContainers/PageLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import JSZip from "jszip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import ModalDocumentacionAdd from "./components/ModalDocumentacionAdd";
import ModalFinalizado from "./components/ModalFinalizado";
import ModalImage from "components/Modals/ModalImage";
import Joyride from "react-joyride";
import PropTypes from "prop-types";

const CustomBeacon = ({ onClick }) => (
  <Tooltip title="Continuar tutorial" placement="top">
    <SoftButton
      onClick={onClick}
      color="primary"
      circular
      iconOnly
      sx={{
        cursor: "help",
        animation: "pulse 2s infinite",
        "@keyframes pulse": {
          "0%": {
            transform: "scale(1)",
          },
          "50%": {
            transform: "scale(1.1)",
          },
          "100%": {
            transform: "scale(1)",
          },
        },
      }}
    >
      <Icon>question_mark</Icon>
    </SoftButton>
  </Tooltip>
);

const tutorialVersion = 1;
const steps = [
  {
    target: "#examenLayout",
    content:
      "¡Bienvenido al tutorial de examen! Ahora te mostraremos cómo realizar tu primer examen a través de la plataforma.",
  },
  {
    target: "#examenSteps",
    content:
      "Aquí podrás ver las preguntas del examen y seleccionar la que desees responder. Se mostrará en rosa la pregunta que estás respondiendo actualmente, en verde las que ya hayas respondido y en gris las que aún no has respondido.",
  },
  {
    target: "#tiempoRestante",
    content:
      "En esta sección podrás ver el tiempo restante para finalizar el examen. Una vez agotado el tiempo, se cerrará automáticamente con las preguntas que hayas guardado hasta el momento y no podrás continuar respondiendo.",
  },
  {
    target: "#documentacion",
    content:
      "Aquí podrás adjuntar los documentos que te sean requeridos para el examen. Por ejemplo, una foto del papel donde realizaste los calculos. Para hacerlo, haz click en el botón con el ícono de + y selecciona el archivo.",
  },
  {
    target: "#instrucciones",
    content:
      "En esta sección podrás leer las instrucciones que designó el profesor para realizar examen. Es importante que las leas con atención antes de comenzar a responder las preguntas.",
  },
  {
    target: "#pregunta",
    content:
      "En esta sección podrás ver la pregunta que debes responder. Dependiendo del tipo de pregunta, deberás seleccionar una opción o escribir la respuesta en el campo de texto. Esta respuesta se guardará al avanzar a la siguiente pregunta.",
  },
  {
    target: "#anterior",
    content:
      "Si deseas volver a la pregunta anterior, haz click en este botón. La respuesta actual se guardará automáticamente.",
  },
  {
    target: "#siguiente",
    content:
      "Si deseas avanzar a la siguiente pregunta, haz click en este botón. La respuesta actual se guardará automáticamente.",
  },
  {
    target: "#finalizarMovil",
    content:
      "Una vez hayas respondido todas las preguntas, haz click en este botón para finalizar el examen. No podrás volver a responder las preguntas una vez finalizado.",
  },
  {
    target: "#finalizar",
    content:
      "Una vez hayas respondido todas las preguntas, haz click en este botón para finalizar el examen. No podrás volver a responder las preguntas una vez finalizado.",
  },
  {
    target: "#botonTutorial",
    content: "Podés volver a ver este tutorial en cualquier momento haciendo click en este botón.",
  },
  {
    target: "#examenLayout",
    content: "¡Eso es todo! Ahora podés comenzar a responder el examen. ¡Buena suerte!",
  },
];

const GET_EXAMEN = gql`
  query getExamen($id: ID!) {
    inscripcion(id: $id) {
      id
      fechaInicio
      fechaFinEstimada
      tiempoRestante
      estado
      documentos {
        id
        nombre
        file
        createdAt
      }
      respuestas {
        pregunta {
          id
        }
        respuesta
      }
      examen {
        id
        titulo
        textos
        materia {
          nombre
        }
        preguntas {
          id
          titulo
          archivo
          tipo
          opciones {
            opcion
          }
        }
      }
    }
  }
`;

const GET_DOCUMENTOS = gql`
  query getDocumentos($id: ID!) {
    inscripcion(id: $id) {
      documentos {
        id
        nombre
        file
        createdAt
      }
    }
  }
`;

const SAVE_RESPUESTA = gql`
  mutation guardarRespuesta($idInscripcion: ID!, $idPregunta: ID!, $respuesta: String!) {
    guardarRespuesta(idInscripcion: $idInscripcion, idPregunta: $idPregunta, respuesta: $respuesta)
  }
`;

const FINALIZAR_EXAMEN = gql`
  mutation finalizarExamen($idInscripcion: ID!) {
    finalizarExamen(idInscripcion: $idInscripcion)
  }
`;

function Examen() {
  const { state } = useLocation();
  const { inscripcion } = state || {};
  const [files, setFiles] = useState([]);
  const [examen, setExamen] = useState(null);
  const [inscripcionData, setInscripcionData] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);
  const [step, setStep] = useState(0);
  const [currentRespuesta, setCurrentRespuesta] = useState("");
  const [respuestas, setRespuestas] = useState({});
  const [minutosRestantes, setMinutosRestantes] = useState(0);
  const [openModalFinalizado, setOpenModalFinalizado] = useState(false);
  const [ocultarInstrucciones, setOcultarInstrucciones] = useState(false);
  const { confirmar } = useContext(ConfirmarContext);
  const [expanded, setExpanded] = useState(["Documentación"]);
  const [openModalAddDocumentacion, setOpenModalAddDocumentacion] = useState(false);
  const { menu } = useContext(MenuContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isJoyrideActive, setIsJoyrideActive] = useState(false);
  const joyrideRef = useRef(null);

  const [getExamen, { loading }] = useLazyQuery(GET_EXAMEN, {
    fetchPolicy: "no-cache",
    cachePolicy: "no-cache",
  });

  const [getDocumentos, { loading: loadingDocs }] = useLazyQuery(GET_DOCUMENTOS, {
    fetchPolicy: "no-cache",
    cachePolicy: "no-cache",
  });

  const [deleteDocumento] = useMutation(gql`
    mutation deleteDocumento($id: ID!) {
      deleteExamenFile(id: $id)
    }
  `);

  const [saveRespuesta, { loading: loadingSave }] = useMutation(SAVE_RESPUESTA);

  const [finalizarExamen] = useMutation(FINALIZAR_EXAMEN, {
    onCompleted: () => {
      setOpenModalFinalizado(true);
    },
    onError: (error) => {
      handleSnackbar(
        error.message || "Ha ocurrido un error al finalizar el examen, contactate con soporte.",
        "error"
      );
    },
  });

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    for (let file of files) {
      const res = await fetch(`${API_URL}/${file.file}`);
      let filename = file.nombre + " - " + dayjs(file.createdAt).format("DD-MM-YYYY") + ".pdf";
      const blob = await res.blob();
      zip.file(filename, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `Documentación - ${examen?.titulo} - ${dayjs().format("DD-MM-YYYY")}.zip`);
    });
  };

  useEffect(() => {
    if (inscripcion?.id) {
      getExamen({ variables: { id: inscripcion.id } })
        .then((res) => {
          if (res?.data?.inscripcion?.examen) {
            setInscripcionData(res.data.inscripcion);
            setExamen(res.data.inscripcion.examen);
            setMinutosRestantes(res.data.inscripcion.tiempoRestante);
          } else {
            handleSnackbar("error", "Error al obtener el examen");
          }
        })
        .catch((error) => {
          handleSnackbar(error.message || "Ha ocurrido un error al obtener el examen", "error");
        });
      getDocumentos({ variables: { id: inscripcion.id } }).then((res) => {
        if (res?.data?.inscripcion?.documentos) {
          setFiles(res.data.inscripcion.documentos);
        } else {
          handleSnackbar("error", "Error al obtener los documentos");
        }
      });
    }
  }, [inscripcion, getExamen, getDocumentos]);

  useEffect(() => {
    if (examen) {
      const preguntaId = examen.preguntas[step]?.id;
      if (preguntaId && inscripcionData?.respuestas) {
        const respuestaGuardada = inscripcionData.respuestas.find(
          (respuesta) => respuesta.pregunta.id === preguntaId
        )?.respuesta;
        setCurrentRespuesta(respuestas[preguntaId] || respuestaGuardada || "");
      }
    }
  }, [examen, step, inscripcionData, respuestas]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMinutosRestantes((prevMinutos) => prevMinutos - 1);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if ((minutosRestantes === 0 && examen?.id) || inscripcionData?.estado === "Finalizado") {
      finalizarExamen({ variables: { idInscripcion: inscripcion.id } });
    }
  }, [minutosRestantes, inscripcionData]);

  const handleNavigation = (newStep) => {
    const preguntaId = examen.preguntas[step].id;
    const currentAnswer = currentRespuesta;

    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [preguntaId]: currentAnswer,
    }));

    saveRespuesta({
      variables: {
        idInscripcion: inscripcion.id,
        idPregunta: preguntaId,
        respuesta: currentAnswer || "",
      },
    })
      .then(() => {
        setStep(newStep);
        const nuevaPreguntaId = examen.preguntas[newStep].id;
        setCurrentRespuesta(respuestas[nuevaPreguntaId] || "");
        getExamen({ variables: { id: inscripcion.id } })
          .then((res) => {
            if (res?.data?.inscripcion?.examen) {
              setInscripcionData(res.data.inscripcion);
              setExamen(res.data.inscripcion.examen);
              setMinutosRestantes(res.data.inscripcion.tiempoRestante);
            } else {
              handleSnackbar("error", "Error al obtener el examen");
            }
          })
          .catch((error) => {
            handleSnackbar(error.message || "Ha ocurrido un error al obtener el examen", "error");
          });
      })
      .catch((error) => {
        handleSnackbar(error.message || "Ha ocurrido un error al guardar la respuesta", "error");
        getExamen({ variables: { id: inscripcion.id } })
          .then((res) => {
            if (res?.data?.inscripcion?.examen) {
              setInscripcionData(res.data.inscripcion);
              setExamen(res.data.inscripcion.examen);
              setMinutosRestantes(res.data.inscripcion.tiempoRestante);
            } else {
              handleSnackbar("error", "Error al obtener el examen");
            }
          })
          .catch((error) => {
            handleSnackbar(error.message || "Ha ocurrido un error al obtener el examen", "error");
          });
      });
  };

  const handleNext = () => {
    if (step < examen?.preguntas?.length - 1) {
      handleNavigation(step + 1);
    } else {
      // Save the current answer and finalize the exam
      const preguntaId = examen.preguntas[step].id;
      const currentAnswer = currentRespuesta;

      setRespuestas((prevRespuestas) => ({
        ...prevRespuestas,
        [preguntaId]: currentAnswer,
      }));

      saveRespuesta({
        variables: {
          idInscripcion: inscripcion.id,
          idPregunta: preguntaId,
          respuesta: currentAnswer || "",
        },
      })
        .then(() => {
          confirmar({
            title: "Finalizar examen",
            message: `¿Está seguro que desea finalizar el examen? Esta acción no se puede deshacer.`,
            icon: "done_all",
            func: () => {
              finalizarExamen({ variables: { idInscripcion: inscripcion.id } });
            },
          });
        })
        .catch((error) => {
          handleSnackbar(error.message || "Ha ocurrido un error al guardar la respuesta", "error");
          getExamen({ variables: { id: inscripcion.id } })
            .then((res) => {
              if (res?.data?.inscripcion?.examen) {
                setInscripcionData(res.data.inscripcion);
                setExamen(res.data.inscripcion.examen);
                setMinutosRestantes(res.data.inscripcion.tiempoRestante);
              } else {
                handleSnackbar("error", "Error al obtener el examen");
              }
            })
            .catch((error) => {
              handleSnackbar(error.message || "Ha ocurrido un error al obtener el examen", "error");
            });
        });
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      handleNavigation(step - 1);
    }
  };

  const handleSelectRespuesta = (preguntaId, respuesta) => {
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [preguntaId]: respuesta,
    }));
    setCurrentRespuesta(respuesta);
  };

  useEffect(() => {
    const tutorialVersionStorage = localStorage.getItem("tutorialExamenVersion");
    if (tutorialVersionStorage !== tutorialVersion.toString()) {
      setIsJoyrideActive(true);
    }else{
      setIsJoyrideActive(false);
    }
  }, [step]);

  const handleRestartJoyride = () => {
    setIsJoyrideActive(false);
    setTimeout(() => {
      setIsJoyrideActive(true);
    }, 100);
  };

  if (loading) {
    return (
      <SoftBox display="flex" justifyContent="center" alignItems="center" height="100vh">
        <SoftBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <SoftBox component="img" src={logo} alt="UPrO Logo" width="150px" p={3} />
          <CircularProgress size={30} color="primary" />
        </SoftBox>
      </SoftBox>
    );
  }

  return (
    <PageLayout>
      <SoftBox p={3}>
        <Joyride
          run={isJoyrideActive && !openModalFinalizado}
          ref={joyrideRef}
          locale={{
            back: "Atrás",
            close: "Cerrar",
            last: "Entendido",
            next: "Siguiente",
            skip: "Omitir",
            open: "Continuar tutorial",
          }}
          steps={steps}
          continuous
          styles={{
            options: {
              zIndex: 9999,
            },
            overlay: {
              zIndex: 9999,
            },
            tooltip: {
              zIndex: 9999,
              borderRadius: 10,
            },
            buttonBack: {
              backgroundColor: getUproColor("uproYellow"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonNext: {
              backgroundColor: getUproColor("uproGreen"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonSkip: {
              backgroundColor: getUproColor("uproPink"),
              color: "#fff",
              borderRadius: "10rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0.5rem 1rem",
              fontFamily: "inherit",
            },
            buttonClose: {
              display: "none",
            },
          }}
          beaconComponent={CustomBeacon}
          callback={(data) => {
            if (data.status === "finished" || data.status === "skipped") {
              localStorage.setItem("tutorialExamenVersion", tutorialVersion);
            }
          }}
          disableOverlayClose
          showSkipButton
        />
        <DashboardNavbar />
        <SoftBox
          p={{
            xs: 0,
            sm: 3,
          }}
        >
          <Grid container spacing={2} mb={3} id="examenLayout">
            <Grid item xs={12} md={3}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <SoftBox component="img" src={logo} alt="UPrO Logo" width="150px" />
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox
                      textAlign={{
                        xs: "center",
                        sm: "left",
                      }}
                    >
                      <SoftTypography variant="h6">{`${examen?.materia?.nombre} - ${examen?.titulo}`}</SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item container spacing={1} xs={12} id="examenSteps">
                    {examen?.preguntas?.map((pregunta, index) => (
                      <Grid item key={index} xs={3} sm={2}>
                        <SoftBox display="flex" justifyContent="center" alignItems="center">
                          <SoftButton
                            color={
                              step === index
                                ? "primary"
                                : inscripcionData?.respuestas.find(
                                    (respuesta) =>
                                      respuesta.pregunta.id === pregunta.id &&
                                      respuesta.respuesta?.length > 0
                                  )
                                ? "uproGreen"
                                : "secondary"
                            }
                            iconOnly
                            circular
                            size="large"
                            onClick={() => handleNavigation(index)}
                            disabled={minutosRestantes === 0 || openModalFinalizado}
                          >
                            {index + 1}
                          </SoftButton>
                        </SoftBox>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox>
                      <Card id="tiempoRestante">
                        <SoftBox
                          p={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <SoftTypography variant="h6">Tiempo restante</SoftTypography>
                          <SoftTypography variant="h4" color="primary">
                            {minutosRestantes > 0
                              ? `${minutosRestantes} minuto${minutosRestantes !== 1 ? "s" : ""}`
                              : "Finalizado"}
                          </SoftTypography>
                        </SoftBox>
                      </Card>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      id="documentacion"
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        py={1}
                        sx={{
                          borderBottom: !expanded.includes("Documentación")
                            ? "none"
                            : "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">description</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            {`Documentación adjunta`}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                          <SoftBox mr={1}>
                            <Tooltip placement="top" title="Descargar todos">
                              <SoftButton
                                color="primary"
                                iconOnly
                                circular
                                onClick={handleDownloadAll}
                                disabled={
                                  files.length === 0 ||
                                  minutosRestantes === 0 ||
                                  openModalFinalizado
                                }
                              >
                                <Icon sx={{ fontWeight: "regular" }}>download</Icon>
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                          <SoftBox>
                            <Tooltip placement="top" title="Agregar nuevo">
                              <SoftButton
                                color="uproGreen"
                                iconOnly
                                circular
                                disabled={minutosRestantes === 0 || openModalFinalizado}
                                onClick={() => {
                                  setOpenModalAddDocumentacion(true);
                                }}
                              >
                                <Icon sx={{ fontWeight: "regular" }}>add</Icon>
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <Collapse in={expanded.includes("Documentación")}>
                        {loadingDocs ? (
                          <Loading />
                        ) : files.length > 0 ? (
                          files.map((documento, index) => (
                            <SoftBox key={documento.id}>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    files.length - 1 === index
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                              >
                                <SoftBox display="flex" justifyContent="space-between">
                                  <SoftBox display="flex" alignItems="center" pl={2}>
                                    <SoftBox mr={1}>
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproPink"),
                                        }}
                                      >
                                        subdirectory_arrow_right
                                      </Icon>
                                    </SoftBox>
                                    <SoftBox mr={1}>
                                      <Tooltip title={documento?.nombre} placement="top">
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproYellow"),
                                          }}
                                        >
                                          file_present
                                        </Icon>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftTypography variant="h6">{documento.nombre}</SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                  >
                                    <SoftBox>
                                      <Tooltip title={"Opciones"} placement="top">
                                        <SoftButton
                                          color="uproYellow"
                                          circular
                                          iconOnly
                                          disabled={minutosRestantes === 0 || openModalFinalizado}
                                          onClick={(event) => {
                                            menu({
                                              open: event.currentTarget,
                                              align: "right",
                                              options: [
                                                {
                                                  name: "Descargar",
                                                  icon: {
                                                    icon: "file_download",
                                                    color: "info",
                                                  },
                                                  onClick: async () => {
                                                    const file = await fetch(
                                                      `${API_URL}/${documento.file}`
                                                    );
                                                    const blob = await file.blob();
                                                    saveAs(blob, documento.nombre);
                                                  },
                                                },

                                                {
                                                  name: "Eliminar",
                                                  icon: {
                                                    icon: "delete",
                                                    color: "error",
                                                  },
                                                  onClick: () => {
                                                    confirmar({
                                                      title: "Eliminar documento",
                                                      message: `¿Está seguro que desea eliminar el documento ${documento.nombre}?`,
                                                      icon: "delete",
                                                      func: () => {
                                                        deleteDocumento({
                                                          variables: { id: documento.id },
                                                        }).then(() => {
                                                          handleSnackbar(
                                                            "Documento eliminado correctamente",
                                                            "success"
                                                          );
                                                          getDocumentos({
                                                            variables: { id: inscripcion.id },
                                                          })
                                                            .then((res) => {
                                                              if (
                                                                res?.data?.inscripcion?.documentos
                                                              ) {
                                                                setFiles(
                                                                  res.data.inscripcion.documentos
                                                                );
                                                              } else {
                                                                handleSnackbar(
                                                                  "error",
                                                                  "Error al obtener los documentos"
                                                                );
                                                              }
                                                            })
                                                            .catch((err) => {
                                                              handleSnackbar(
                                                                err.message ||
                                                                  "Ha ocurrido un error al obtener los documentos",
                                                                "error"
                                                              );
                                                            });
                                                        });
                                                      },
                                                    });
                                                  },
                                                },
                                              ],
                                              handleClose: () => {},
                                            });
                                          }}
                                        >
                                          <Icon>more_vert</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          ))
                        ) : (
                          <Grid container spacing={2} p={2}>
                            <SinDatos />
                          </Grid>
                        )}
                      </Collapse>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftButton
                      color="uproYellow"
                      fullWidth
                      circular
                      id="botonTutorial"
                      onClick={handleRestartJoyride}
                    >
                      Tutorial de examen&nbsp;
                      <Icon>help_outline</Icon>
                    </SoftButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                  >
                    <SoftButton
                      color="primary"
                      fullWidth
                      id="finalizar"
                      circular
                      disabled={
                        minutosRestantes === 0 || openModalFinalizado || loading || loadingSave
                      }
                      onClick={() => {
                        const preguntaId = examen.preguntas[step].id;
                        const currentAnswer = currentRespuesta;

                        setRespuestas((prevRespuestas) => ({
                          ...prevRespuestas,
                          [preguntaId]: currentAnswer,
                        }));

                        saveRespuesta({
                          variables: {
                            idInscripcion: inscripcion.id,
                            idPregunta: preguntaId,
                            respuesta: currentAnswer || "",
                          },
                        })
                          .then(() => {
                            confirmar({
                              title: "Finalizar examen",
                              message: `¿Está seguro que desea finalizar el examen? Esta acción no se puede deshacer.`,
                              icon: "done_all",
                              func: () => {
                                finalizarExamen({ variables: { idInscripcion: inscripcion.id } });
                              },
                            });
                          })
                          .catch((error) => {
                            handleSnackbar(
                              error.message || "Ha ocurrido un error al guardar la respuesta",
                              "error"
                            );
                            getExamen({ variables: { id: inscripcion.id } })
                              .then((res) => {
                                if (res?.data?.inscripcion?.examen) {
                                  setInscripcionData(res.data.inscripcion);
                                  setExamen(res.data.inscripcion.examen);
                                  setMinutosRestantes(res.data.inscripcion.tiempoRestante);
                                } else {
                                  handleSnackbar("error", "Error al obtener el examen");
                                }
                              })
                              .catch((error) => {
                                handleSnackbar(
                                  error.message || "Ha ocurrido un error al obtener el examen",
                                  "error"
                                );
                              });
                          });
                      }}
                    >
                      Finalizar Examen&nbsp;
                      <Icon>send</Icon>
                    </SoftButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={9}>
              <Card id="instrucciones">
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Instrucciones</SoftTypography>
                    <Tooltip title="Ocultar instrucciones" placement="top">
                      <SoftButton
                        color="primary"
                        iconOnly
                        circular
                        onClick={() => setOcultarInstrucciones(!ocultarInstrucciones)}
                      >
                        <Icon
                          sx={{
                            rotate: !ocultarInstrucciones ? "180deg" : "0deg",
                            transition: "all 0.5s",
                          }}
                        >
                          expand_more
                        </Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <Collapse in={!ocultarInstrucciones}>
                    <SoftTypography
                      variant="body2"
                      fontWeight="light"
                      mt={2}
                      sx={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {examen?.textos || "No hay instrucciones para este examen."}
                    </SoftTypography>
                  </Collapse>
                </SoftBox>
              </Card>
              <Card
                sx={{
                  mt: examen?.textos ? 2 : 0,
                }}
                id="pregunta"
              >
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      gap={2}
                      alignItems="center"
                      flexDirection={{
                        xs: "column",
                        sm: "row",
                      }}
                    >
                      <SoftTypography variant="h6" color="primary">{`Pregunta Nº${
                        step + 1
                      }`}</SoftTypography>
                      {examen?.preguntas[step]?.archivo && (
                        <SoftButton
                          color="primary"
                          circular
                          onClick={() =>
                            setSelectedImage(`${API_URL}/${examen?.preguntas[step]?.archivo}`)
                          }
                        >
                          Expandir imágen&nbsp;
                          <Icon>open_in_full</Icon>
                        </SoftButton>
                      )}
                    </SoftBox>
                    {examen?.preguntas[step]?.archivo && (
                      <SoftBox mt={2} display="flex" alignItems="center">
                        <Tooltip title="Click para ver en pantalla completa" placement="top">
                          <SoftBox
                            component="img"
                            src={`${API_URL}/${examen?.preguntas[step]?.archivo}`}
                            alt={examen?.preguntas[step]?.titulo}
                            borderRadius="lg"
                            sx={{
                              height: {
                                xs: "auto",
                                sm: "200px",
                              },
                              width: {
                                xs: "100%",
                                sm: "auto",
                              },
                              "&:hover": {
                                cursor: "pointer",
                                opacity: 0.8,
                              },
                            }}
                            onClick={() =>
                              setSelectedImage(`${API_URL}/${examen?.preguntas[step]?.archivo}`)
                            }
                          />
                        </Tooltip>
                      </SoftBox>
                    )}
                    <SoftBox mt={2} display="flex" alignItems="center">
                      <SoftTypography variant="h5">
                        {examen?.preguntas[step]?.titulo}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mt={2}>
                      {examen?.preguntas[step]?.tipo === "multipleChoice" ? (
                        examen?.preguntas[step]?.opciones?.map((opcion, index) => (
                          <SoftBox mt={2} key={index}>
                            <Card>
                              <SoftBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                p={2}
                              >
                                <SoftTypography variant="h6">{opcion.opcion}</SoftTypography>
                                <SoftButton
                                  color={
                                    respuestas[examen.preguntas[step].id] === opcion.opcion
                                      ? "uproGreen"
                                      : "secondary"
                                  }
                                  iconOnly
                                  circular
                                  onClick={() =>
                                    handleSelectRespuesta(examen.preguntas[step].id, opcion.opcion)
                                  }
                                  disabled={minutosRestantes === 0 || openModalFinalizado}
                                >
                                  <Icon>check</Icon>
                                </SoftButton>
                              </SoftBox>
                            </Card>
                          </SoftBox>
                        ))
                      ) : (
                        <SoftBox>
                          <InputLabel htmlFor="respuesta">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Respuesta
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            id="respuesta"
                            label="respuesta"
                            placeholder="Ingrese su respuesta"
                            type="text"
                            value={currentRespuesta}
                            onChange={(e) =>
                              handleSelectRespuesta(examen.preguntas[step].id, e.target.value)
                            }
                            disabled={minutosRestantes === 0 || openModalFinalizado}
                          />
                        </SoftBox>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox
                      display="flex"
                      flexDirection={{
                        xs: "column",
                        sm: "row",
                      }}
                      justifyContent="space-between"
                    >
                      <SoftButton
                        id="anterior"
                        color="uproYellow"
                        circular
                        onClick={handlePrev}
                        disabled={
                          step === 0 || minutosRestantes === 0 || openModalFinalizado || loadingSave
                        }
                        sx={{
                          mb: {
                            xs: 2,
                            sm: 0,
                          },
                        }}
                      >
                        {loadingSave ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <Icon
                            sx={{
                              marginRight: 0.5,
                            }}
                          >
                            arrow_back
                          </Icon>
                        )}
                        {loadingSave ? "" : "Anterior"}
                      </SoftButton>
                      <SoftButton
                        color={step === examen?.preguntas?.length - 1 ? "primary" : "uproGreen"}
                        circular
                        id="siguiente"
                        onClick={handleNext}
                        disabled={minutosRestantes === 0 || openModalFinalizado || loadingSave}
                      >
                        {loadingSave
                          ? ""
                          : step === examen?.preguntas?.length - 1
                          ? "Finalizar"
                          : "Siguiente"}
                        {loadingSave ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : step === examen?.preguntas?.length - 1 ? (
                          <Icon
                            sx={{
                              marginLeft: 0.5,
                            }}
                          >
                            send
                          </Icon>
                        ) : (
                          <Icon
                            sx={{
                              marginLeft: 0.5,
                            }}
                          >
                            arrow_forward
                          </Icon>
                        )}
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                  {examen?.preguntas?.length !== step + 1 && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                        },
                      }}
                    >
                      <SoftButton
                        color="primary"
                        id="finalizarMovil"
                        fullWidth
                        circular
                        disabled={
                          minutosRestantes === 0 || openModalFinalizado || loading || loadingSave
                        }
                        onClick={() => {
                          const preguntaId = examen.preguntas[step].id;
                          const currentAnswer = currentRespuesta;

                          setRespuestas((prevRespuestas) => ({
                            ...prevRespuestas,
                            [preguntaId]: currentAnswer,
                          }));

                          saveRespuesta({
                            variables: {
                              idInscripcion: inscripcion.id,
                              idPregunta: preguntaId,
                              respuesta: currentAnswer || "",
                            },
                          })
                            .then(() => {
                              confirmar({
                                title: "Finalizar examen",
                                message: `¿Está seguro que desea finalizar el examen? Esta acción no se puede deshacer.`,
                                icon: "done_all",
                                func: () => {
                                  finalizarExamen({ variables: { idInscripcion: inscripcion.id } });
                                },
                              });
                            })
                            .catch((error) => {
                              handleSnackbar(
                                error.message || "Ha ocurrido un error al guardar la respuesta",
                                "error"
                              );
                              getExamen({ variables: { id: inscripcion.id } })
                                .then((res) => {
                                  if (res?.data?.inscripcion?.examen) {
                                    setInscripcionData(res.data.inscripcion);
                                    setExamen(res.data.inscripcion.examen);
                                    setMinutosRestantes(res.data.inscripcion.tiempoRestante);
                                  } else {
                                    handleSnackbar("error", "Error al obtener el examen");
                                  }
                                })
                                .catch((error) => {
                                  handleSnackbar(
                                    error.message || "Ha ocurrido un error al obtener el examen",
                                    "error"
                                  );
                                });
                            });
                        }}
                      >
                        Finalizar Examen&nbsp;
                        <Icon>send</Icon>
                      </SoftButton>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <ModalFinalizado open={openModalFinalizado} />
        <ModalDocumentacionAdd
          open={openModalAddDocumentacion}
          handleClose={() => setOpenModalAddDocumentacion(false)}
          idInscripcion={inscripcion.id}
          refetch={() => {
            getDocumentos({ variables: { id: inscripcion.id } }).then((res) => {
              if (res?.data?.inscripcion?.documentos) {
                setFiles(res.data.inscripcion.documentos);
              } else {
                handleSnackbar("error", "Error al obtener los documentos");
              }
            });
          }}
        />
        <ModalImage
          open={selectedImage !== null}
          handleClose={() => setSelectedImage(null)}
          src={selectedImage}
        />
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

CustomBeacon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default React.memo(Examen);
