// Kaizen Dashboard layouts
import ErrorHandler from "components/ErrorHandler";
import SignIn from "layouts/authentication/sign-in";
import Soporte from "layouts/soporte";
import Usuarios from "layouts/usuarios";

//PDFs

// Kaizen Dashboard icons
import {
  HistoryEdu,
  ManageAccounts,
  Person,
  Quiz,
  Restaurant,
  School,
  SupervisorAccount,
} from "@mui/icons-material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Alumnos from "layouts/alumnos";
import Preinscripcion from "layouts/authentication/preinscripcion";
import Autogestion from "layouts/autogestion";
import AutogestionDocente from "layouts/autogestion-docente";
import Carreras from "layouts/carreras";
import Certificado from "layouts/certificado";
import Comedor from "layouts/comedor";
import CreadorExamenes from "layouts/creador-examenes";
import Docentes from "layouts/docentes";
import Egresados from "layouts/egresados";
import Examen from "layouts/examen";
import Acreditados from "layouts/egresados/acreditados";
import FormularioDocentes from "layouts/authentication/formularioDocentes";

const routes = [
  {
    type: "collapse",
    name: "Soporte",
    key: "soporte",
    route: "/soporte",
    icon: <SupportAgentIcon />,
    component: <Soporte />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    route: "/usuarios",
    icon: <ManageAccounts />,
    component: <Usuarios />,
    noCollapse: true,
    permiso: "Usuarios",
  },
  {
    type: "collapse",
    name: "Alumnos",
    key: "alumnos",
    route: "/alumnos",
    icon: <School />,
    component: <Alumnos />,
    noCollapse: true,
    permiso: "Alumnos",
  },
  {
    type: "collapse",
    name: "Docentes",
    key: "docentes",
    route: "/docentes",
    icon: <SupervisorAccount />,
    component: <Docentes />,
    noCollapse: true,
    permiso: "Docentes",
  },
  {
    type: "collapse",
    name: "Carreras",
    key: "carreras",
    route: "/carreras",
    icon: <HistoryEdu />,
    component: <Carreras />,
    noCollapse: true,
    permiso: "Carreras",
  },
  {
    type: "collapse",
    name: "Autogestión",
    key: "autogestion",
    route: "/autogestion",
    icon: <Person />,
    component: <Autogestion />,
    noCollapse: true,
    permiso: "Autogestion",
  },
  {
    type: "collapse",
    name: "Comedor",
    key: "comedor",
    route: "/comedor",
    icon: <Restaurant />,
    component: <Comedor />,
    noCollapse: true,
    permiso: "Comedor",
  },
  {
    type: "collapse",
    name: "Egresados",
    key: "egresados",
    route: "/egresados",
    icon: <HistoryEdu />,
    component: <Egresados />,
    noCollapse: true,
    permiso: "Egresados",
  },
  {
    key: "acreditados",
    route: "/egresados/acreditados",
    component: <Acreditados />,
    noCollapse: true,
    permiso: "Egresados",
  },
  {
    type: "collapse",
    name: "Autogestión Docente",
    key: "autogestion-docente",
    route: "/autogestion-docente",
    icon: <Person />,
    component: <AutogestionDocente />,
    noCollapse: true,
    permiso: "AutogestionDocentes",
  },
  {
    route: "/error",
    component: <ErrorHandler />,
  },
  {
    route: "/login",
    component: <SignIn />,
  },
  {
    route: "/certificado/:id",
    component: <Certificado />,
  },
  {
    key: "creador-examenes",
    route: "/carreras/creador-examenes",
    icon: <Person />,
    component: <CreadorExamenes />,
    noCollapse: true,
    permiso: "CreadorExamenes",
  },
  {
    key: "examen",
    route: "/examen",
    icon: <Quiz />,
    component: <Examen />,
    noCollapse: true,
    permiso: "Autogestion",
  },
  // {
  //   route: "/acceso-alumnos",
  //   component: <AccesoAlumnos />,
  // },
  {
    route: "/preinscripcion",
    component: <Preinscripcion />,
  },
  {
    route: "/formulario-instructor",
    component: <FormularioDocentes />,
  },
];

export default routes;
