import { gql, useMutation } from "@apollo/client";
import { Card, CircularProgress, Fade, Grid, Icon, Modal, Skeleton, Tooltip } from "@mui/material";
import cover from "assets/images/cover.png";
import logo from "assets/images/logo.png";
import logo_alt from "assets/images/logo_alt.png";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
};

export default function ModalCertificadoLibre({ open, handleClose, sede, alumno, carrera }) {
  const [addCertificado, { loading: loadingAdd, data: dataCertificado }] = useMutation(gql`
    mutation addCertificado($input: CertificadoInput!) {
      addCertificado(input: $input) {
        id
      }
    }
  `);

  const [updateCertificado] = useMutation(gql`
    mutation updateCertificado($file: Upload!, $id: ID!) {
      updateCertificado(file: $file, id: $id) {
        id
      }
    }
  `);

  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const generatePDF = () => {
    addCertificado({
      variables: {
        input: {
          idUser: alumno.id,
          idCarrera: carrera.id,
          tipo: "Certificado de Inscripción Libre",
        },
      },
    }).then((data) => {
      setLoadingGenerate(true);
      const element = document.getElementById("certificado");

      setTimeout(() => {
        if (element) {
          html2canvas(element, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 0.7);

            // Debugging: Show the captured image in an <img> tag
            const img = document.createElement("img");
            img.src = imgData;
            document.body.appendChild(img);

            const report = new jsPDF({
              orientation: "portrait",
              compress: true,
            });

            const pdfWidth = report.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            report.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            report.save(
              `${data.data.addCertificado.id} - Certificado de Inscripción Libre - ${alumno?.dni}.pdf`
            );

            //Actualizar certificado con el archivo
            updateCertificado({
              variables: {
                file: new File(
                  [report.output("blob")],
                  `${data.data.addCertificado.id} - Certificado de Inscripción Libre - ${alumno?.dni}.pdf`
                ),
                id: data.data.addCertificado.id,
              },
            });

            //remove the image from the body
            img.remove();
            setLoadingGenerate(false);
          });
        }
      }, 500);
    });
  };

  useEffect(() => {
    if (open && carrera && alumno) {
      setTimeout(() => {
        generatePDF();
      }, 500);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Boolean(open)}
      onClose={() => {
        if (!loadingAdd && !loadingGenerate) handleClose();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Certificado generado</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        if (!loadingAdd && !loadingGenerate) handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox id="certificado" p={3}>
              <SoftBox position="relative">
                <SoftBox
                  display="flex"
                  alignItems="top"
                  position="relative"
                  maxHeight="200px"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.primary.main, 0.6),
                        rgba(gradients.primary.state, 0.6)
                      )}, url(${cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                >
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    p={4}
                    mb={4}
                    alignItems={"center"}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <SoftBox component="img" src={logo_alt} alt="UPrO Logo" width="150px" p={3} />
                  </SoftBox>
                </SoftBox>
                <Card
                  sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 1),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 2,
                    py: 2,
                    px: 2,
                  }}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                      <SoftTypography variant="h6" fontWeight="light">
                        Número de certificado
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dataCertificado?.addCertificado?.id || (
                          <Skeleton variant="text" width={100} />
                        )}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox textAlign="right">
                      <SoftTypography variant="h6" fontWeight="light">
                        Fecha de emisión
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dayjs().format("DD/MM/YYYY HH:mm")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <SoftTypography variant="h5" fontWeight="bold" textAlign="center">
                        Certificado de Inscripción Libre
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftTypography variant="h6" fontWeight="light">
                        El presente certificado acredita que el alumno{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {makeNiceText(`${alumno?.nombre} ${alumno?.apellido}`, "nombre")}
                        </span>
                        , DNI{" "}
                        <span style={{ fontWeight: "bold" }}>{formatMoneyPunto(alumno?.dni)}</span>{" "}
                        ha realizado la inscripción como{" "}
                        <span style={{ fontWeight: "bold" }}>ALUMNO LIBRE</span> del curso{" "}
                        <span
                          style={{ fontWeight: "bold" }}
                        >{`${carrera?.codigo} - ${carrera?.nombre}`}</span>{" "}
                        en la sede{" "}
                        <span style={{ fontWeight: "bold" }}>{`${sede?.nombre} ${
                          sede?.id == 2 ? "(Sede centenario)" : ""
                        }`}</span>{" "}
                        de la Universidad Provincial de Oficios. El examen libre se realizará el día
                        Martes 28 de Enero del presente año en la sede anteriormente mencionada,
                        siendo el examen de Matemáticas a las 13:00 hs y el de Comprensión de Textos
                        a las 15:00 hs.
                      </SoftTypography>
                    </Grid>
                  </Grid>
                  <SoftBox display="flex" justifyContent="space-between" mt={3} alignItems="end">
                    <SoftBox component="img" src={logo} alt="UPrO Logo" width="90px" />
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="center">
                    <SoftBox textAlign="center">
                      <SoftTypography variant="caption" fontWeight="light">
                        Powered by&nbsp;
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        UPrO
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3} pt={0} display="flex" justifyContent="flex-end">
              <SoftButton color="primary" onClick={generatePDF} circular disabled={false}>
                {false ? (
                  <CircularProgress
                    size={15}
                    sx={{
                      marginRight: 0.5,
                    }}
                    color="white"
                  />
                ) : (
                  <Icon>download</Icon>
                )}
                &nbsp;Descargar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCertificadoLibre.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  sede: PropTypes.object,
  alumno: PropTypes.object,
  carrera: PropTypes.object,
};
