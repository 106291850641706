/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("uproBlue"),
                }}
                fontSize="lg"
              >
                person
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Alumno"} placement="top">
                <Icon
                  color="primary"
                  sx={{
                    verticalAlign: "middle",
                  }}
                  fontSize="lg"
                >
                  person
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{}}
            data={makeNiceText(`${item?.alumno?.apellido} ${item?.alumno?.nombre}`, "nombre")}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(`${item?.alumno?.apellido} ${item?.alumno?.nombre}`, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        nota: (
          <SoftBox data={item?.nota || 0}>
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nota || 0}
            </SoftTypography>
          </SoftBox>
        ),
        estadoExamen: (
          <SoftBox data={item?.acta?.estado} pr={3}>
            <SoftBadge
              color={
                item?.acta?.estado === "Aprobado"
                  ? "uproGreen"
                  : item?.acta?.estado === "Desaprobado"
                  ? "primary"
                  : "primary"
              }
              badgeContent={item?.acta?.estado}
            />
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "nota", desc: "Nota", align: "left", noOrder: true },
  { name: "estadoExamen", desc: "Estado Examen", align: "right", noOrder: true },
];

export default { columns, getRows };
