import { gql, useQuery } from "@apollo/client";
import { Card, Collapse, Grid, Icon, Tooltip } from "@mui/material";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { useApolloClient } from "context/apolloClientContext";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import ModalDetallesPedido from "./components/ModalDetallesPedido";
import ModalNuevoPedido from "./components/ModalNuevoPedido";
import ProfileCard from "./components/ProfileCard";
import dataPedidos from "./data/dataPedidos";

function Comedor() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openModalPedidoDetalle, setOpenModalPedidoDetalle] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "id",
    orden: "DESC",
  });
  const { menu } = useContext(MenuContext);
  const [expanded, setExpanded] = useState(["Pedidos"]);
  const [openModalNuevoPedido, setOpenModalNuevoPedido] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const client = useApolloClient("clientComedor");

  const { columns, getRows } = dataPedidos;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { loading, data, refetch } = useQuery(
    gql`
      query misPedidos($search: String, $page: Int, $order: Order, $app: Apps) {
        paginationInfo {
          pages
          total
        }
        misPedidos(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          app: $app
        ) {
          id
          estado
          total
          createdAt
          updatedAt
          fechaPedido
          cliente {
            nombre
            apellido
          }
          detalles {
            id
            numeroComanda
            cantidad
            precio
          }
          mesa {
            numero
          }
          venta {
            id
            tipoFactura
            idAnulada
          }
          mozo {
            nombre
            apellido
          }
        }
      }
    `,
    {
      client,
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        app: "Alumnos",
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: usuario } = useQuery(gql`
    query getUser {
      currentUser {
        id
        tipoUser
        username
        nombre
        apellido
        email
        dni
      }
    }
  `);

  useEffect(() => {
    if (data) {
      setRows(
        getRows(data.misPedidos, (event, item) => {
          menu({
            open: event.currentTarget,
            align: "right",
            options: [
              {
                name: "Ver detalles",
                icon: { icon: "visibility", color: getUproColor("uproGreen") },
                onClick: () => {
                  setSelectedPedido(item);
                  setOpenModalPedidoDetalle(true);
                },
              },
            ],
            handleClose: () => {},
          });
        })
      );
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <ProfileCard
                        usuario={usuario?.currentUser}
                        loading={loading}
                        handleClick={() => setTab(2)}
                        handleAddPedido={() => setOpenModalNuevoPedido(true)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Card>
                        <SoftBox
                          p={{
                            xs: 0,
                            sm: 2,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Card
                                sx={{
                                  border: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  px={2}
                                  py={1}
                                  pt={{
                                    xs: 2,
                                    sm: 1,
                                  }}
                                  gap={2}
                                  flexDirection={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                  sx={{
                                    borderBottom: !expanded.includes("Pedidos")
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center">
                                    <SoftBox mr={1} display="flex" alignItems="center">
                                      <Icon color="primary">receipt</Icon>
                                    </SoftBox>
                                    <SoftTypography variant="h6" fontWeight="bold">
                                      Pedidos realizados
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <SoftBox
                                      sx={{
                                        width: {
                                          xs: "100%",
                                          sm: "auto",
                                        },
                                      }}
                                    >
                                      <SoftInput
                                        placeholder="Escribe aquí..."
                                        icon={{ component: "search", direction: "left" }}
                                        onChange={handleSearch}
                                      />
                                    </SoftBox>
                                    <SoftBox
                                      display={{
                                        xs: "none",
                                        sm: "flex",
                                      }}
                                    >
                                      <Tooltip title="Nuevo pedido" placement="top">
                                        <SoftButton
                                          color="uproGreen"
                                          circular
                                          onClick={() => setOpenModalNuevoPedido(true)}
                                        >
                                          <Icon>add_shopping_cart</Icon>
                                          &nbsp; Nuevo pedido
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftBox
                                      display={{
                                        xs: "flex",
                                        sm: "none",
                                      }}
                                    >
                                      <Tooltip title="Nuevo pedido" placement="top">
                                        <SoftButton
                                          color="uproGreen"
                                          circular
                                          iconOnly
                                          onClick={() => setOpenModalNuevoPedido(true)}
                                        >
                                          <Icon>add_shopping_cart</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                                <Collapse in={expanded.includes("Pedidos")}>
                                  <SoftBox
                                    sx={{
                                      "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                          borderBottom: ({
                                            borders: { borderWidth, borderColor },
                                          }) => `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                      },
                                    }}
                                  >
                                    {loading ? (
                                      <Loading />
                                    ) : rows?.length > 0 ? (
                                      <DataTable
                                        columns={columns}
                                        rows={rows}
                                        order={orderBy}
                                        setOrder={handleOrderBy}
                                      />
                                    ) : (
                                      <SinDatos />
                                    )}
                                  </SoftBox>
                                  <CustomPagination
                                    loading={loading}
                                    length={data?.misPedidos.length}
                                    total={pagesInfo?.total}
                                    pages={pagesInfo?.pages}
                                    page={page}
                                    setPage={setPage}
                                    noCard
                                  />
                                </Collapse>
                              </Card>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </Card>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
          </Card>
          <ModalNuevoPedido
            open={openModalNuevoPedido}
            handleClose={() => setOpenModalNuevoPedido(false)}
            refetch={refetch}
          />
          <ModalDetallesPedido
            open={openModalPedidoDetalle}
            handleClose={() => {
              setOpenModalPedidoDetalle(false);
              setSelectedPedido(null);
            }}
            idPedido={selectedPedido?.id}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Comedor;
