// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  CircularProgress,
  circularProgressClasses,
  Collapse,
  Fade,
  Grid,
  Icon,
  Modal,
  Slider,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  maxHeight: "90%",
  overflowY: "scroll",
  p: 3,
};

const marks = [
  {
    value: 0,
    text: "Insuficiente / Nunca",
  },
  {
    value: 25,
    text: "Regular / Rara vez",
  },
  {
    value: 50,
    text: "Bueno / Ocasionalmente",
  },
  {
    value: 75,
    text: "Muy bueno / Casi siempre",
  },
  {
    value: 100,
    text: "Excelente / Siempre",
  },
];

const preguntas = [
  {
    titulo: "Dimensión Profesional",
    area: "Coordinador",
    idPermiso: 25,
    preguntas: [
      {
        value: 50,
        pregunta:
          "Desempeña con seguridad su rol (tener en cuenta si es INSTRUCTOR O AUXILAR), evidenciando responsabilidad, autonomia, entusiasmo y compromiso con la tarea que desarrolla.",
      },
      {
        value: 50,
        pregunta: "Propicia y genera relaciones de respeto con los/las alumnos/as.",
      },
      {
        value: 50,
        pregunta:
          "Respeta y hace cumplir (según su rol) las clases espejo previamente coordinadas en el equipo de trabajo.",
      },
    ],
  },
  {
    titulo: "Dimensión Administrativa e Institucional",
    idPermiso: 25,
    area: "Coordinador",
    preguntas: [
      {
        value: 50,
        pregunta:
          "Cumple en tiempo y forma las tareas administrativas relativas a su función de Instructor/Auxiliar ( Correccion de examenes y TP, carga y cierre de actas, asistencia, etc.)",
      },
      {
        value: 50,
        pregunta:
          "Evidencia compromiso y participación en las actividades propuestas por la institución. (Participacion en eventos, actos academicos, capacitaciones etc.)",
      },
      {
        value: 50,
        pregunta:
          "Se responsaboliza por los materiales, herramientas y equipos que se utilizan en el aula/taller durante la clase.",
      },
    ],
  },
  {
    titulo: "Dimensión Conocimiento Técnico",
    idPermiso: 25,
    area: "Coordinador",
    preguntas: [
      {
        value: 50,
        pregunta: "Posee nivel de experiencia y conocimiento técnico que posee para el puesto.",
      },
      {
        value: 50,
        pregunta: "Sabe transmitir sus conocimientos tecnicos de una manera clara y efectiva.",
      },
      {
        value: 50,
        pregunta: "Respeta las medidas de Higiene y Seguridad requeridas por el puesto.",
      },
    ],
  },
  {
    titulo: "Dimensión Cualitativa",
    idPermiso: 25,
    area: "Coordinador",
    preguntas: [
      { value: 0, pregunta: "¿Volvería a contratar a este docente?", tipo: "boolean" },
      {
        value: "",
        pregunta:
          "Evaluacion cualitativa sobre el desempeño (Comentarios sobre el desempeño del evaluado o cualquier aspecto relevante que desees destacar. Indica si lo volverías a contratar o no, para qué nivel o puesto específico en caso de tecnicaturas, y menciona puntos de mejora, aspectos positivos y/o negativos, entre otros.)",
        tipo: "text",
      },
    ],
  },
  {
    titulo: "Recursos Humanos",
    idPermiso: 26,
    area: "Recursos Humanos",
    preguntas: [
      { value: 50, pregunta: "Nivel de ausentismo" },
      { value: 0, pregunta: "¿Volvería a contratar a este docente?", tipo: "boolean" },
      {
        value: "",
        pregunta:
          "Volvería a contratar al evaluado debido a que presenta comunicación asertiva y respetuosa frente a los alumnos, pares y superiores. Cumple con su jornada de trabajo en su totalidad y realiza solicitudes de permisos y entrega de certificados médicos en tiempo y forma. Respeta la normativa institucional, se adecua a los valores de la UPrO y cuenta con una presencia adecuada para el dictado de clases. Responde a reuniones, comunicaciones y observaciones de la institución. Además, posee sólidos conocimientos y habilidades técnicas, así como iniciativa y proactividad en eventos organizados por la universidad.",
        tipo: "text",
      },
    ],
  },
];

export default function ModalPuntaje({ open, handleClose, docente, idSede }) {
  const [step, setStep] = useState(0);
  const [scorePercentage, setScorePercentage] = useState(0);
  const { verificarAcceso } = useContext(UserContext);
  const { handleSnackbar } = useContext(MessageManager);
  const [evaluacion, setEvaluacion] = useState([]);
  const [evaluacionHeader, setEvaluacionHeader] = useState();
  const { confirmar } = useContext(ConfirmarContext);

  const [getEvaluacionDocente, { loadingGet }] = useLazyQuery(
    gql`
      query getEvaluacionDocente($idDocente: ID!, $idSede: ID!) {
        evaluacionDocente(idDocente: $idDocente, idSede: $idSede) {
          id
          puntaje
          resultado
          createdAt
          detalles {
            id
            pregunta
            respuesta
            area
            tipo
            value
          }
        }
      }
    `,
    {
      cachePolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );

  const [saveEvaluacionDocente, { loading }] = useMutation(gql`
    mutation saveEvaluacionDocente($input: EvaluacionDocenteInput!) {
      saveEvaluacionDocente(input: $input) {
        id
      }
    }
  `);

  const getGradient = (value) => {
    if (value <= 25) return "linear-gradient(310deg, #ea0606, #ff3d59)";
    if (value <= 50) return "linear-gradient(310deg, #f53939, #fac60b)";
    if (value <= 75) return "linear-gradient(310deg, #2152ff, #02c6f3)";
    return "linear-gradient(310deg, #17ad37, #84dc14)";
  };

  const getColor = (value) => {
    if (value <= 25) return "#ff3d59";
    if (value <= 50) return "#fac60b";
    if (value <= 75) return "#02c6f3";
    return "#84dc14";
  };

  useEffect(() => {
    const preguntas = evaluacion
      .slice(0, 3)
      .map((dimension) => {
        return dimension.preguntas.map((pregunta) => pregunta);
      })
      .flat();
    setScorePercentage(
      Math.round(preguntas.reduce((acc, pregunta) => acc + pregunta.value, 0) / preguntas.length)
    );
  }, [evaluacion]);

  useEffect(() => {
    const preguntasFiltradas = preguntas.filter((dimension) =>
      verificarAcceso(dimension.idPermiso)
    );
    if (!open) {
      setStep(0);
      preguntasFiltradas.forEach((dimension) => {
        dimension.preguntas.forEach((pregunta) => {
          pregunta.value = pregunta.tipo === "boolean" ? 0 : pregunta.tipo === "text" ? "" : 50;
        });
      });
      setEvaluacion(preguntasFiltradas);
      setEvaluacionHeader();
      setScorePercentage(0);
    } else {
      setEvaluacion(preguntasFiltradas);
      getEvaluacionDocente({
        variables: {
          idDocente: docente.id,
          idSede: idSede,
        },
      })
        .then((response) => {
          if (response?.data?.evaluacionDocente) {
            setEvaluacionHeader({
              id: response.data.evaluacionDocente.id,
              estado: response.data.evaluacionDocente.estado,
              puntaje: response.data.evaluacionDocente.puntaje,
              resultado: response.data.evaluacionDocente.resultado,
            });
            const preguntasNew = preguntasFiltradas.map((dimension) => {
              return {
                ...dimension,
                preguntas: dimension.preguntas.map((pregunta) => {
                  const preguntaDocente = response.data.evaluacionDocente.detalles.find(
                    (item) => item.pregunta === pregunta.pregunta && item.area === dimension.area
                  );
                  if (preguntaDocente) {
                    return {
                      ...pregunta,
                      value:
                        preguntaDocente.tipo === "boolean"
                          ? preguntaDocente.value
                            ? 1
                            : 0
                          : preguntaDocente.value || preguntaDocente.respuesta,
                      id: preguntaDocente.id || null,
                    };
                  }
                  return pregunta;
                }),
              };
            });
            setEvaluacion(preguntasNew);
            if (
              preguntasNew
                .map((dimension) => dimension.preguntas)
                .flat()
                .filter((pregunta) => !pregunta.id).length === 0
            ) {
              setStep(preguntasNew.length);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        if (!loading) handleClose();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Puntaje del docente</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={evaluacion.length} unmountOnExit>
              {evaluacion
                .map((dimension) => dimension.preguntas)
                .flat()
                .filter((pregunta) => !pregunta.id).length && evaluacion.length ? (
                <SoftBox>
                  <SoftBox>
                    <SoftTypography variant="h5" color="primary">
                      {evaluacion[step].titulo}
                    </SoftTypography>
                  </SoftBox>
                  <Collapse in={!verificarAcceso(evaluacion[step].idPermiso)}>
                    <SoftBox py={3}>
                      <SoftBox
                        bgColor="primary"
                        p={2}
                        display="flex"
                        justifyContent="center"
                        sx={{
                          borderRadius: 4,
                        }}
                      >
                        <SoftTypography variant="h6" color="white">
                          No tienes permisos para realizar esta evaluación
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Collapse>
                  <SoftBox py={3}>
                    {evaluacion[step].preguntas.map((pregunta, index) => (
                      <Fade in={true} key={`${step}-${index}`}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <SoftTypography variant="h6" mr={1} fontWeight="regular">
                              {pregunta.pregunta}
                            </SoftTypography>
                            {!pregunta.tipo ? (
                              <SoftBox my={4}>
                                <SoftBox
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  gap={2}
                                  pt={2}
                                >
                                  <Icon fontSize="large">sentiment_dissatisfied</Icon>
                                  <Slider
                                    getAriaValueText={(value) => {
                                      return marks.find((mark) => mark.value === value)?.text;
                                    }}
                                    step={25}
                                    value={pregunta.value}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(value) => {
                                      return marks.find((mark) => mark.value === value)?.text;
                                    }}
                                    onChange={(event, value) => {
                                      if (!verificarAcceso(evaluacion[step].idPermiso)) return;
                                      setEvaluacion((prev) => {
                                        const newEvaluacion = [...prev];
                                        newEvaluacion[step].preguntas[index].value = value;
                                        return newEvaluacion;
                                      });
                                    }}
                                    sx={{
                                      "& .MuiSlider-track": {
                                        height: 10,
                                        backgroundImage: getGradient(pregunta.value),
                                        transition: "background-image 0.5s ease-in-out",
                                      },
                                      "& .MuiSlider-rail": {
                                        width: "100%",
                                        height: 10,
                                        backgroundColor: "#e9ecef",
                                      },
                                      "& .MuiSlider-valueLabelLabel": {
                                        color: "#344767",
                                      },
                                    }}
                                  />
                                  <Icon fontSize="large">sentiment_very_satisfied</Icon>
                                </SoftBox>
                              </SoftBox>
                            ) : pregunta.tipo === "boolean" ? (
                              <SoftBox my={4}>
                                <Tabs
                                  value={pregunta.value ? pregunta.value : 0}
                                  onChange={(_, value) => {
                                    if (!verificarAcceso(evaluacion[step].idPermiso)) return;
                                    setEvaluacion((prev) => {
                                      const newEvaluacion = [...prev];
                                      newEvaluacion[step].preguntas[index].value = value;
                                      return newEvaluacion;
                                    });
                                  }}
                                >
                                  <Tab
                                    label={
                                      <SoftTypography
                                        variant="h6"
                                        fontWeight={pregunta.value === 0 ? "bold" : "regular"}
                                      >
                                        No
                                      </SoftTypography>
                                    }
                                    value={0}
                                  />
                                  <Tab
                                    label={
                                      <SoftTypography
                                        variant="h6"
                                        fontWeight={pregunta.value === 1 ? "bold" : "regular"}
                                      >
                                        Sí
                                      </SoftTypography>
                                    }
                                    value={1}
                                  />
                                </Tabs>
                              </SoftBox>
                            ) : (
                              <SoftBox my={4}>
                                <SoftInput
                                  multiline
                                  fullWidth
                                  rows={4}
                                  value={pregunta.value}
                                  onChange={(event) => {
                                    if (!verificarAcceso(evaluacion[step].idPermiso)) return;
                                    setEvaluacion((prev) => {
                                      const newEvaluacion = [...prev];
                                      newEvaluacion[step].preguntas[index].value =
                                        event.target.value;
                                      return newEvaluacion;
                                    });
                                  }}
                                  placeholder="Escriba aquí su comentario"
                                />
                              </SoftBox>
                            )}
                          </Grid>
                        </Grid>
                      </Fade>
                    ))}
                  </SoftBox>
                </SoftBox>
              ) : (
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  justifyContent="center"
                  my={4}
                  alignItems="center"
                >
                  <SoftTypography variant="h5" color="primary">
                    Resultado de la evaluación
                  </SoftTypography>
                  <Collapse in={scorePercentage}>
                    <SoftBox>
                      <SoftBox position="relative" display="flex" justifyContent="center">
                        <CircularProgress
                          variant="determinate"
                          sx={(theme) => ({
                            color: theme.palette.grey[200],
                            ...theme.applyStyles("dark", {
                              color: theme.palette.grey[800],
                            }),
                          })}
                          size={100}
                          thickness={4}
                          value={100}
                        />
                        <CircularProgress
                          variant="determinate"
                          disableShrink
                          sx={(theme) => ({
                            color: getColor(scorePercentage),
                            animationDuration: "550ms",
                            position: "absolute",
                            [`& .${circularProgressClasses.circle}`]: {
                              strokeLinecap: "round",
                            },
                          })}
                          value={scorePercentage}
                          size={100}
                          thickness={4}
                        />
                        <SoftBox
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SoftTypography variant="h6">
                            {`${Math.round(scorePercentage)}/100`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Collapse>
                  {evaluacion
                    .map((dimension) => {
                      return {
                        titulo: dimension.titulo,
                        area: dimension.area,
                        preguntas: dimension.preguntas.filter(
                          (pregunta) => pregunta.tipo === "boolean"
                        ),
                      };
                    })
                    .flat()
                    .filter((item) => item.preguntas.length > 0)
                    .map((dimension, index) => (
                      <SoftBox
                        key={index}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <SoftTypography variant="h6">{dimension.area}</SoftTypography>
                        <SoftBox>
                          {dimension.preguntas.map((pregunta, index) => (
                            <SoftBox
                              key={index}
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              alignItems="center"
                            >
                              <SoftBadge
                                color={pregunta.value === 0 ? "error" : "success"}
                                badgeContent={`${
                                  pregunta.value === 0 ? "No" : "Sí"
                                } ${pregunta.pregunta.replace(/[\?¿]/g, "")}`}
                              />
                            </SoftBox>
                          ))}
                        </SoftBox>
                      </SoftBox>
                    ))}
                </SoftBox>
              )}
            </Collapse>
            <Collapse
              in={
                evaluacion
                  .map((dimension) => dimension.preguntas)
                  .flat()
                  .filter((pregunta) => !pregunta.id).length
              }
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    disabled={loading}
                    onClick={
                      step === 0
                        ? handleClose
                        : () => {
                            setStep(step - 1);
                          }
                    }
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>{step === 0 ? "close" : "arrow_back"}</Icon>
                    &nbsp;{step === 0 ? "Cancelar" : "Anterior"}
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="uproGreen"
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    disabled={
                      loading ||
                      evaluacion[step]?.preguntas.filter(
                        (pregunta) => pregunta.value === undefined 
                      ).length
                    }
                    onClick={() => {
                      if (step < evaluacion.length - 1) {
                        setStep(step + 1);
                      } else {
                        confirmar({
                          title: "Finalizar evaluación",
                          message: `¿Está seguro que quieres dar por finalizada esta evaluación? Este proceso no se puede deshacer.`,
                          icon: "quiz",
                          func: () => {
                            saveEvaluacionDocente({
                              variables: {
                                input: {
                                  id: evaluacionHeader?.id || null,
                                  idSede: idSede,
                                  idDocente: docente.id,
                                  puntaje: scorePercentage,
                                  detalles: evaluacion
                                    .map((dimension) =>
                                      dimension.preguntas.map((pregunta) => ({
                                        id: pregunta.id || null,
                                        pregunta: pregunta.pregunta,
                                        respuesta:
                                          pregunta.tipo === "boolean"
                                            ? pregunta.value === 0
                                              ? "No"
                                              : "Sí"
                                            : pregunta.tipo === "text"
                                            ? pregunta.value
                                            : marks.find((mark) => mark.value === pregunta.value)
                                                .text,
                                        area: dimension.area,
                                        tipo: pregunta.tipo,
                                        value:
                                          pregunta.tipo === "text"
                                            ? null
                                            : parseInt(pregunta.value),
                                      }))
                                    )
                                    .flat(),
                                },
                              },
                            })
                              .then(() => {
                                handleSnackbar("Evaluación guardada correctamente", "success");
                                handleClose();
                              })
                              .catch((error) => {
                                handleSnackbar(
                                  error.message || "Ha ocurrido un error al guardar la evaluación",
                                  "error"
                                );
                              });
                          },
                        });
                      }
                    }}
                  >
                    {false ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          marginRight: 0.5,
                        }}
                        color="inherit"
                      />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>
                        {step === evaluacion.length - 1 ? "done" : "arrow_forward"}
                      </Icon>
                    )}
                    &nbsp;{step === evaluacion.length - 1 ? "Finalizar" : "Siguiente"}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Collapse>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalPuntaje.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  docente: PropTypes.object,
  idSede: PropTypes.string,
};
