import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Card, Fade, Grid, Icon, InputLabel, Modal, Skeleton, Tooltip } from "@mui/material";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import ModalImage from "components/Modals/ModalImage";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import JSZip from "jszip";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "30%" },
  overflowY: "auto",
};

export default function ModalRevisionAdaptada({ open, handleClose, idInscripcion }) {
  const [inscripcion, setInscripcion] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);
  const [selectedImage, setSelectedImage] = useState(null);
  const [getInscripcion, { loading }] = useLazyQuery(
    gql`
      query getInscripcion($id: ID!) {
        inscripcionAlumno(id: $id) {
          id
          fechaInicio
          acta {
            id
            estado
            nota
          }
          examen {
            id
            titulo
          }
          alumno {
            id
            nombre
            apellido
          }
          adaptacionExamen
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (idInscripcion && open) {
      getInscripcion({ variables: { id: idInscripcion } })
        .then((res) => {
          setInscripcion(res.data.inscripcionAlumno);
        })
        .catch((err) => {
          handleSnackbar(err.message || "Error al obtener la inscripción", "error");
        });
    }
  }, [idInscripcion, open, getInscripcion, handleSnackbar]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftTypography variant="h6">{`Revisar examen de ${makeNiceText(
                      `${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`,
                      "nombre"
                    )}`}</SoftTypography>
                    <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  sx={{
                    height: "fit-content",
                  }}
                >
                  <Grid item xs={12}>
                    <Card>
                      <SoftBox p={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8} container spacing={2}>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="calificacion">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Calificación
                                </SoftTypography>
                              </InputLabel>
                              <SoftBadge
                                color={
                                  inscripcion?.acta?.estado === "Aprobado" ? "uproGreen" : "primary"
                                }
                                badgeContent={inscripcion?.acta?.estado}
                                container
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="alumno">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Alumno
                                </SoftTypography>
                              </InputLabel>
                              <SoftTypography variant="h6">
                                {makeNiceText(
                                  `${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`,
                                  "nombre"
                                )}
                              </SoftTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <InputLabel htmlFor="nombre">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Examen
                                </SoftTypography>
                              </InputLabel>
                              <SoftTypography variant="h6">
                                {inscripcion?.examen?.titulo}
                              </SoftTypography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={4} justifyContent="center">
                            <SoftBox
                              sx={{
                                width: "100%",
                              }}
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <SoftBox position="relative" display="flex" justifyContent="center">
                                <CircularProgress
                                  variant="determinate"
                                  sx={(theme) => ({
                                    color: theme.palette.grey[200],
                                    ...theme.applyStyles("dark", {
                                      color: theme.palette.grey[800],
                                    }),
                                  })}
                                  size={100}
                                  thickness={4}
                                  value={100}
                                />
                                <CircularProgress
                                  variant="determinate"
                                  disableShrink
                                  sx={(theme) => ({
                                    color:
                                      inscripcion?.acta?.estado === "Aprobado"
                                        ? getUproColor("uproGreen")
                                        : getUproColor("uproPink"),
                                    animationDuration: "550ms",
                                    position: "absolute",
                                    [`& .${circularProgressClasses.circle}`]: {
                                      strokeLinecap: "round",
                                    },
                                  })}
                                  value={inscripcion?.acta?.nota}
                                  size={100}
                                  thickness={4}
                                />
                                <SoftBox
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <SoftTypography variant="h6">
                                    {loading ? (
                                      <Skeleton variant="circular" width={20} height={20} />
                                    ) : (
                                      inscripcion?.acta?.nota
                                    )}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="inicio">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Fecha
                              </SoftTypography>
                            </InputLabel>
                            <SoftTypography variant="h6">
                              {inscripcion?.fechaInicio
                                ? dayjs(inscripcion?.fechaInicio).format("DD/MM/YYYY")
                                : "Sin comenzar"}
                            </SoftTypography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="fin">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Tipo de adaptación
                              </SoftTypography>
                            </InputLabel>
                            <SoftTypography variant="h6">
                              {inscripcion?.adaptacionExamen}
                            </SoftTypography>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </SoftBox>
          <ModalImage
            open={selectedImage !== null}
            handleClose={() => setSelectedImage(null)}
            src={selectedImage}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalRevisionAdaptada.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idInscripcion: PropTypes.number,
  refetch: PropTypes.func,
  readOnly: PropTypes.bool,
};
