// React
import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "30%", xxl: "25%" },
  overflowY: "auto",
};

export default function ModalFinalizado({ open }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftTypography variant="h6">Examen finalizado</SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <SoftBox>
                    <Icon
                      sx={{
                        color: getUproColor("uproGreen"),
                      }}
                      fontSize="large"
                    >
                      task
                    </Icon>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography variant="h6">¡El examen ha finalizado!</SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography variant="caption" component="p" color="text">
                      Se ha guardado tu examen, el resultado estará disponible luego de ser
                      corregido por el docente a cargo.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <Link to="/autogestion">
                      <SoftButton color="primary" circular fullWidth>
                        Ir a autogestión &nbsp;
                        <Icon>arrow_forward</Icon>
                      </SoftButton>
                    </Link>
                  </SoftBox>
                </Grid>
              </Grid>
            </Card>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalFinalizado.propTypes = {
  open: PropTypes.bool,
};
