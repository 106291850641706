// React
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { memo, useContext, useEffect, useState } from "react";

// Components
import { Card, Collapse, Grid, Icon, Slide, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import PageLayout from "components/LayoutContainers/EmpleadoLayout";
import SoftButton from "components/SoftButton";
import { MessageManager } from "context";
import { Link } from "react-router-dom";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const EgresadoItem = memo(
  ({ egresado, index, entregarDiploma, handleSnackbar, isFirstWithoutDiploma }) => (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <SoftBox>
        <SoftBox
          px={2}
          py={1}
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <SoftBox display="flex" alignItems="center" justifyContent="space-between">
            <SoftBox
              display={{ xs: "flex-row", sm: "flex" }}
              alignItems="center"
              pl={{ xs: 0, sm: 2 }}
            >
              <SoftBox mr={1} sx={{ display: { xs: "none", sm: "block" } }}>
                <Icon sx={{ color: getUproColor("uproPink") }}>subdirectory_arrow_right</Icon>
              </SoftBox>
              <SoftTypography
                variant="h6"
                color={
                  isFirstWithoutDiploma
                    ? "primary"
                    : egresado.diplomaEntregado
                    ? "dark"
                    : "textPrimary"
                }
              >
                {`${index + 1} - ${makeNiceText(
                  `${egresado.apellido} ${egresado.nombre}`,
                  "nombre"
                )}`}
                {egresado.carreraSecundaria && (
                  <SoftTypography variant="h7" fontWeight="regular">
                    {` (${egresado.carreraSecundaria})`}
                  </SoftTypography>
                )}
              </SoftTypography>
            </SoftBox>
            <SoftBox justifyContent="flex-end" alignItems="center" gap={1}>
              {egresado.diplomaEntregado ? (
                <Tooltip title="Diploma entregado" placement="top">
                  <SoftBox display="flex" alignItems="center" justifyContent="center" p={1}>
                    <Icon sx={{ color: getUproColor("uproGreen") }}>done_all</Icon>
                  </SoftBox>
                </Tooltip>
              ) : (
                <Tooltip title="Marcar como entregado" placement="top">
                  <SoftBox>
                    <SoftButton
                      circular
                      iconOnly
                      color="uproGreen"
                      onClick={() => {
                        entregarDiploma({ variables: { id: egresado.id } })
                          .then(() => {
                            handleSnackbar("Diploma entregado", "success");
                          })
                          .catch(() => {
                            handleSnackbar("Error al entregar el diploma", "error");
                          });
                      }}
                    >
                      <Icon>done_all</Icon>
                    </SoftButton>
                  </SoftBox>
                </Tooltip>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Slide>
  )
);

EgresadoItem.propTypes = {
  egresado: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  entregarDiploma: PropTypes.func.isRequired,
  handleSnackbar: PropTypes.func.isRequired,
  isFirstWithoutDiploma: PropTypes.bool.isRequired,
};

function Acreditados() {
  const [expanded, setExpanded] = useState([]);
  const [egresados, setEgresados] = useState([]);
  const [carreras, setCarreras] = useState([]);
  const [filtrarEntregados, setFiltrarEntregados] = useState(false);
  const { loading, error, data, refetch } = useQuery(
    gql`
      query getEgresados($order: Order, $filter: JSON) {
        egresados(order: $order, filter: $filter) {
          id
          nombre
          apellido
          dni
          carrera
          acreditacion
          diplomaEntregado
          carreraSecundaria
        }
      }
    `,
    {
      variables: {
        order: { field: "acreditacion", order: "ASC" },
        filter: {
          acreditacion: { _ne: null },
          diplomaEntregado: filtrarEntregados ? false : undefined,
        },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { handleSnackbar } = useContext(MessageManager);

  const { data: nuevaAcreditacionEgresado } = useSubscription(
    gql`
      subscription {
        nuevaAcreditacionEgresado {
          id
          nombre
          apellido
          dni
          carrera
          acreditacion
          diplomaEntregado
          carreraSecundaria
        }
      }
    `
  );

  const { data: nuevoDiplomaEntregado } = useSubscription(
    gql`
      subscription {
        diplomaEntregadoEgresado {
          id
          nombre
          apellido
          dni
          carrera
          acreditacion
          diplomaEntregado
          carreraSecundaria
        }
      }
    `
  );

  const [entregarDiploma] = useMutation(
    gql`
      mutation entregarDiplomaEgresado($id: ID!) {
        entregarDiplomaEgresado(id: $id) {
          id
        }
      }
    `
  );

  useEffect(() => {
    if (data?.egresados) {
      setEgresados(data.egresados);
    }
  }, [data]);

  useEffect(() => {
    if (egresados) {
      const carreras = egresados.map((egresado) => egresado.carrera);
      setCarreras([...new Set(carreras)]);
    }
  }, [egresados]);

  useEffect(() => {
    if (nuevaAcreditacionEgresado) {
      handleSnackbar("Se ha registrado una nueva acreditación", "success");
      setEgresados([...egresados, nuevaAcreditacionEgresado.nuevaAcreditacionEgresado]);
      if (!expanded.includes(nuevaAcreditacionEgresado.nuevaAcreditacionEgresado.carrera)) {
        setExpanded([...expanded, nuevaAcreditacionEgresado.nuevaAcreditacionEgresado.carrera]);
      }
    }
  }, [nuevaAcreditacionEgresado]);

  useEffect(() => {
    if (nuevoDiplomaEntregado) {
      let newEgresados = egresados.map((egresado) => {
        if (egresado.id === nuevoDiplomaEntregado.diplomaEntregadoEgresado.id) {
          return nuevoDiplomaEntregado.diplomaEntregadoEgresado;
        }
        return egresado;
      });
      setEgresados(newEgresados);
    }
  }, [nuevoDiplomaEntregado]);

  return (
    <PageLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3} pb={0}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Acreditados</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Volver a egresados" placement="top">
                        <Link to="/egresados">
                          <SoftButton color="primary" circular iconOnly>
                            <Icon>arrow_back</Icon>
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <Tooltip
                        title={filtrarEntregados ? "Mostrar todos" : "Filtrar entregados"}
                        placement="top"
                      >
                        <SoftButton
                          color={filtrarEntregados ? "uproGreen" : "uproYellow"}
                          circular
                          onClick={() => {
                            setFiltrarEntregados(!filtrarEntregados);
                          }}
                          sx={{
                            transition: "all 0.5s",
                          }}
                          fullWidth
                        >
                          <Icon>{filtrarEntregados ? "check_circle" : "check_circle_outline"}</Icon>
                          &nbsp;{filtrarEntregados ? "Mostrar todos" : "Filtrar entregados"}
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Volver a egresados" placement="top">
                        <Link to="/egresados">
                          <SoftButton color="primary" circular>
                            <Icon>arrow_back</Icon>
                            &nbsp;Volver
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={2} p={3}>
              {loading ? (
                <Grid item xs={12}>
                  <Loading />
                </Grid>
              ) : carreras.length > 0 ? (
                carreras
                  .sort((a, b) => a.localeCompare(b))
                  .map((carrera) => {
                    const egresadosCarrera = egresados.filter(
                      (egresado) => egresado.carrera === carrera
                    );
                    const firstWithoutDiploma = egresadosCarrera.findIndex(
                      (egresado) => !egresado.diplomaEntregado
                    );
                    return (
                      <Grid item xs={12} key={carrera}>
                        <Card
                          sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={1}
                            sx={{
                              borderBottom: !expanded.includes(carrera)
                                ? "none"
                                : "1px solid rgba(0, 0, 0, 0.12)",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                            onClick={() => {
                              setExpanded(
                                expanded.includes(carrera)
                                  ? expanded.filter((i) => i !== carrera)
                                  : [...expanded, carrera]
                              );
                            }}
                          >
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox mr={1} display="flex" alignItems="center">
                                <Icon sx={{ color: getUproColor("uproGreen") }}>assignment</Icon>
                              </SoftBox>
                              <SoftTypography variant="h6" fontWeight="bold">
                                {carrera}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                              <SoftBox>
                                <Tooltip
                                  title={expanded.includes(carrera) ? "Ocultar" : "Mostrar"}
                                  placement="top"
                                >
                                  <SoftButton
                                    color="primary"
                                    circular
                                    iconOnly
                                    onClick={() =>
                                      setExpanded(
                                        expanded.includes(carrera)
                                          ? expanded.filter((i) => i !== carrera)
                                          : [...expanded, carrera]
                                      )
                                    }
                                  >
                                    <Icon
                                      sx={{
                                        rotate: expanded.includes(carrera) ? "180deg" : "0deg",
                                        transition: "all 0.5s",
                                      }}
                                    >
                                      expand_more
                                    </Icon>
                                  </SoftButton>
                                </Tooltip>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <Collapse in={expanded.includes(carrera)}>
                            <SoftBox display="flex" flexDirection="column-reverse">
                              {egresadosCarrera.length > 0 ? (
                                egresadosCarrera.map((egresado, index) => (
                                  <EgresadoItem
                                    key={egresado.id}
                                    egresado={egresado}
                                    index={index}
                                    entregarDiploma={entregarDiploma}
                                    handleSnackbar={handleSnackbar}
                                    isFirstWithoutDiploma={index === firstWithoutDiploma}
                                  />
                                ))
                              ) : (
                                <Grid container spacing={2} p={2}>
                                  <SinDatos />
                                </Grid>
                              )}
                            </SoftBox>
                          </Collapse>
                        </Card>
                      </Grid>
                    );
                  })
              ) : (
                <Grid item xs={12}>
                  <SinDatos />
                </Grid>
              )}
            </Grid>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default Acreditados;
