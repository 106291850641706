/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const estados = [
  {
    nombre: "Nuevo",
    color: getUproColor("uproBlue"),
  },
  {
    nombre: "Contactado - Pasa a Entrevista RRHH",
    color: getUproColor("uproGreen"),
  },
  {
    nombre: "Entrevista RRHH - Pasa a Entrevista Técnica",
    color: getUproColor("uproGreen"),
  },
  {
    nombre: "Entrevista Técnica - Pasa a Clase de Oposición",
    color: getUproColor("uproGreen"),
  },
  {
    nombre: "Clase de Oposición",
    color: getUproColor("uproGreen"),
  },
  {
    nombre: "Sin Respuesta",
    color: getUproColor("uproYellow"),
  },
  {
    nombre: "No Continua",
    color: getUproColor("uproPink"),
  },
  {
    nombre: "Aprobado",
    color: getUproColor("uproGreen"),
  },
  {
    nombre: "Inducción",
    color: getUproColor("uproGreen"),
  },
];

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      let sedes = [];
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("uproYellow"),
                }}
                fontSize="lg"
              >
                person
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title="Aspirante" placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("uproYellow"),
                  }}
                  fontSize="lg"
                >
                  person
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              <SoftBox
                sx={{
                  cursor: "pointer",
                }}
                data={item?.estado}
                onClick={(e) => handleOpenMenu(e, item)}
              >
                <SoftTypography
                  verticalAlign="text-top"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                >
                  <SoftBadge
                    badgeContent={item?.estado}
                    variant="color"
                    color={
                      estados.find((estado) => estado.nombre === item?.estado)?.color ||
                      getUproColor("uproBlue")
                    }
                  />
                </SoftTypography>
              </SoftBox>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.apellido
                ? makeNiceText(item?.apellido + " " + item?.nombre, "nombre")
                : item?.username
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.apellido + " " + item?.nombre, "nombre")}
              {item?.esDiscapacitado && (
                <Tooltip title="Discapacitado" placement="top">
                  <Icon color="info" sx={{ verticalAlign: "middle", ml: 0.5 }} fontSize="small">
                    accessible
                  </Icon>
                </Tooltip>
              )}
            </SoftTypography>
          </SoftBox>
        ),
        oficio: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(item?.oficio, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title={item?.oficio.length > 40 ? item?.oficio : ""} placement="top">
              <SoftTypography
                verticalAlign="text-top"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {makeNiceText(
                  item?.oficio.length > 40 ? item?.oficio.slice(0, 40) + "..." : item?.oficio,
                  "nombre"
                )}
              </SoftTypography>
            </Tooltip>
          </SoftBox>
        ),
        sedes: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(`${item?.sedes?.map((sede) => sede?.nombre).join(", ")}`, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip
              title={
                item?.sedes?.length > 2 ? item?.sedes?.map((sede) => sede?.nombre).join(", ") : ""
              }
              placement="top"
            >
              <SoftTypography
                verticalAlign="text-top"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {item?.sedes?.length > 2
                  ? `${makeNiceText(
                      `${item?.sedes
                        ?.map((sede) => sede?.nombre)
                        .slice(0, 2)
                        .join(", ")}`,
                      "nombre"
                    )}... +${item?.sedes?.length - 2}`
                  : makeNiceText(
                      `${item?.sedes?.map((sede) => sede?.nombre).join(", ")}`,
                      "nombre"
                    )}
              </SoftTypography>
            </Tooltip>
          </SoftBox>
        ),
        carreras: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(
              `${item?.carreras?.map((sede) => sede?.nombre).join(", ")}`,
              "nombre"
            )}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip
              title={
                item?.carreras?.length > 2
                  ? makeNiceText(item?.carreras?.map((sede) => sede?.nombre).join(", "), "nombre")
                  : ""
              }
              placement="top"
            >
              <SoftTypography
                verticalAlign="text-top"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {item?.carreras?.length > 2
                  ? `${makeNiceText(
                      `${item?.carreras
                        ?.map((sede) => sede?.nombre)
                        .slice(0, 2)
                        .join(", ")}`,
                      "nombre"
                    )}... +${item?.carreras?.length - 2}`
                  : makeNiceText(
                      `${item?.carreras?.map((sede) => sede?.nombre).join(", ")}`,
                      "nombre"
                    )}
              </SoftTypography>
            </Tooltip>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.estado}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SoftBadge
                badgeContent={item?.estado}
                variant="color"
                color={
                  estados.find((estado) => estado.nombre === item?.estado)?.color ||
                  getUproColor("uproBlue")
                }
              />
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("uproGreen"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left", orderField: "nombreCompleto" },
  { name: "oficio", desc: "Oficio", align: "left" },
  { name: "sedes", desc: "Sedes", align: "left", noOrder: true },
  { name: "carreras", desc: "Carreras", align: "left", noOrder: true },
  { name: "estado", desc: "Estado", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { estados, columns, getRows };
