import { Card, CircularProgress, Fade, Modal } from "@mui/material";
import SoftBox from "components/CheckBox";
import PropTypes from "prop-types";
import logo from "assets/images/logo.png";
import SoftTypography from "components/SoftTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
};

export default function ModalLoading({ open }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="center" alignItems="center" p={3} px={6}>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <SoftBox component="img" src={logo} alt="UPrO Logo" width="150px" p={3} />
              <CircularProgress size={30} color="primary" />
              <SoftTypography variant="h6" align="center" mt={2}>
                Cargando...
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalLoading.propTypes = {
  open: PropTypes.bool,
};
