// React

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

import {
  CircularProgress,
  Collapse,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { validateDNI, validateEmail, validatePhone } from "utils/validations";
import ModalVerificarDocente from "../ModalVerificarDocente";
import ModalVerPlanCarrera from "../VerPlanCarrera";
import { validarName } from "utils/validations";

function PostulacionDocente({ handleCancel, handleSuccess, googleReCaptchaProps, setIdUser }) {
  const { executeRecaptcha } = googleReCaptchaProps;
  const [usuario, setUsuario] = useState();
  const [openVerificar, setOpenVerificar] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [sedes, setSedes] = useState([]);
  const inputRef = useRef();

  const handleChanges = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const { data: paises } = useQuery(
    gql`
      query getPaises {
        paises {
          id
          nombre
          nacionalidad
          codigo_internacional
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const { data: carreras } = useQuery(
    gql`
      query getCarreras {
        carrerasPublic(filter: { tipo: "Carrera" }) {
          id
          nombre
          codigo
          sedes {
            id
            nombre
          }
        }
      }
    `
  );

  const [sendVerificacion, { loading: loadingVerificacion }] = useMutation(
    gql`
      mutation sendVerificationEmail($email: String!) {
        sendVerificationEmail(email: $email)
      }
    `
  );

  useEffect(() => {
    if (carreras?.carrerasPublic) {
      const sedes = carreras.carrerasPublic.reduce((acc, carrera) => {
        if (carrera.sedes) {
          carrera.sedes.forEach((sede) => {
            if (!acc.find((s) => s.id === sede.id)) {
              acc.push(sede);
            }
          });
        }
        return acc;
      }, []);
      setSedes(sedes);
    }
  }, [carreras]);

  useEffect(() => {
    if (usuario?.idProvincia) {
      getLocalidades({ variables: { provincia: usuario?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === usuario?.idLocalidad)) {
          let userNew = { ...usuario };
          delete userNew.idLocalidad;
          setUsuario(userNew);
        }
      });
    }
  }, [usuario?.idProvincia]);

  return (
    <SoftBox p={3}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox>
          <SoftTypography variant="h6">
            Postularse para el puesto de Instructor/Auxiliar
          </SoftTypography>
        </SoftBox>
        <SoftBox display={{ xs: "block", sm: "none" }}>
          <SoftButton
            color="primary"
            circular
            onClick={handleCancel}
            fullWidth={{
              xs: true,
              sm: false,
            }}
            iconOnly
          >
            <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="username">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Email
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="email"
              placeholder="Ingrese el email"
              type="text"
              name="email"
              value={usuario?.email || ""}
              error={usuario?.email?.length > 0 && !validateEmail(usuario?.email)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="nombre">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nombre
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="nombre"
              placeholder="Ingrese el nombre"
              type="text"
              name="nombre"
              value={usuario?.nombre || ""}
              error={usuario?.nombre?.length > 0 && !validarName(usuario?.nombre)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="apellido">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Apellido
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="apellido"
              placeholder="Ingrese el apellido"
              type="text"
              name="apellido"
              value={usuario?.apellido || ""}
              error={usuario?.apellido?.length > 0 && !validarName(usuario?.apellido)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="dni">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                DNI
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="dni"
              placeholder="Ingrese el dni"
              type="text"
              name="dni"
              value={usuario?.dni || ""}
              error={usuario?.dni?.length > 0 && !validateDNI(usuario?.dni)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="fechaNacimiento">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Fecha de nacimiento
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="fechaNacimiento"
              type="date"
              name="fechaNacimiento"
              value={usuario?.fechaNacimiento || ""}
              error={
                usuario?.fechaNacimiento &&
                (dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year")) ||
                  dayjs(usuario?.fechaNacimiento).isBefore(dayjs().subtract(100, "year")))
              }
              onChange={handleChanges}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              "& .MuiInputBase-root": {
                maxHeight: "40px",
                paddingRight: "40px !important",
              },
            }}
          >
            <InputLabel htmlFor="nacionalidad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nacionalidad
              </SoftTypography>
            </InputLabel>
            <Select
              name="idPais"
              value={usuario?.idPais || usuario?.pais?.id || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {paises?.paises?.map((pais) => (
                <MenuItem key={pais.id} value={pais.id}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <SoftBox>
                      <SoftTypography variant="p">{pais.nacionalidad}</SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                      <SoftBox
                        component="img"
                        src={`https://flagsapi.com/${pais.codigo_internacional}/flat/64.png`}
                        alt={`${pais.nombre}`}
                        sx={{
                          height: "25px",
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="genero">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Genero
              </SoftTypography>
            </InputLabel>
            <Select name="genero" value={usuario?.genero || -1} onChange={handleChanges}>
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
              <MenuItem value={"X"}>X</MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sx={
              usuario?.esDiscapacitado === "No" || !usuario?.esDiscapacitado
                ? {
                    paddingTop: "0 !important",
                    transition: "padding 0.5s",
                  }
                : {
                    transition: "padding 0.5s",
                  }
            }
          >
            <Collapse in={usuario?.esDiscapacitado === "Si"}>
              <InputLabel htmlFor="discapacidad">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Discapacidad
                  <SoftTypography variant="caption" fontWeight="bold" color="primary">
                    &nbsp;*
                  </SoftTypography>
                </SoftTypography>
              </InputLabel>
              <SoftInput
                label="discapacidad"
                placeholder="Detalle la discapacidad"
                type="text"
                name="discapacidad"
                value={usuario?.discapacidad || ""}
                onChange={handleChanges}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="provincia">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Provincia de residencia
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="provincia"
              value={usuario?.idProvincia || -1}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  idProvincia: e.target.value,
                  provincia: provincias?.provincias.find(
                    (provincia) => provincia.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {provincias?.provincias?.map((provincia) => (
                <MenuItem key={provincia.id} value={provincia.id}>
                  {provincia.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="localidad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Localidad de residencia
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="localidad"
              value={usuario?.idLocalidad || -1}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  idLocalidad: e.target.value,
                  localidad: localidades?.localidades.find(
                    (localidad) => localidad.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                {usuario?.idProvincia ? "Seleccione una" : "Seleccione una provincia"}
              </MenuItem>
              {localidades?.localidades?.map((localidad) => (
                <MenuItem key={localidad.id} value={localidad.id}>
                  {localidad.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="telefono">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Teléfono de contacto
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="telefono"
              placeholder="Código de área + número"
              type="text"
              name="telefono"
              value={usuario?.telefono || ""}
              error={usuario?.telefono?.length > 0 && !validatePhone(usuario?.telefono)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="tipoEstudios">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nivel de educación alcanzado
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="tipoEstudios"
              value={usuario?.tipoEstudios || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Secundario Incompleto"}>Secundario Incompleto</MenuItem>
              <MenuItem value={"Secundario Completo"}>Secundario Completo</MenuItem>
              <MenuItem value={"Terciario Incompleto"}>Terciario Incompleto</MenuItem>
              <MenuItem value={"Terciario Completo"}>Terciario Completo</MenuItem>
              <MenuItem value={"Universitario Incompleto"}>Universitario Incompleto</MenuItem>
              <MenuItem value={"Universitario Completo"}>Universitario Completo</MenuItem>
              <MenuItem value={"Posgrado Incompleto"}>Posgrado Incompleto</MenuItem>
              <MenuItem value={"Posgrado Completo"}>Posgrado Completo</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="titulo">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Título
              </SoftTypography>
              <SoftTypography variant="caption" color="primary">
                &nbsp;(En caso de tener)
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="titulo"
              placeholder="Ingrese el título y la institución"
              type="text"
              name="titulo"
              value={usuario?.titulo || ""}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="oficio">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Oficio
              </SoftTypography>
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="oficio"
              placeholder="Ingrese el nombre de una o más materias"
              type="text"
              name="oficio"
              value={usuario?.oficio || ""}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="preferenciaHorario">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Preferencia de horario
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="preferenciaHorario"
              value={usuario?.preferenciaHorario || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              <MenuItem value={"Mañana"}>Mañana</MenuItem>
              <MenuItem value={"Tarde"}>Tarde</MenuItem>
              <MenuItem value={"Noche"}>Noche</MenuItem>
              <MenuItem value={"Full Time"}>Full Time</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="idsSedes">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Sede
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="idsSedes"
              value={usuario?.idsSedes || []}
              multiple
              onChange={(e) => {
                setUsuario({
                  ...usuario,
                  idsSedes: e.target.value,
                  idsCarreras: undefined,
                });
              }}
            >
              {sedes?.map((usuario) => (
                <MenuItem key={usuario.id} value={usuario.id}>
                  {usuario.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="idsCarreras">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Curriculum
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftButton
              color={usuario?.curriculum ? "uproBlue" : "uproYellow"}
              fullWidth
              onClick={() => inputRef.current.click()}
              circular
            >
              {usuario?.curriculum
                ? usuario?.curriculum.name.length > 40
                  ? `${usuario?.curriculum.name.substring(
                      0,
                      40
                    )}...${usuario?.curriculum.name.substring(usuario?.curriculum.name.length - 4)}`
                  : usuario?.curriculum.name
                : "Subir archivo"}
              &nbsp;
              <Icon sx={{ fontWeight: "light" }}>
                {usuario?.curriculum ? "done" : "cloud_upload"}
              </Icon>
            </SoftButton>
            <input
              ref={inputRef}
              type="file"
              style={{ display: "none" }}
              accept=".pdf"
              onChange={(e) => {
                if (e.target.files[0] && e.target.files[0].size < 20000000) {
                  setUsuario({
                    ...usuario,
                    curriculum: e.target.files[0],
                  });
                } else {
                  handleSnackbar("El archivo debe ser menor a 20MB", "error");
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="idsCarreras">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Carreras de interés
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="idsCarreras"
              value={usuario?.idsCarreras || [-1]}
              onChange={(e) => {
                if (e.target.value.includes(-1)) {
                  e.target.value = e.target.value.filter((id) => id !== -1);
                }
                setUsuario({
                  ...usuario,
                  idsCarreras: e.target.value,
                });
              }}
              multiple
            >
              {!usuario?.idsCarreras?.length && (
                <MenuItem value={-1} disabled>
                  {usuario?.idsSedes?.length
                    ? "Seleccione una o más carreras"
                    : "Seleccione una sede primero"}
                </MenuItem>
              )}
              {carreras?.carrerasPublic
                ?.filter((carrera) =>
                  carrera.sedes.some((sede) => usuario?.idsSedes?.includes(sede.id))
                )
                .map((carrera) => (
                  <MenuItem key={carrera.id} value={carrera.id}>
                    {carrera.nombre}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        display={{
          xs: "flex-row",
          sm: "flex",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox>
          <SoftBox
            display={{
              xs: "flex-row",
              sm: "flex",
            }}
            gap={2}
          >
            <SoftBox display={{ xs: "none", sm: "block" }}>
              <SoftButton
                color="primary"
                circular
                onClick={handleCancel}
                fullWidth={{
                  xs: true,
                  sm: false,
                }}
              >
                <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                &nbsp;Cancelar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          mt={{
            xs: 2,
            sm: 0,
          }}
        >
          <SoftBox>
            <SoftButton
              color="uproGreen"
              circular
              disabled={
                loadingVerificacion ||
                !usuario?.email ||
                !usuario?.nombre ||
                !usuario?.apellido ||
                !usuario?.dni ||
                !usuario?.idsSedes ||
                !usuario?.tipoEstudios ||
                !usuario?.curriculum ||
                !usuario?.oficio ||
                !usuario?.telefono ||
                !usuario?.idProvincia ||
                !usuario?.idLocalidad ||
                (usuario?.nombre?.length > 0 && !validarName(usuario?.nombre)) ||
                (usuario?.apellido?.length > 0 && !validarName(usuario?.apellido)) ||
                (usuario?.telefono && !validatePhone(usuario?.telefono)) ||
                !usuario?.idsCarreras ||
                (usuario?.fechaNacimiento &&
                  (dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year")) ||
                    dayjs(usuario?.fechaNacimiento).isBefore(dayjs().subtract(100, "year")))) ||
                (usuario?.email && !validateEmail(usuario?.email)) ||
                (usuario?.dni && !validateDNI(usuario?.dni))
              }
              fullWidth={{
                xs: true,
                sm: false,
              }}
              onClick={() =>
                sendVerificacion({ variables: { email: usuario?.email } })
                  .then(() => {
                    setOpenVerificar(true);
                  })
                  .catch((e) => {
                    handleSnackbar("error", e.message);
                  })
              }
            >
              {loadingVerificacion ? (
                <CircularProgress
                  size={15}
                  sx={{
                    marginRight: 0.5,
                  }}
                  color="inherit"
                />
              ) : (
                <Icon sx={{ fontWeight: "light" }}>done</Icon>
              )}
              &nbsp;Aplicar
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <ModalVerificarDocente
          open={openVerificar}
          handleClose={() => setOpenVerificar(false)}
          usuario={usuario}
          handleCancel={handleCancel}
          handleResend={() => {
            sendVerificacion({ variables: { email: usuario?.email } })
              .then(() => {
                handleSnackbar("Se ha reenviado el email de verificación", "success");
              })
              .catch((e) => {
                handleSnackbar("error", e.message);
              });
          }}
        />
      </SoftBox>
    </SoftBox>
  );
}

PostulacionDocente.defaultProps = {
  handleCancel: () => {},
  handleSuccess: () => {},
  setIdUser: () => {},
};

PostulacionDocente.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
  setIdUser: PropTypes.func.isRequired,
};

const WrappedPreinscripcionAlumno = withGoogleReCaptcha(PostulacionDocente);

function Captcha({ handleCancel, handleSuccess, setIdUser }) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
      <WrappedPreinscripcionAlumno
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        setIdUser={setIdUser}
      />
    </GoogleReCaptchaProvider>
  );
}

Captcha.defaultProps = {
  handleCancel: () => {},
  handleSuccess: () => {},
  setIdUser: () => {},
};

Captcha.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  setIdUser: PropTypes.func.isRequired,
};

export default Captcha;
