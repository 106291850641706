// React
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

// Components
import { Card, Grid, Icon, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { ModalContext } from "context/modal";
import dataEgresados from "layouts/egresados/data/dataEgresados";
import { Link } from "react-router-dom";
import PDFResponsabilidad from "./PDFResponsabilidad";

function Egresados() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [verAcreditados, setVerAcreditados] = useState(false);
  const [modalResponsabilidad, setModalResponsabilidad] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "apellido",
    orden: "ASC",
  });

  const { handleSnackbar } = useContext(MessageManager);
  const { columns, getRows } = dataEgresados;
  const { modal } = useContext(ModalContext);

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getEgresados($filter: JSON, $search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        egresados(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          nombre
          apellido
          dni
          carrera
          carreraSecundaria
          acreditacion
          diplomaEntregado
          orden
        }
      }
    `,
    {
      variables: {
        filter: verAcreditados
          ? {
              acreditacion: { _ne: null },
            }
          : { acreditacion: null },
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [acreditarEgresado, { loading: loadingAcreditar }] = useMutation(
    gql`
      mutation acreditarEgresado($id: ID!) {
        acreditarEgresado(id: $id) {
          id
          acreditacion
          carrera
          orden
        }
      }
    `
  );

  const { data: nuevaAcreditacionEgresado } = useSubscription(
    gql`
      subscription {
        nuevaAcreditacionEgresado {
          id
          nombre
          apellido
          dni
          carrera
          acreditacion
          diplomaEntregado
          carreraSecundaria
        }
      }
    `
  );

  const handleAcreditar = (id) => {
    acreditarEgresado({
      variables: {
        id: id,
      },
    })
      .then((res) => {
        console.log(res);
        modal({
          type: "success",
          title: "Egresado acreditado",
          subtitle: `${res.data.acreditarEgresado.carrera} - Nº${res.data.acreditarEgresado.orden}`,
        });
        refetch();
      })
      .catch((error) => {
        handleSnackbar("Error al acreditar egresado", "error");
      });
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.egresados, handleAcreditar, loadingAcreditar));
      setPagesInfo(data.paginationInfo);
    }
  }, [data, loadingAcreditar]);

  useEffect(() => {
    if (nuevaAcreditacionEgresado) {
      refetch();
    }
  }, [nuevaAcreditacionEgresado]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Egresados</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "flex",
                        sm: "none",
                      }}
                      alignItems="center"
                      justifyContent="end"
                    >
                      <SoftBox mr={11}>
                        <SoftButton
                          color={verAcreditados ? "uproGreen" : "uproYellow"}
                          circular
                          sx={{
                            transition: "all 0.5s",
                          }}
                          onClick={() => {
                            setPage(1);
                            setSearch("");
                            setVerAcreditados(!verAcreditados);
                          }}
                          iconOnly
                        >
                          <Icon>{verAcreditados ? "visibility_off" : "visibility"}</Icon>
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <Link to="/egresados/acreditados">
                          <SoftButton color="primary" circular iconOnly>
                            <Icon>checklist</Icon>
                          </SoftButton>
                        </Link>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip
                        title={verAcreditados ? "Ocultar acreditados" : "Ver acreditados"}
                        placement="top"
                      >
                        <SoftButton
                          color={verAcreditados ? "uproGreen" : "uproYellow"}
                          circular
                          sx={{
                            transition: "all 0.5s",
                          }}
                          onClick={() => setVerAcreditados(!verAcreditados)}
                        >
                          <Icon>{verAcreditados ? "visibility_off" : "visibility"}</Icon>
                          &nbsp;{verAcreditados ? "Ocultar" : "Ver"} acreditados
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip title="Ir a la pantalla de acreditados" placement="top">
                        <Link to="/egresados/acreditados">
                          <SoftButton color="primary" circular iconOnly>
                            <Icon>checklist</Icon>
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Generar nota de responsabilidad de diploma" placement="top">
                        <SoftButton
                          color="uproGreen"
                          circular
                          iconOnly
                          sx={{
                            transition: "all 0.5s",
                          }}
                          onClick={() => setModalResponsabilidad(true)}
                        >
                          <Icon>file_present</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.egresados.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
        </SoftBox>
        <PDFResponsabilidad
          open={modalResponsabilidad}
          handleClose={() => setModalResponsabilidad(false)}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Egresados;
