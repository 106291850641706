import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  CircularProgress,
  Collapse,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import { validateDNI, validateEmail } from "utils/validations";
import { validatePhone } from "utils/validations";

export default function DatosPersonales({ selectedUsuario, loading, refetch }) {
  const [usuario, setUsuario] = useState();
  const [selectedPermisos, setSelectedPermisos] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const { confirmar } = useContext(ConfirmarContext);
  const { verificarAcceso } = useContext(UserContext);

  const handleChanges = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const { data: paises } = useQuery(
    gql`
      query getPaises {
        paises {
          id
          nombre
          nacionalidad
        }
      }
    `
  );
  
  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const { data: carreras } = useQuery(
    gql`
      query getCarreras {
        carrerasPublic(filter: { tipo: "Carrera" }) {
          id
          nombre
          sedes {
            id
            nombre
          }
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          username
          email
        }
      }
    `
  );

  const [saveUsuario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveUser($input: UserInput!) {
        saveUser(input: $input) {
          id
        }
      }
    `
  );

  const handleSave = () => {
    saveUsuario({
      variables: {
        input: {
          id: selectedUsuario?.id,
          email: usuario?.email,
          nombre: usuario?.nombre,
          apellido: usuario?.apellido,
          dni: usuario?.dni,
          fechaNacimiento: usuario?.fechaNacimiento,
          idPais: usuario?.idPais,
          genero: usuario?.genero,
          esDiscapacitado: usuario?.esDiscapacitado === "Si",
          discapacidad: usuario?.discapacidad,
          idLocalidad: usuario?.idLocalidad,
          tipoEstudios: usuario?.tipoEstudios,
          nombreEscuela: usuario?.nombreEscuela,
          preferenciaHorario: usuario?.preferenciaHorario,
          poseeBeneficiosSociales: usuario?.poseeBeneficiosSociales === "Si",
          escalaMatematicas: usuario?.escalaMatematicas,
          escalaComprensionTextos: usuario?.escalaComprensionTextos,
          telefono: usuario?.telefono,
          telefonoRespaldo: usuario?.telefonoRespaldo,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Usuario guardado correctamente", "success");
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el usuario", "error");
      });
  };

  useEffect(() => {
    if (selectedUsuario) {
      setUsuario({
        ...selectedUsuario,
        esDiscapacitado: selectedUsuario.esDiscapacitado ? "Si" : "No",
        idPais: selectedUsuario.pais?.id,
        idProvincia: selectedUsuario.localidad?.provincia?.id,
        idLocalidad: selectedUsuario.localidad?.id,
        poseeBeneficiosSociales: selectedUsuario.poseeBeneficiosSociales ? "Si" : "No",
      });
    } else {
      setUsuario();
    }
  }, [selectedUsuario]);

  useEffect(() => {
    if (usuario?.idProvincia) {
      getLocalidades({ variables: { provincia: usuario?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === usuario?.idLocalidad)) {
          let userNew = { ...usuario };
          delete userNew.idLocalidad;
          setUsuario(userNew);
        }
      });
    }
  }, [usuario?.idProvincia]);

  return (
    <SoftBox>
      <SoftBox py={3} id="cardDatosPersonales">
      <Grid container spacing={2} >
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="username">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Email de usuario
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="email"
              placeholder="Ingrese el email de usuario"
              type="text"
              name="email"
              value={usuario?.email || ""}
              error={usuario?.email?.length > 0 && !validateEmail(usuario?.email)}
              onChange={handleChanges}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="nombre">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nombre
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="nombre"
              placeholder="Ingrese el nombre del usuario"
              type="text"
              name="nombre"
              value={usuario?.nombre || ""}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="apellido">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Apellido
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="apellido"
              placeholder="Ingrese el apellido del usuario"
              type="text"
              name="apellido"
              value={usuario?.apellido || ""}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="dni">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                DNI
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="dni"
              placeholder="Ingrese el dni del usuario"
              type="text"
              name="dni"
              value={usuario?.dni || ""}
              error={usuario?.dni?.length > 0 && !validateDNI(usuario?.dni)}
              onChange={handleChanges}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="fechaNacimiento">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Fecha de nacimiento
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="fechaNacimiento"
              type="date"
              name="fechaNacimiento"
              value={usuario?.fechaNacimiento || ""}
              error={
                usuario?.fechaNacimiento &&
                (dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year")) ||
                  dayjs(usuario?.fechaNacimiento).isBefore(dayjs().subtract(100, "year")))
              }
              onChange={handleChanges}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="nacionalidad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nacionalidad
              </SoftTypography>
            </InputLabel>
            <Select
              name="idPais"
              value={usuario?.idPais || usuario?.pais?.id || -1}
              onChange={handleChanges}
              disabled
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {paises?.paises?.map((pais) => (
                <MenuItem key={pais.id} value={pais.id}>
                  {pais.nacionalidad}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="genero">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Genero
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select name="genero" value={usuario?.genero || -1} onChange={handleChanges}>
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
              <MenuItem value={"X"}>X</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="esDiscapacitado">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Posee discapacidad
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="esDiscapacitado"
              value={usuario?.esDiscapacitado || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Si"}>Si</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sx={
              usuario?.esDiscapacitado === "No" || !usuario?.esDiscapacitado
                ? {
                    paddingTop: "0 !important",
                    transition: "padding 0.5s",
                  }
                : {
                    transition: "padding 0.5s",
                  }
            }
          >
            <Collapse in={usuario?.esDiscapacitado === "Si"}>
              <InputLabel htmlFor="discapacidad">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Discapacidad
                  <SoftTypography variant="caption" fontWeight="bold" color="primary">
                    &nbsp;*
                  </SoftTypography>
                </SoftTypography>
              </InputLabel>
              <SoftInput
                label="discapacidad"
                placeholder="Detalle la discapacidad"
                type="text"
                name="discapacidad"
                value={usuario?.discapacidad || ""}
                onChange={handleChanges}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="provincia">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Provincia de residencia
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="provincia"
              value={usuario?.idProvincia || -1}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  idProvincia: e.target.value,
                  provincia: provincias?.provincias.find(
                    (provincia) => provincia.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {provincias?.provincias?.map((provincia) => (
                <MenuItem key={provincia.id} value={provincia.id}>
                  {provincia.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="localidad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Localidad de residencia
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="localidad"
              value={usuario?.idLocalidad || -1}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  idLocalidad: e.target.value,
                  localidad: localidades?.localidades.find(
                    (localidad) => localidad.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                {usuario?.idProvincia ? "Seleccione una" : "Seleccione una provincia"}
              </MenuItem>
              {localidades?.localidades?.map((localidad) => (
                <MenuItem key={localidad.id} value={localidad.id}>
                  {localidad.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="tipoEstudios">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nivel de educación alcanzado
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="tipoEstudios"
              value={usuario?.tipoEstudios || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Primario Incompleto"}>Primario Incompleto</MenuItem>
              <MenuItem value={"Primario Completo"}>Primario Completo</MenuItem>
              <MenuItem value={"Secundario Incompleto"}>Secundario Incompleto</MenuItem>
              <MenuItem value={"Secundario Completo"}>Secundario Completo</MenuItem>
              <MenuItem value={"Terciario Incompleto"}>Terciario Incompleto</MenuItem>
              <MenuItem value={"Terciario Completo"}>Terciario Completo</MenuItem>
              <MenuItem value={"Universitario Incompleto"}>Universitario Incompleto</MenuItem>
              <MenuItem value={"Universitario Completo"}>Universitario Completo</MenuItem>
              <MenuItem value={"Posgrado Incompleto"}>Posgrado Incompleto</MenuItem>
              <MenuItem value={"Posgrado Completo"}>Posgrado Completo</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="nombreEscuela">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nombre de la institución donde asistió
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="nombreEscuela"
              placeholder="Ingrese el nombre de la última institución"
              type="text"
              name="nombreEscuela"
              value={usuario?.nombreEscuela || ""}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="telefono">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Teléfono de contacto
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="telefono"
              placeholder="Código de área + número"
              type="text"
              name="telefono"
              value={usuario?.telefono || ""}
              error={usuario?.telefono?.length > 0 && !validatePhone(usuario?.telefono)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="telefonoRespaldo">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Teléfono de contacto de respaldo
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="telefonoRespaldo"
              placeholder="Código de área + número"
              type="text"
              name="telefonoRespaldo"
              value={usuario?.telefonoRespaldo || ""}
              error={usuario?.telefonoRespaldo?.length > 0 && !validatePhone(usuario?.telefonoRespaldo)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="preferenciaHorario">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Preferencia de horario del curso de nivelación
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="preferenciaHorario"
              value={usuario?.preferenciaHorario || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              <MenuItem value={"Mañana"}>Mañana</MenuItem>
              <MenuItem value={"Tarde"}>Tarde</MenuItem>
              <MenuItem value={"Noche"}>Noche</MenuItem>
              <MenuItem value={"Indistinto"}>Indistinto</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="poseeBeneficiosSociales">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Posee beneficios sociales
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="poseeBeneficiosSociales"
              value={usuario?.poseeBeneficiosSociales || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              <MenuItem value={"Si"}>Si</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="escalaMatematicas">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Escala de conocimiento en matemáticas (1 a 10)
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="escalaMatematicas"
              value={usuario?.escalaMatematicas || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              {[...Array(10).keys()].map((i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="escalaComprensionTextos">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Escala de conocimiento en comprensión de textos (1 a 10)
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="escalaComprensionTextos"
              value={usuario?.escalaComprensionTextos || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              {[...Array(10).keys()].map((i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        display={{
          xs: "flex-row",
          sm: "flex",
        }}
        justifyContent="end"
        alignItems="center"
      >
        <SoftBox
          mt={{
            xs: 2,
            sm: 0,
          }}
        >
          <SoftButton
            color="uproGreen"
            circular
            disabled={
              loadingSave ||
              (selectedUsuario
                ? !usuario?.nombre ||
                  !usuario?.apellido ||
                  !usuario?.fechaNacimiento ||
                  !usuario?.idPais ||
                  !usuario?.genero ||
                  !usuario?.esDiscapacitado ||
                  !usuario?.idLocalidad ||
                  !usuario?.tipoEstudios ||
                  !usuario?.nombreEscuela ||
                  !usuario?.preferenciaHorario ||
                  !usuario?.poseeBeneficiosSociales ||
                  !usuario?.escalaMatematicas ||
                  !usuario?.escalaComprensionTextos ||
                  !usuario?.telefono ||
                  !usuario?.email ||
                  !usuario?.idProvincia ||
                  (usuario?.telefonoRespaldo && !validatePhone(usuario?.telefonoRespaldo)) ||
                  (usuario?.telefono && !validatePhone(usuario?.telefono)) ||
                  (usuario?.esDiscapacitado === "Si" && !usuario?.discapacidad) ||
                  (usuario?.nombre === selectedUsuario.nombre &&
                    usuario?.apellido === selectedUsuario.apellido &&
                    usuario?.dni === selectedUsuario.dni &&
                    usuario?.fechaNacimiento === selectedUsuario.fechaNacimiento &&
                    usuario?.idPais === selectedUsuario.pais?.id &&
                    usuario?.genero === selectedUsuario.genero &&
                    usuario?.esDiscapacitado === (selectedUsuario.esDiscapacitado ? "Si" : "No") &&
                    usuario?.discapacidad === selectedUsuario.discapacidad &&
                    usuario?.idLocalidad === selectedUsuario.localidad?.id &&
                    usuario?.tipoEstudios === selectedUsuario.tipoEstudios &&
                    usuario?.nombreEscuela === selectedUsuario.nombreEscuela &&
                    usuario?.preferenciaHorario === selectedUsuario.preferenciaHorario &&
                    usuario?.poseeBeneficiosSociales === (selectedUsuario.poseeBeneficiosSociales ? "Si" : "No") &&
                    usuario?.escalaMatematicas === selectedUsuario.escalaMatematicas &&
                    usuario?.escalaComprensionTextos === selectedUsuario.escalaComprensionTextos &&
                    usuario?.telefono === selectedUsuario.telefono &&
                    usuario?.telefonoRespaldo === selectedUsuario.telefonoRespaldo)
                : !usuario?.email ||
                  !usuario?.nombre ||
                  !usuario?.apellido ||
                  !usuario?.dni ||
                  (usuario?.esDiscapacitado === "Si" && !usuario?.discapacidad) ||
                  (usuario?.dni && !validateDNI(usuario?.dni)) ||
                  (usuario?.fechaNacimiento &&
                    dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year"))) ||
                  !validateEmail(usuario?.email) ||
                  usuarios?.usuarios.find((u) => u.username === usuario?.username) ||
                  usuarios?.usuarios.find((u) => u.email === usuario?.email))
            }
            onClick={handleSave}
            fullWidth={{
              xs: true,
              sm: false,
            }}
          >
            {loadingSave ? (
              <CircularProgress
                size={15}
                sx={{
                  marginRight: 0.5,
                }}
                color="inherit"
              />
            ) : (
              <Icon sx={{ fontWeight: "light" }}>save</Icon>
            )}
            &nbsp;Guardar
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

DatosPersonales.propTypes = {
  selectedUsuario: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
};
