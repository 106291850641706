// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "30%" },
  overflow: "auto",
};

export default function ModalInscripcionAdaptada({
  open,
  handleClose,
  idAlumno,
  idCarrera,
  idSede,
  refetch,
}) {
  const [materias, setMaterias] = useState([]);
  const [examenes, setExamenes] = useState([]);
  const [actas, setActas] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState();
  const [selectedExamen, setSelectedExamen] = useState();
  const [selectedTipo, setSelectedTipo] = useState();
  const [selectedActa, setSelectedActa] = useState();
  const [nota, setNota] = useState();
  const [estado, setEstado] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const { confirmar } = useContext(ConfirmarContext);

  const [getExamenes] = useLazyQuery(gql`
    query getExamenes($filter: JSON) {
      examenes(filter: $filter) {
        id
        titulo
        fecha
      }
    }
  `);

  const [inscribirExamenAdaptado] = useMutation(gql`
    mutation inscribirExamenAdaptado($input: ExamenAdaptadoInput!) {
      inscribirExamenAdaptado(input: $input)
    }
  `);

  const [getActasExamen] = useLazyQuery(gql`
    query actasExamen($idExamen: ID!) {
      actasExamen(idExamen: $idExamen) {
        id
        createdAt
        comision {
          id
          codigo
          nombre
        }
      }
    }
  `);

  const [getCarrera] = useLazyQuery(gql`
    query carrera($id: ID!) {
      carrera(id: $id) {
        id
        nombre
        planes {
          niveles {
            materias {
              id
              nombre
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (open && idCarrera) {
      getCarrera({ variables: { id: idCarrera } }).then((res) => {
        const plan = res.data.carrera.planes[0];
        const materias = [];
        plan.niveles.forEach((nivel) => {
          nivel.materias.forEach((materia) => {
            materias.push(materia);
          });
        });
        setMaterias(materias);
      });
    } else {
      setMaterias([]);
      setSelectedMateria();
    }
  }, [idCarrera, open]);

  useEffect(() => {
    if (selectedMateria && open) {
      getExamenes({
        variables: {
          filter: {
            idMateria: selectedMateria,
          },
        },
      }).then((res) => {
        setExamenes(res.data.examenes);
      });
    } else {
      setExamenes([]);
      setSelectedExamen();
    }
  }, [selectedMateria]);

  useEffect(() => {
    if (selectedExamen && open) {
      getActasExamen({ variables: { idExamen: selectedExamen } }).then((res) => {
        setActas(res.data.actasExamen);
      });
    }
  }, [selectedExamen]);

  console.log(actas);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
            <SoftTypography variant="h6">Inscripción a exámen adaptado</SoftTypography>
            <SoftBox display="flex" justifyContent="end" alignItems="center">
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <Tooltip title="Cerrar" placement="top">
                  <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Materia
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  label="Materia"
                  value={selectedMateria || -1}
                  onChange={(e) => {
                    setSelectedMateria(e.target.value);
                    setSelectedExamen();
                  }}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione una materia
                  </MenuItem>
                  {materias?.map((materia) => (
                    <MenuItem key={materia.id} value={materia.id}>
                      {materia.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Examen
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  label="Examen"
                  value={selectedExamen || -1}
                  onChange={(e) => setSelectedExamen(e.target.value)}
                >
                  <MenuItem value={-1} disabled>
                    {selectedMateria ? "Seleccione un examen" : "Seleccione una materia primero"}
                  </MenuItem>
                  {examenes?.map((examen) => (
                    <MenuItem key={examen.id} value={examen.id}>
                      {examen.titulo}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Tipo de adaptación
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  label="Examen"
                  value={selectedTipo || -1}
                  onChange={(e) => setSelectedTipo(e.target.value)}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione un tipo
                  </MenuItem>
                  <MenuItem value="Contenido">Contenido</MenuItem>
                  <MenuItem value="Acceso">Acceso</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Agregar al acta
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  label="Examen"
                  value={selectedActa || -1}
                  onChange={(e) => setSelectedActa(e.target.value)}
                >
                  <MenuItem value={-1} disabled>
                    {selectedExamen ? "Seleccione un acta" : "Seleccione un examen primero"}
                  </MenuItem>
                  {actas?.map((acta) => (
                    <MenuItem key={acta.id} value={acta.id}>
                      {`${acta.comision.codigo} - ${acta.comision.nombre} - ${dayjs(
                        acta.createdAt
                      ).format("DD/MM/YYYY")}`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={8}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Estado del exámen
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  fullWidth
                  label="Examen"
                  value={estado || -1}
                  onChange={(e) => setEstado(e.target.value)}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione un estado
                  </MenuItem>
                  <MenuItem value="Aprobado">Aprobado</MenuItem>
                  <MenuItem value="Desaprobado">Desaprobado</MenuItem>
                  <MenuItem value="Ausente">Ausente</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Calificación
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Tooltip
                  title={
                    estado === "Ausente"
                      ? "La nota debe ser 0"
                      : estado === "Aprobado"
                      ? "La nota debe ser mayor o igual a 70"
                      : estado === "Desaprobado"
                      ? "La nota debe ser menor a 70"
                      : "Ingrese la nota del examen"
                  }
                  placement="top"
                >
                  <SoftInputNumber
                    value={nota}
                    onChange={(e) => setNota(e.target.value)}
                    fullWidth
                    error={
                      nota < 0 ||
                      nota > 100 ||
                      (estado === "Ausente" && nota !== 0) ||
                      (estado === "Aprobado" && nota < 70) ||
                      (estado === "Desaprobado" && nota >= 70)
                    }
                    placeholder="Nota"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox p={3} pt={0}>
            <SoftButton
              circular
              color="uproGreen"
              fullWidth
              disabled={
                !selectedMateria ||
                !selectedExamen ||
                !selectedTipo ||
                !selectedActa ||
                !estado ||
                nota === undefined ||
                nota < 0 ||
                nota > 100 ||
                (estado === "Ausente" && nota !== 0) ||
                (estado === "Aprobado" && nota < 70) ||
                (estado === "Desaprobado" && nota >= 70)
              }
              onClick={() => {
                confirmar({
                  title: "Confirmar inscripción",
                  message: `
                  Está a punto de inscribir al alumno en el examen adaptado seleccionado. ¿Desea continuar?
                  `,
                  icon: "warning",
                  func: () => {
                    inscribirExamenAdaptado({
                      variables: {
                        input: {
                          idExamen: selectedExamen,
                          idAlumno: idAlumno,
                          tipoAdaptacion: selectedTipo,
                          idActa: selectedActa,
                          estadoExamen: estado,
                          nota: nota,
                        },
                      },
                    })
                      .then((response) => {
                        handleSnackbar("Inscripción agregada con éxito ", "success");
                        setSelectedMateria();
                        setSelectedExamen();
                        setSelectedTipo();
                        setSelectedActa();
                        setEstado();
                        setNota();
                        handleClose();
                        refetch();
                      })
                      .catch((e) => {
                        handleSnackbar(
                          e.message || "Ha ocurrido un error al intentar inscribir al alumno",
                          "error"
                        );
                      });
                  },
                });
              }}
            >
              Guardar &nbsp;
              <Icon>save</Icon>
            </SoftButton>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalInscripcionAdaptada.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idAlumno: PropTypes.string,
  idCarrera: PropTypes.string,
  idSede: PropTypes.string,
  refetch: PropTypes.func,
};
