// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalInscribirExamen({ open, handleClose, comision, idMateria }) {
  const [examenes, setExamenes] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const { confirmar } = useContext(ConfirmarContext);

  const [getExamenes, { data, loading }] = useLazyQuery(gql`
    query getExamenes($filter: JSON) {
      examenes(filter: $filter) {
        id
        titulo
        fecha
      }
    }
  `);

  const [inscribirComisionExamen, {loading: loadingInscribir}] = useMutation(gql`
    mutation inscribirComisionExamen($idsExamenes: [ID]!, $idComision: ID!) {
      inscribirComisionExamen(idsExamenes: $idsExamenes, idComision: $idComision)
    }
  `);

  useEffect(() => {
    if (open) {
      getExamenes({
        variables: {
          filter: {
            idMateria: idMateria,
            fecha: { _gte: dayjs().format("YYYY-MM-DD") },
          },
        },
      });
    }else{
        setExamenes();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  Inscribir alumnos de la comisión al examen
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Examen
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftTypography variant="caption">
                    (Si selecciona más de uno, se repartiran los alumnos entre los exámenes)
                  </SoftTypography>
                  <SoftBox mt={1}>
                    <Select
                      fullWidth
                      value={examenes || []}
                      onChange={(e) => setExamenes(e.target.value)}
                      multiple
                    >
                      {loading ? (
                        <MenuItem value={[]} disabled>
                          <Skeleton variant="text" width={"100%"} />
                        </MenuItem>
                      ) : data?.examenes.length === 0 ? (
                        <MenuItem value={[]} disabled>
                          No hay exámenes disponibles
                        </MenuItem>
                      ) : (
                        data?.examenes.map((examen) => (
                          <MenuItem key={examen.id} value={examen.id}>
                            <SoftTypography variant="caption">
                              {`${examen.titulo}`}&nbsp;
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="bold">
                              {` - ${dayjs(examen.fecha).format("DD/MM/YYYY")}`}
                            </SoftTypography>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingInscribir}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={examenes === undefined || examenes.length === 0 || loadingInscribir}
                  onClick={() => {
                    confirmar({
                      title: "Inscribir alumnos al examen",
                      message: (
                        <SoftBox>
                          <SoftTypography variant="h6" fontWeight="light">
                            ¿Estás seguro que deseas inscribir a
                            <span style={{ fontWeight: "bold" }}>
                              {` ${comision.cantidadInscriptos} alumnos `}
                            </span>
                            {`a ${
                              examenes.length > 1
                                ? "los siguientes exámenes?"
                                : "el siguiente examen?"
                            }`}
                          </SoftTypography>
                          {examenes.length > 1 && (
                            <SoftBox mt={2}>
                              <SoftTypography variant="h7" color="primary" fontWeight="light">
                                Los alumnos se distribuiran aleatoriamente entre los exámenes
                              </SoftTypography>
                            </SoftBox>
                          )}

                          <SoftBox mt={2} display="flex" flexDirection="column" gap={1}>
                            {examenes.map((examen) => (
                              <SoftTypography variant="h7" key={examen.id}>
                                {data?.examenes.find((e) => e.id === examen)?.titulo}
                              </SoftTypography>
                            ))}
                          </SoftBox>
                        </SoftBox>
                      ),
                      icon: "warning",
                      func: () => {
                        inscribirComisionExamen({
                          variables: {
                            idsExamenes: examenes,
                            idComision: comision.id,
                          },
                        })
                          .then(() => {
                            handleSnackbar("Alumnos inscriptos correctamente", "success");
                            handleClose();
                          })
                          .catch((error) => {
                            handleSnackbar(
                              error.message ||
                                "Ocurrió un error al inscribir los alumnos al examen",
                              "error"
                            );
                          });
                      },
                    });
                  }}
                >
                  {loadingInscribir ? <CircularProgress size={15} color="inherit" /> : <Icon sx={{ fontWeight: "light" }}>save</Icon>}
                  &nbsp;Inscribir
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalInscribirExamen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  comision: PropTypes.object,
  idMateria: PropTypes.number,
};
