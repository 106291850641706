// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalPlan({ open, handleClose, selectedPlan, idCarrera, refetch, sedes }) {
  const [plan, setPlan] = useState();
  const [cupos, setCupos] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const codigoRef = useRef(null);
  const nombreRef = useRef(null);

  const [getPlan, { loading: loading }] = useLazyQuery(
    gql`
      query getPlanEstudio($id: ID!) {
        planEstudio(id: $id) {
          id
          codigo
          nombre
          cupos {
            id
            capacidad
            nombre
            sede {
              id
            }
          }
        }
      }
    `,
    {
      cachePolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );

  const handleChanges = (e) => {
    setPlan({
      ...plan,
      [e.target.name]: e.target.value,
    });
  };

  const [savePlan, { loading: loadingSave }] = useMutation(
    gql`
      mutation savePlanEstudio($idCarrera: ID!, $input: PlanEstudioInput!) {
        savePlanEstudio(idCarrera: $idCarrera, input: $input) {
          id
        }
      }
    `
  );

  const [deleteCupo, { loading: loadingDelete }] = useMutation(
    gql`
      mutation deleteCupo($id: ID!) {
        deleteCupo(id: $id)
      }
    `
  );

  useEffect(() => {
    setCupos([]);
    setPlan();
    if (open && selectedPlan) {
      getPlan({
        variables: {
          id: selectedPlan?.id,
        },
      }).then((res) => {
        setPlan(res.data.planEstudio);
        setCupos(
          res.data.planEstudio.cupos.map((c) => {
            return {
              capacidad: c.capacidad,
              nombre: c.nombre,
              id: c.id || null,
              idSede: c.sede?.id || null,
              uniqueId: c.id,
            };
          })
        );
      });
    } else {
      setTimeout(() => {
        if (codigoRef.current) {
          codigoRef.current.focus();
        }
      }, 100);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedPlan ? `Editar plan` : `Agregar plan`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código del plan"
                    type="text"
                    name="codigo"
                    value={plan?.codigo || ""}
                    onChange={handleChanges}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        nombreRef.current.focus();
                      }
                    }}
                    inputRef={codigoRef}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre del plan"
                    type="text"
                    name="nombre"
                    value={plan?.nombre || ""}
                    onChange={handleChanges}
                    inputRef={nombreRef}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (
                          !loading &&
                          plan?.codigo &&
                          plan?.nombre &&
                          (plan?.codigo !== selectedPlan?.codigo ||
                            plan?.nombre !== selectedPlan?.nombre)
                        ) {
                          savePlan({
                            variables: {
                              idCarrera: idCarrera,
                              input: {
                                id: plan?.id || null,
                                codigo: plan?.codigo,
                                nombre: plan?.nombre,
                              },
                            },
                          })
                            .then(() => {
                              handleSnackbar("Plan guardado correctamente", "success");
                              refetch();
                              handleClose();
                            })
                            .catch((e) => {
                              handleSnackbar("Ocurrió un error al guardar el plan", "error");
                            });
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cupos
                    </SoftTypography>
                  </InputLabel>
                </Grid>
                {cupos.map((cupo, index) => (
                  <Grid item xs={12} key={cupo.uniqueId}>
                    <SoftBox display="flex" gap={2}>
                      <SoftBox>
                        <InputLabel htmlFor="codigo">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Capacidad
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          </SoftTypography>
                        </InputLabel>
                        <SoftInputNumber
                          label="capacidad"
                          placeholder="Ingrese la capacidad del cupo"
                          type="text"
                          name="capacidad"
                          value={cupo.capacidad || ""}
                          onChange={(e) => {
                            setCupos(
                              cupos.map((c) => {
                                if (c.uniqueId === cupo.uniqueId) {
                                  return {
                                    ...c,
                                    capacidad: e.target.value,
                                  };
                                }
                                return c;
                              })
                            );
                          }}
                        />
                      </SoftBox>
                      <SoftBox
                        sx={{
                          width: "100%",
                        }}
                      >
                        <InputLabel htmlFor="nombre">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Nombre
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          </SoftTypography>
                        </InputLabel>
                        <SoftInput
                          label="nombre"
                          placeholder="Ej. Turno tarde"
                          type="text"
                          name="nombre"
                          value={cupo.nombre || ""}
                          onChange={(e) => {
                            setCupos(
                              cupos.map((c) => {
                                if (c.uniqueId === cupo.uniqueId) {
                                  return {
                                    ...c,
                                    nombre: e.target.value,
                                  };
                                }
                                return c;
                              })
                            );
                          }}
                        />
                      </SoftBox>
                      <SoftBox sx={{ width: "100%" }}>
                        <InputLabel htmlFor="nombre">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Sede
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          </SoftTypography>
                        </InputLabel>
                        <Select
                          value={cupo.idSede}
                          onChange={(e) => {
                            setCupos(
                              cupos.map((c) => {
                                if (c.uniqueId === cupo.uniqueId) {
                                  return {
                                    ...c,
                                    idSede: e.target.value,
                                  };
                                }
                                return c;
                              })
                            );
                          }}
                        >
                          {sedes.map((sede) => (
                            <MenuItem key={sede.id} value={sede.id}>
                              {sede.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </SoftBox>
                      <SoftBox>
                        <InputLabel htmlFor="nombre">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            &nbsp;
                          </SoftTypography>
                        </InputLabel>
                        <SoftButton
                          circular
                          iconOnly
                          color="primary"
                          onClick={() => {
                            if (cupo.id) {
                              deleteCupo({
                                variables: {
                                  id: cupo.id,
                                },
                              })
                                .then(() => {
                                  setCupos(cupos.filter((c) => c.uniqueId !== cupo.uniqueId));
                                  handleSnackbar("Cupo eliminado correctamente", "success");
                                })
                                .catch((e) => {
                                  handleSnackbar("Ocurrió un error al eliminar el cupo", "error");
                                });
                            } else {
                              setCupos(cupos.filter((c) => c.uniqueId !== cupo.uniqueId));
                            }
                          }}
                        >
                          <Icon>close</Icon>
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                ))}
                <Grid item xs={12} mt={2}>
                  <SoftButton
                    color="uproGreen"
                    circular
                    fullWidth
                    onClick={() =>
                      setCupos([
                        ...cupos,
                        {
                          uniqueId: new Date().getTime(),
                        },
                      ])
                    }
                  >
                    Agregar cupo&nbsp;
                    <Icon sx={{ fontWeight: "light" }}>add</Icon>
                  </SoftButton>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loading}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={
                    loading ||
                    !plan?.codigo ||
                    !plan?.nombre ||
                    cupos.find((c) => !c.capacidad || !c.nombre || !c.idSede)
                  }
                  onClick={() => {
                    savePlan({
                      variables: {
                        idCarrera: idCarrera,
                        input: {
                          id: plan?.id || null,
                          codigo: plan?.codigo,
                          nombre: plan?.nombre,
                          cupos: cupos.map((c) => {
                            return {
                              id: c.id || null,
                              capacidad: c.capacidad,
                              nombre: c.nombre,
                              idSede: c.idSede,
                            };
                          }),
                        },
                      },
                    })
                      .then(() => {
                        handleSnackbar("Plan guardado correctamente", "success");
                        refetch();
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar("Ocurrió un error al guardar el plan", "error");
                      });
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalPlan.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedPlan: PropTypes.object,
  idCarrera: PropTypes.string,
  refetch: PropTypes.func,
  sedes: PropTypes.array,
};
