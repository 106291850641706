// React

// Components
import { Card, CircularProgress, Collapse, Grid, Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

// Data
import { gql, useMutation } from "@apollo/client";
import SoftBadge from "components/SoftBadge";
import { MessageManager } from "context";
import { useContext, useRef, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import ProfileCard from "../ProfileCard";

export default function Resumen({ usuario, carrera, loading, refetch, setTab }) {
  const [expanded, setExpanded] = useState(["Documentacion"]);
  const [selectedInput, setSelectedInput] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);
  const documentacionRequerida = [
    {
      nombre: "DNI Frente",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "DNI Dorso",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "Certificado de Salud",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "Certificado de Discapacidad (CUD)",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "Declaración Jurada",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "Constancia de Finalización de Estudios Secundarios",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "Certificado Analítico",
      requerido: true,
      ref: useRef(),
    },
    {
      nombre: "Constancia de estudios en progreso",
      requerido: true,
      ref: useRef(),
    },
  ];

  const [addUserFile, { loading: loadingFile }] = useMutation(gql`
    mutation addUserFile($idUser: ID!, $file: Upload!, $nombre: String!) {
      addUserFile(idUser: $idUser, file: $file, nombre: $nombre) {
        id
      }
    }
  `);

  const [deleteUserFile, { loading: loadingDelete }] = useMutation(gql`
    mutation deleteUserFile($id: ID!) {
      deleteUserFile(id: $id) {
        id
      }
    }
  `);

  return (
    <SoftBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <ProfileCard
            usuario={usuario}
            carrera={carrera}
            loading={loading}
            handleClick={() => setTab(2)}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Card>
            <SoftBox
              p={{
                xs: 0,
                sm: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    id="documentacionCard"
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Documentacion")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Documentacion")
                            ? expanded.filter((i) => i !== "Documentacion")
                            : [...expanded, "Documentacion"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">description</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Documentación requerida
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Documentacion") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Documentacion")
                                    ? expanded.filter((i) => i !== "Documentacion")
                                    : [...expanded, "Documentacion"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Documentacion") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Documentacion")}>
                      {documentacionRequerida.map((documento, index) => (
                        <SoftBox key={index}>
                          <SoftBox
                            px={2}
                            py={1}
                            sx={{
                              borderBottom:
                                index === documentacionRequerida.length - 1
                                  ? "none"
                                  : "1px solid rgba(0, 0, 0, 0.12)",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                          >
                            <SoftBox
                              display={{
                                xs: "flex-row",
                                sm: "flex",
                              }}
                              justifyContent="space-between"
                            >
                              <SoftBox
                                display="flex"
                                alignItems="center"
                                justifyContent={{
                                  xs: "space-between",
                                  sm: "flex-start",
                                }}
                                pl={{
                                  xs: 0,
                                  sm: 2,
                                }}
                              >
                                <SoftBox display="flex">
                                  <SoftBox
                                    mr={{
                                      xs: 0,
                                      sm: 1,
                                    }}
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "block",
                                      },
                                    }}
                                  >
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox mr={1}>
                                    <Tooltip title="Documento" placement="top">
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproYellow"),
                                        }}
                                      >
                                        file_present
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">{documento.nombre}</SoftTypography>
                                </SoftBox>
                                <SoftBox ml={1} display="flex" alignItems="center">
                                  <SoftBadge
                                    color={
                                      usuario?.documentos?.find(
                                        (doc) => doc.nombre === documento.nombre
                                      )?.estado === "Aprobado"
                                        ? "uproGreen"
                                        : usuario?.documentos?.find(
                                            (doc) => doc.nombre === documento.nombre
                                          )?.estado === "Pendiente"
                                        ? "uproYellow"
                                        : usuario?.documentos?.find(
                                            (doc) => doc.nombre === documento.nombre
                                          )?.estado === "Rechazado"
                                        ? "error"
                                        : "primary"
                                    }
                                    badgeContent={
                                      usuario?.documentos?.find(
                                        (doc) => doc.nombre === documento.nombre
                                      )?.estado || "Sin cargar"
                                    }
                                  />
                                </SoftBox>
                              </SoftBox>
                              <SoftBox
                                display={{
                                  xs: "flex-row",
                                  sm: "flex",
                                }}
                                justifyContent={{
                                  xs: "center",
                                  sm: "flex-end",
                                }}
                                my={{
                                  xs: 1,
                                  sm: 0,
                                }}
                                alignItems="center"
                              >
                                {!usuario?.documentos?.find(
                                  (doc) => doc.nombre === documento.nombre
                                ) && (
                                  <SoftBox
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "block",
                                      },
                                    }}
                                  >
                                    <Tooltip title={"Subir archivo"} placement="top">
                                      <SoftButton
                                        color="uproYellow"
                                        circular
                                        onClick={() => {
                                          setSelectedInput(documento.nombre);
                                          documento.ref.current.value = null;
                                          documento.ref.current.click();
                                        }}
                                        disabled={loadingFile && selectedInput === documento.nombre}
                                        fullWidth={{
                                          xs: true,
                                          sm: false,
                                        }}
                                      >
                                        {loadingFile && selectedInput === documento.nombre ? (
                                          <CircularProgress
                                            size={15}
                                            color="white"
                                            sx={{
                                              marginRight: 0.5,
                                            }}
                                          />
                                        ) : (
                                          <Icon>upload</Icon>
                                        )}
                                        &nbsp;Subir
                                      </SoftButton>
                                    </Tooltip>
                                    <input
                                      ref={documento.ref}
                                      type="file"
                                      style={{ display: "none" }}
                                      accept=".pdf, .docx, .jpg, .jpeg, .png"
                                      onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && usuario?.id && file.size <= 5000000) {
                                          addUserFile({
                                            variables: {
                                              idUser: usuario?.id,
                                              file: file,
                                              nombre: documento.nombre,
                                            },
                                          })
                                            .then(() => {
                                              handleSnackbar(
                                                "Archivo subido correctamente",
                                                "success"
                                              );
                                              refetch();
                                            })
                                            .catch(() => {
                                              handleSnackbar("Error al subir el archivo", "error");
                                            });
                                        } else {
                                          handleSnackbar(
                                            "El archivo debe ser menor a 5MB",
                                            "error"
                                          );
                                        }
                                      }}
                                    />
                                  </SoftBox>
                                )}
                                {!usuario?.documentos?.find(
                                  (doc) => doc.nombre === documento.nombre
                                ) && (
                                  <SoftBox
                                    mb={1}
                                    sx={{
                                      display: {
                                        xs: "block",
                                        sm: "none",
                                      },
                                      width: "100%",
                                    }}
                                  >
                                    <Tooltip title={"Subir archivo"} placement="top">
                                      <SoftButton
                                        color="uproYellow"
                                        circular
                                        onClick={() => {
                                          setSelectedInput(documento.nombre);
                                          documento.ref.current.value = null;
                                          documento.ref.current.click();
                                        }}
                                        disabled={loadingFile && selectedInput === documento.nombre}
                                        fullWidth
                                      >
                                        {loadingFile && selectedInput === documento.nombre ? (
                                          <CircularProgress
                                            size={15}
                                            color="white"
                                            sx={{
                                              marginRight: 0.5,
                                            }}
                                          />
                                        ) : (
                                          <Icon>upload</Icon>
                                        )}
                                        &nbsp;Subir
                                      </SoftButton>
                                    </Tooltip>
                                    <input
                                      ref={documento.ref}
                                      type="file"
                                      style={{ display: "none" }}
                                      accept=".pdf, .docx, .jpg, .jpeg, .png"
                                      onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && usuario?.id && file.size <= 5000000) {
                                          addUserFile({
                                            variables: {
                                              idUser: usuario?.id,
                                              file: file,
                                              nombre: documento.nombre,
                                            },
                                          })
                                            .then(() => {
                                              handleSnackbar(
                                                "Archivo subido correctamente",
                                                "success"
                                              );
                                              refetch();
                                            })
                                            .catch(() => {
                                              handleSnackbar("Error al subir el archivo", "error");
                                            });
                                        } else {
                                          handleSnackbar(
                                            "El archivo debe ser menor a 5MB",
                                            "error"
                                          );
                                        }
                                      }}
                                    />
                                  </SoftBox>
                                )}
                                {usuario?.documentos?.find(
                                  (doc) => doc.nombre === documento.nombre
                                ) &&
                                  usuario?.documentos?.find(
                                    (doc) => doc.estado === "Pendiente"
                                  ) && (
                                    <SoftBox
                                      mr={1}
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "block",
                                        },
                                      }}
                                    >
                                      <Tooltip title={"Eliminar archivo"} placement="top">
                                        <SoftButton
                                          color="error"
                                          circular
                                          iconOnly
                                          disabled={
                                            loadingDelete && selectedInput === documento.nombre
                                          }
                                          onClick={() => {
                                            setSelectedInput(documento.nombre);
                                            const file = usuario?.documentos?.find(
                                              (doc) => doc.nombre === documento.nombre
                                            );
                                            if (file) {
                                              deleteUserFile({
                                                variables: {
                                                  id: file.id,
                                                },
                                              })
                                                .then(() => {
                                                  handleSnackbar(
                                                    "Archivo eliminado correctamente",
                                                    "success"
                                                  );
                                                  refetch();
                                                  setSelectedInput(null);
                                                })
                                                .catch(() => {
                                                  handleSnackbar(
                                                    "Error al eliminar el archivo",
                                                    "error"
                                                  );
                                                  setSelectedInput(null);
                                                });
                                            }
                                          }}
                                        >
                                          {loadingDelete && selectedInput === documento.nombre ? (
                                            <CircularProgress size={15} color="white" />
                                          ) : (
                                            <Icon>delete</Icon>
                                          )}
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  )}
                                {usuario?.documentos?.find(
                                  (doc) => doc.nombre === documento.nombre
                                ) &&
                                  usuario?.documentos?.find(
                                    (doc) => doc.estado === "Pendiente"
                                  ) && (
                                    <SoftBox
                                      mt={1}
                                      sx={{
                                        display: {
                                          xs: "block",
                                          sm: "none",
                                        },
                                        width: "100%",
                                      }}
                                    >
                                      <Tooltip title={"Eliminar archivo"} placement="top">
                                        <SoftButton
                                          color="error"
                                          circular
                                          fullWidth
                                          disabled={
                                            loadingDelete && selectedInput === documento.nombre
                                          }
                                          onClick={() => {
                                            setSelectedInput(documento.nombre);
                                            const file = usuario?.documentos?.find(
                                              (doc) => doc.nombre === documento.nombre
                                            );
                                            if (file) {
                                              deleteUserFile({
                                                variables: {
                                                  id: file.id,
                                                },
                                              })
                                                .then(() => {
                                                  handleSnackbar(
                                                    "Archivo eliminado correctamente",
                                                    "success"
                                                  );
                                                  refetch();
                                                  setSelectedInput(null);
                                                })
                                                .catch(() => {
                                                  handleSnackbar(
                                                    "Error al eliminar el archivo",
                                                    "error"
                                                  );
                                                  setSelectedInput(null);
                                                });
                                            }
                                          }}
                                        >
                                          {loadingDelete && selectedInput === documento.nombre ? (
                                            <CircularProgress size={15} color="white" />
                                          ) : (
                                            <Icon>delete</Icon>
                                          )}
                                          &nbsp;Eliminar
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  )}
                                {usuario?.documentos?.find(
                                  (doc) => doc.nombre === documento.nombre
                                ) && (
                                  <SoftBox
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "block",
                                      },
                                    }}
                                  >
                                    <Tooltip title={"Descargar archivo"} placement="top">
                                      <SoftButton
                                        color="uproGreen"
                                        circular
                                        iconOnly
                                        onClick={async () => {
                                          const doc = usuario?.documentos?.find(
                                            (doc) => doc.nombre === documento.nombre
                                          );
                                          if (doc) {
                                            const file = await fetch(`${API_URL}/${doc.file}`);

                                            const name = `${doc.nombre} - ${usuario.dni}.${doc.file
                                              .split(".")
                                              .pop()}`;
                                            const blob = await file.blob();
                                            const url = URL.createObjectURL(blob);

                                            const a = document.createElement("a");
                                            a.href = url;
                                            a.download = name;
                                            a.click();
                                          }
                                        }}
                                      >
                                        <Icon>download</Icon>
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                )}
                                {usuario?.documentos?.find(
                                  (doc) => doc.nombre === documento.nombre
                                ) && (
                                  <SoftBox
                                    mt={1}
                                    sx={{
                                      display: {
                                        xs: "block",
                                        sm: "none",
                                      },
                                      width: "100%",
                                    }}
                                  >
                                    <Tooltip title={"Descargar archivo"} placement="top">
                                      <SoftButton
                                        color="uproGreen"
                                        circular
                                        fullWidth
                                        onClick={async () => {
                                          const doc = usuario?.documentos?.find(
                                            (doc) => doc.nombre === documento.nombre
                                          );
                                          if (doc) {
                                            const file = await fetch(`${API_URL}/${doc.file}`);

                                            const name = `${doc.nombre} - ${usuario.dni}.${doc.file
                                              .split(".")
                                              .pop()}`;
                                            const blob = await file.blob();
                                            const url = URL.createObjectURL(blob);

                                            const a = document.createElement("a");
                                            a.href = url;
                                            a.download = name;
                                            a.click();
                                          }
                                        }}
                                      >
                                        <Icon>download</Icon>
                                        &nbsp;Descargar
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                )}
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      ))}
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Resumen.propTypes = {
  usuario: PropTypes.object,
  carrera: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  setTab: PropTypes.func,
};
