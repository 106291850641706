import { Backdrop, Card, CircularProgress, Fade, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { gql, useMutation } from "@apollo/client";
import { MessageManager } from "context";
import { API_URL } from "services/config";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", md: "40%", lg: "35%", xl: "30%" },
  overflow: "auto",
};

export default function ModalNuevaPass({ open, handleClose, idUser, codigo }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [changePass, { loading }] = useMutation(gql`
    mutation changePass($idUser: ID!, $password: String!, $codigo: String!) {
      changePass(idUser: $idUser, password: $password, codigo: $codigo)
    }
  `);

  const handleSubmit = (event) => {
    event.preventDefault();
    changePass({ variables: { idUser: idUser, password: password, codigo: codigo } })
      .then((result) => {
        if (result.data.changePass) {
          handleSnackbar(
            "Contraseña cambiada correctamente, inicia sesión con tu nueva clave.",
            "success"
          );
          handleClose();
        } else {
          handleSnackbar("Error al cambiar contraseña", "error");
        }
      })
      .catch((error) => {
        handleSnackbar("Error al cambiar contraseña", "error");
      });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox p={3}>
            <SoftBox display="flex" justifyContent="space-between" >
              <SoftBox display="flex">
                <SoftTypography variant="h4" color="dark" fontWeight="bold">
                  🔒 Nueva
                </SoftTypography>
                <SoftTypography variant="h4" color="primary" fontWeight="bold">
                  &nbsp;Contraseña
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </SoftBox>
            </SoftBox>
            <SoftBox mt={2}>
              <SoftTypography fontSize="medium">
                Para comenzar, queremos asegurarnos de tu seguridad. Por favor, ingresa una nueva
                contraseña para proteger tu cuenta y tus experiencias futuras.
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={2}>
              <Tooltip
                title={
                  !/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password)
                    ? "Almenos 8 caracteres, 1 mayúscula y 1 número"
                    : ""
                }
                open={true}
                placement="top"
              >
                <SoftInput
                  placeholder="Nueva contraseña"
                  type="password"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Tooltip>
            </SoftBox>
            <SoftBox mt={2}>
              <Tooltip
                title={
                  /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password) && password != repeatPassword
                    ? "Las contraseñas deben coincidir"
                    : ""
                }
                open={true}
                placement="bottom"
              >
                <SoftInput
                  placeholder="Repetir contraseña"
                  type="password"
                  fullWidth
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  onKeyPress={(e) =>
                    e.key === "Enter" &&
                    password === repeatPassword &&
                    !loading &&
                    /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password)
                      ? handleSubmit(e)
                      : null
                  }
                />
              </Tooltip>
            </SoftBox>
            <SoftBox mt={2}>
              <SoftButton
                color="primary"
                fullWidth
                circular
                disabled={
                  password != repeatPassword ||
                  !/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password) ||
                  loading
                }
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress color="inherit" size={15} /> : <Icon>done</Icon>}
                &nbsp;Cambiar contraseña
              </SoftButton>
            </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalNuevaPass.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idUser: PropTypes.string,
  codigo: PropTypes.string,
};
