import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { getUproColor } from "utils/colors";
import SinDatos from "components/Carteles/SinDatos";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import dayjs from "dayjs";
import { Link, useLocation } from "react-router-dom";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { MessageManager } from "context";
import ModalExito from "./components/ModalExito";
import { API_URL } from "services/config";

const initialState = {
  titulo: "",
  tipo: "",
  modalidad: "",
  ordenAleatorioPreguntas: 0,
  ordenAleatorioRespuestas: 0,
  grupos: [],
};

function reducer(estado, action) {
  switch (action.type) {
    case "SET_EXAMEN":
      return {
        ...estado,
        ...action.payload,
        ordenAleatorioPreguntas: action.payload.ordenAleatorioPreguntas ? 1 : 0,
        ordenAleatorioRespuestas: action.payload.ordenAleatorioRespuestas ? 1 : 0,
      };
    case "SET_FIELD":
      return { ...estado, [action.field]: action.value };
    case "ADD_GRUPO":
      return {
        ...estado,
        grupos: [
          ...estado.grupos,
          {
            id: new Date().getTime(),
            nombre: "",
            preguntas: [
              {
                id: new Date().getTime(),
                pregunta: "",
                tipo: "multipleChoice", // Tipo por defecto
                opciones: [{ opcion: "", correcta: true }],
                archivo: null,
              },
            ],
          },
        ],
      };
    case "UPDATE_GRUPO":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.index ? { ...grupo, ...action.payload } : grupo
        ),
      };
    case "REMOVE_GRUPO":
      return {
        ...estado,
        grupos: estado.grupos.filter((_, index) => index !== action.index),
      };
    case "ADD_PREGUNTA":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.index
            ? {
                ...grupo,
                preguntas: [
                  ...grupo.preguntas,
                  {
                    id: new Date().getTime(),
                    pregunta: "",
                    tipo: "multipleChoice", // Tipo por defecto
                    opciones: [{ opcion: "", correcta: true }],
                    archivo: null,
                  },
                ],
              }
            : grupo
        ),
      };
    case "REMOVE_PREGUNTA":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.grupoIndex
            ? {
                ...grupo,
                preguntas: grupo.preguntas.filter((_, i) => i !== action.preguntaIndex),
              }
            : grupo
        ),
      };
    case "ADD_OPCION":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.grupoIndex
            ? {
                ...grupo,
                preguntas: grupo.preguntas.map((pregunta, i) =>
                  i === action.preguntaIndex
                    ? {
                        ...pregunta,
                        opciones: [...pregunta.opciones, { opcion: "", correcta: false }],
                      }
                    : pregunta
                ),
              }
            : grupo
        ),
      };
    case "REMOVE_OPCION":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.grupoIndex
            ? {
                ...grupo,
                preguntas: grupo.preguntas.map((pregunta, i) =>
                  i === action.preguntaIndex
                    ? {
                        ...pregunta,
                        opciones: pregunta.opciones.filter((_, j) => j !== action.opcionIndex),
                      }
                    : pregunta
                ),
              }
            : grupo
        ),
      };
    case "SET_CORRECTA":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.grupoIndex
            ? {
                ...grupo,
                preguntas: grupo.preguntas.map((pregunta, i) =>
                  i === action.preguntaIndex
                    ? {
                        ...pregunta,
                        opciones: pregunta.opciones.map((opcion, j) => ({
                          ...opcion,
                          correcta: j === action.opcionIndex,
                        })),
                      }
                    : pregunta
                ),
              }
            : grupo
        ),
      };
    case "SET_TIPO_PREGUNTA":
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.grupoIndex
            ? {
                ...grupo,
                preguntas: grupo.preguntas.map((pregunta, i) =>
                  i === action.preguntaIndex
                    ? {
                        ...pregunta,
                        tipo: action.tipo,
                        opciones:
                          action.tipo === "multipleChoice"
                            ? pregunta.opciones.length === 0
                              ? [{ opcion: "", correcta: true }]
                              : pregunta.opciones
                            : [],
                      }
                    : pregunta
                ),
              }
            : grupo
        ),
      };
    case "REORDER_PREGUNTAS":
      const reorderedPreguntas = Array.from(estado.grupos[action.grupoIndex].preguntas);
      const [removed] = reorderedPreguntas.splice(action.startIndex, 1);
      reorderedPreguntas.splice(action.endIndex, 0, removed);
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) =>
          index === action.grupoIndex ? { ...grupo, preguntas: reorderedPreguntas } : grupo
        ),
      };
    case "MOVE_PREGUNTA":
      const sourcePreguntas = Array.from(estado.grupos[action.sourceGrupoIndex].preguntas);
      const [movedPregunta] = sourcePreguntas.splice(action.sourceIndex, 1);
      const destPreguntas = Array.from(estado.grupos[action.destGrupoIndex].preguntas);
      destPreguntas.splice(action.destIndex, 0, movedPregunta);
      return {
        ...estado,
        grupos: estado.grupos.map((grupo, index) => {
          if (index === action.sourceGrupoIndex) {
            return { ...grupo, preguntas: sourcePreguntas };
          }
          if (index === action.destGrupoIndex) {
            return { ...grupo, preguntas: destPreguntas };
          }
          return grupo;
        }),
      };
    case "SET_EXAMEN_ID":
      return {
        ...estado,
        id: action.payload,
      };
    default:
      return estado;
  }
}

const GET_EXAMEN = gql`
  query getExamen($id: ID!) {
    examen(id: $id) {
      id
      titulo
      tipo
      modalidad
      fecha
      textos
      duracion
      ordenAleatorioPreguntas
      ordenAleatorioRespuestas
      grupos {
        id
        orden
        puntaje
        preguntas {
          id
          titulo
          archivo
          opciones {
            opcion
            correcta
          }
          tipo
        }
      }
    }
  }
`;

function CreadorExamenes() {
  const [estado, dispatch] = useReducer(reducer, initialState);
  let { state } = useLocation();
  const [materia, setMateria] = useState(state?.materia || null);
  const { handleSnackbar } = useContext(MessageManager);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [saveExamen, { loading }] = useMutation(
    gql`
      mutation saveExamen($input: ExamenInput!) {
        saveExamen(input: $input) {
          id
        }
      }
    `
  );

  const [saveExamenFile, { loading: loadingFile }] = useMutation(
    gql`
      mutation saveExamenFile($file: Upload!) {
        saveExamenFile(file: $file)
      }
    `
  );

  const [getExamen, { data }] = useLazyQuery(GET_EXAMEN, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.examen) {
        dispatch({
          type: "SET_EXAMEN",
          payload: {
            ...data.examen,
            readOnly: state?.readOnly,
          },
        });
      } else {
        handleSnackbar("Error al obtener el examen", "error");
      }
    },
    onError: (error) => {
      handleSnackbar(error.message || "Error al obtener el examen", "error");
    },
  });

  useEffect(() => {
    if (state?.idExamen) {
      getExamen({ variables: { id: state.idExamen } });
    }
  }, [state, getExamen]);

  const handleFieldChange = useCallback((field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  }, []);

  const handleAddGrupo = useCallback(() => {
    dispatch({ type: "ADD_GRUPO" });
  }, []);

  const handleAddPregunta = useCallback((index) => {
    dispatch({ type: "ADD_PREGUNTA", index });
  }, []);

  const handleRemoveGrupo = useCallback((index) => {
    dispatch({ type: "REMOVE_GRUPO", index });
  }, []);

  const handleRemovePregunta = useCallback((grupoIndex, preguntaIndex) => {
    dispatch({ type: "REMOVE_PREGUNTA", grupoIndex, preguntaIndex });
  }, []);

  const handleAddOpcion = useCallback((grupoIndex, preguntaIndex) => {
    dispatch({ type: "ADD_OPCION", grupoIndex, preguntaIndex });
  }, []);

  const handleRemoveOpcion = useCallback((grupoIndex, preguntaIndex, opcionIndex) => {
    dispatch({ type: "REMOVE_OPCION", grupoIndex, preguntaIndex, opcionIndex });
  }, []);

  const handleSetCorrecta = useCallback((grupoIndex, preguntaIndex, opcionIndex) => {
    dispatch({ type: "SET_CORRECTA", grupoIndex, preguntaIndex, opcionIndex });
  }, []);

  const handleSetTipoPregunta = useCallback((grupoIndex, preguntaIndex, tipo) => {
    dispatch({ type: "SET_TIPO_PREGUNTA", grupoIndex, preguntaIndex, tipo });
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.index !== destination.index) {
        // Reorder within the same group
        dispatch({
          type: "REORDER_PREGUNTAS",
          grupoIndex: parseInt(source.droppableId),
          startIndex: source.index,
          endIndex: destination.index,
        });
      }
    } else {
      // Move to a different group
      dispatch({
        type: "MOVE_PREGUNTA",
        sourceGrupoIndex: parseInt(source.droppableId),
        destGrupoIndex: parseInt(destination.droppableId),
        sourceIndex: source.index,
        destIndex: destination.index,
      });
    }
  };

  const validateForm = () => {
    if (!estado.titulo) {
      handleSnackbar("El título del examen es obligatorio.", "error");
      return false;
    }

    if (!estado.tipo) {
      handleSnackbar("El tipo de examen es obligatorio.", "error");
      return false;
    }

    if (!estado.modalidad) {
      handleSnackbar("La modalidad de examen es obligatoria.", "error");
      return false;
    }

    if (!estado.fecha) {
      handleSnackbar("La fecha y hora de inicio son obligatorias.", "error");
      return false;
    }

    if (!estado.duracion) {
      handleSnackbar("La duración del examen es obligatoria.", "error");
      return false;
    }

    if (estado.grupos.length === 0) {
      handleSnackbar("Debe haber al menos un grupo de preguntas.", "error");
      return false;
    }

    let totalPuntaje = estado.grupos.reduce((acc, grupo) => acc + (grupo.puntaje || 0), 0);
    if (totalPuntaje !== 100) {
      handleSnackbar("La suma de los puntajes de los grupos debe ser 100.", "error");
      return false;
    }

    for (let grupo of estado.grupos) {
      if (grupo.preguntas.length === 0) {
        handleSnackbar("No puede haber grupos vacíos.", "error");
        return false;
      }

      if (!grupo.puntaje) {
        handleSnackbar("Todos los grupos deben tener un puntaje.", "error");
        return false;
      }

      for (let pregunta of grupo.preguntas) {
        if (!pregunta.titulo) {
          handleSnackbar("Todas las preguntas deben tener un título.", "error");
          return false;
        }

        if (pregunta.tipo === "multipleChoice") {
          if (pregunta.opciones.length === 0) {
            handleSnackbar(
              "Las preguntas de tipo 'multiple choice' deben tener al menos una opción.",
              "error"
            );
            return false;
          }

          let hasCorrectAnswer = pregunta.opciones.some((opcion) => opcion.correcta);
          if (!hasCorrectAnswer) {
            handleSnackbar(
              "Las preguntas de tipo 'multiple choice' deben tener al menos una respuesta correcta.",
              "error"
            );
            return false;
          }

          for (let opcion of pregunta.opciones) {
            if (!opcion.opcion) {
              handleSnackbar("Todas las opciones deben tener un texto.", "error");
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">{`${
                    estado.id ? (estado.readOnly ? "Ver" : "Editar") : "Crear"
                  } examen de ${materia ? materia.nombre : "materia"} ${
                    estado.readOnly ? "(Solo lectura)" : ""
                  }`}</SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="titulo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Título del examen
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="titulo"
                    placeholder="Ingrese un título para el examen"
                    type="text"
                    name="titulo"
                    value={estado.titulo}
                    readOnly={estado.readOnly}
                    onChange={(e) => handleFieldChange("titulo", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="tipo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Tipo de examen
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="tipo"
                    value={estado.tipo}
                    readOnly={estado.readOnly}
                    onChange={(e) => handleFieldChange("tipo", e.target.value)}
                  >
                    <MenuItem value="Parcial">Parcial</MenuItem>
                    <MenuItem value="Final">Final</MenuItem>
                    <MenuItem value="Recuperatorio">Recuperatorio</MenuItem>
                    <MenuItem value="TP">Trabajo práctico</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="modalidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Modalidad de examen
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="modalidad"
                    value={estado.modalidad}
                    readOnly={estado.readOnly}
                    onChange={(e) => handleFieldChange("modalidad", e.target.value)}
                  >
                    <MenuItem value="Virtual">Virtual</MenuItem>
                    <MenuItem value="Híbrido">Híbrido</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="ordenAleatorioPreguntas">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Orden aleatorio de preguntas
                    </SoftTypography>
                  </InputLabel>
                  <Tabs
                    value={estado.ordenAleatorioPreguntas}
                    name="ordenAleatorioPreguntas"
                    onChange={(_, value) => {
                      estado.readOnly || handleFieldChange("ordenAleatorioPreguntas", value);
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={estado.ordenAleatorioPreguntas === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={estado.ordenAleatorioPreguntas === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="ordenAleatorioRespuestas">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Orden aleatorio de respuestas
                    </SoftTypography>
                  </InputLabel>
                  <Tabs
                    value={estado.ordenAleatorioRespuestas}
                    name="ordenAleatorioRespuestas"
                    onChange={(_, value) =>
                      estado.readOnly || handleFieldChange("ordenAleatorioRespuestas", value)
                    }
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={estado.ordenAleatorioRespuestas === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={estado.ordenAleatorioRespuestas === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="ordenAleatorioRespuestas">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha de examen
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="fecha"
                    placeholder="Ingrese una fecha"
                    type="date"
                    name="fecha"
                    readOnly={estado.readOnly}
                    value={estado.fecha}
                    onChange={(e) => handleFieldChange("fecha", e.target.value)}
                    error={
                      estado.fecha &&
                      dayjs(estado.fecha).isBefore(dayjs(new Date()).format("YYYY-MM-DD"))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="titulo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Duración del examen (minutos)
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInputNumber
                    label="duracion"
                    placeholder="Ingrese la duración del examen"
                    name="duracion"
                    value={estado.duracion}
                    readOnly={estado.readOnly}
                    onChange={(e) => handleFieldChange("duracion", e.target.value)}
                    error={estado.duracion && (estado.duracion < 1 || estado.duracion >= 1440)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="textos">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Textos, consignas y/o instrucciones
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="textos"
                    placeholder="Ingrese aquí las consignas, textos útiles o instrucciones del examen"
                    type="text"
                    name="textos"
                    value={estado.textos}
                    readOnly={estado.readOnly}
                    onChange={(e) => handleFieldChange("textos", e.target.value)}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                  <SoftTypography variant="h6">Preguntas del examen</SoftTypography>
                  <SoftButton
                    circular
                    color="primary"
                    onClick={handleAddGrupo}
                    disabled={estado.readOnly}
                  >
                    <Icon>add</Icon>
                    &nbsp;Agregar grupo
                  </SoftButton>
                </SoftBox>
              </Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                {estado.grupos.length ? (
                  estado.grupos.map((grupo, grupoIndex) => (
                    <Grid item xs={12} key={grupo.id}>
                      <Card>
                        <SoftBox p={3}>
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <SoftBox display="flex" alignItems="center">
                              <SoftTypography variant="h6">
                                Grupo de preguntas Nº{grupoIndex + 1}&nbsp;
                              </SoftTypography>
                              <Tooltip
                                title="Solo se mostrará una pregunta en el examen"
                                placement="top"
                              >
                                <Icon
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                >
                                  help_outline
                                </Icon>
                              </Tooltip>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              alignItems="center"
                              justifyContent="end"
                              gap={1}
                            >
                              <Tooltip title="Puntaje de la pregunta" placement="top">
                                <SoftBox
                                  mr={1}
                                  sx={{
                                    width: "150px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <SoftInputNumber
                                    label="puntaje"
                                    placeholder="Puntaje"
                                    name="puntaje"
                                    readOnly={estado.readOnly}
                                    value={grupo.puntaje || ""}
                                    icon={{
                                      component: "grade",
                                      direction: "left",
                                    }}
                                    onChange={(e) =>
                                      dispatch({
                                        type: "UPDATE_GRUPO",
                                        index: grupoIndex,
                                        payload: {
                                          puntaje: e.target.value,
                                        },
                                      })
                                    }
                                    error={
                                      grupo.puntaje && (grupo.puntaje < 1 || grupo.puntaje > 100)
                                    }
                                  />
                                </SoftBox>
                              </Tooltip>
                              <Tooltip title="Agregar pregunta" placement="top">
                                <SoftButton
                                  circular
                                  color="uproYellow"
                                  iconOnly
                                  disabled={estado.readOnly}
                                  onClick={() => handleAddPregunta(grupoIndex)}
                                >
                                  <Icon>add</Icon>
                                </SoftButton>
                              </Tooltip>
                              <Tooltip title="Eliminar grupo" placement="top">
                                <SoftButton
                                  circular
                                  color="primary"
                                  iconOnly
                                  disabled={estado.readOnly}
                                  onClick={() => handleRemoveGrupo(grupoIndex)}
                                >
                                  <Icon>delete</Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox mt={2}>
                            <Droppable droppableId={`${grupoIndex}`}>
                              {(provided) => (
                                <Grid
                                  container
                                  spacing={2}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {grupo.preguntas.map((pregunta, preguntaIndex) => (
                                    <Draggable
                                      key={pregunta.id}
                                      draggableId={`${pregunta.id}`}
                                      index={preguntaIndex}
                                      isDragDisabled={estado.readOnly}
                                    >
                                      {(provided) => (
                                        <Grid
                                          item
                                          xs={12}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Card sx={{ border: "0.0625rem solid #d2d6da" }}>
                                            <SoftBox>
                                              <SoftBox
                                                display="flex"
                                                justifyContent="space-between"
                                              >
                                                <Tooltip
                                                  title={
                                                    estado.readOnly ? "" : "Mantener para arrastrar"
                                                  }
                                                  placement="top"
                                                >
                                                  <SoftBox
                                                    display="flex"
                                                    alignItems="center"
                                                    p={2}
                                                    sx={{
                                                      backgroundColor: getUproColor("uproPink"),
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <SoftTypography variant="h6" color="white">
                                                      {preguntaIndex + 1}
                                                    </SoftTypography>
                                                  </SoftBox>
                                                </Tooltip>
                                                <Grid container spacing={2} p={3}>
                                                  {pregunta.archivo && (
                                                    <Grid item xs={12}>
                                                      <SoftBox
                                                        component="img"
                                                        src={`${API_URL}/${pregunta.archivo}`}
                                                        alt={pregunta.titulo}
                                                        borderRadius="lg"
                                                        sx={{
                                                          height: {
                                                            xs: "auto",
                                                            sm: "200px",
                                                          },
                                                        }}
                                                      />
                                                    </Grid>
                                                  )}
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                  >
                                                    <SoftBox sx={{ width: "100%" }} mr={2}>
                                                      <SoftInput
                                                        label="titulo"
                                                        placeholder="Ingrese una pregunta"
                                                        type="text"
                                                        name="titulo"
                                                        readOnly={estado.readOnly}
                                                        icon={{
                                                          component: "question_mark",
                                                          direction: "left",
                                                        }}
                                                        value={pregunta.titulo || ""}
                                                        onChange={(e) =>
                                                          dispatch({
                                                            type: "UPDATE_GRUPO",
                                                            index: grupoIndex,
                                                            payload: {
                                                              preguntas: grupo.preguntas.map(
                                                                (p, i) =>
                                                                  i === preguntaIndex
                                                                    ? {
                                                                        ...p,
                                                                        titulo: e.target.value,
                                                                      }
                                                                    : p
                                                              ),
                                                            },
                                                          })
                                                        }
                                                      />
                                                    </SoftBox>
                                                    <SoftBox mr={2}>
                                                      <Select
                                                        label="tipoPregunta"
                                                        value={pregunta.tipo || ""}
                                                        readOnly={estado.readOnly}
                                                        onChange={(e) =>
                                                          handleSetTipoPregunta(
                                                            grupoIndex,
                                                            preguntaIndex,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <MenuItem value="multipleChoice">
                                                          Multiple choice
                                                        </MenuItem>
                                                        <MenuItem value="desarrollar">
                                                          A desarrollar
                                                        </MenuItem>
                                                      </Select>
                                                    </SoftBox>
                                                    <SoftBox
                                                      display="flex"
                                                      justifyContent="flex-end"
                                                      gap={1}
                                                    >
                                                      <Tooltip
                                                        placement="top"
                                                        title={
                                                          pregunta.archivo
                                                            ? "Eliminar imagen"
                                                            : "Agregar imágen"
                                                        }
                                                      >
                                                        <SoftButton
                                                          color={
                                                            pregunta.archivo
                                                              ? "primary"
                                                              : "uproBlue"
                                                          }
                                                          circular
                                                          iconOnly
                                                          disabled={estado.readOnly}
                                                          onClick={() => {
                                                            if (pregunta.archivo) {
                                                              dispatch({
                                                                type: "UPDATE_GRUPO",
                                                                index: grupoIndex,
                                                                payload: {
                                                                  preguntas: grupo.preguntas.map(
                                                                    (p, i) =>
                                                                      i === preguntaIndex
                                                                        ? {
                                                                            ...p,
                                                                            archivo: null,
                                                                          }
                                                                        : p
                                                                  ),
                                                                },
                                                              });
                                                            } else {
                                                              document.getElementById(
                                                                `pregunta-${grupoIndex}-${preguntaIndex}`
                                                              ).value = null;
                                                              document
                                                                .getElementById(
                                                                  `pregunta-${grupoIndex}-${preguntaIndex}`
                                                                )
                                                                .click();
                                                            }
                                                          }}
                                                        >
                                                          <Icon>
                                                            {pregunta.archivo ? "delete" : "image"}
                                                          </Icon>
                                                        </SoftButton>
                                                      </Tooltip>
                                                      <input
                                                        id={`pregunta-${grupoIndex}-${preguntaIndex}`}
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                          const file = e.target.files[0];
                                                          if (file && file.size <= 5000000) {
                                                            saveExamenFile({
                                                              variables: {
                                                                file,
                                                              },
                                                            }).then((res) => {
                                                              console.log(res);
                                                              dispatch({
                                                                type: "UPDATE_GRUPO",
                                                                index: grupoIndex,
                                                                payload: {
                                                                  preguntas: grupo.preguntas.map(
                                                                    (p, i) =>
                                                                      i === preguntaIndex
                                                                        ? {
                                                                            ...p,
                                                                            archivo:
                                                                              res.data
                                                                                .saveExamenFile,
                                                                          }
                                                                        : p
                                                                  ),
                                                                },
                                                              });
                                                            });
                                                          } else {
                                                            handleSnackbar(
                                                              "El archivo debe ser menor a 5MB",
                                                              "error"
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <Tooltip
                                                        placement="top"
                                                        title="Agregar opción"
                                                      >
                                                        <SoftButton
                                                          color="uproYellow"
                                                          circular
                                                          iconOnly
                                                          onClick={() =>
                                                            handleAddOpcion(
                                                              grupoIndex,
                                                              preguntaIndex
                                                            )
                                                          }
                                                          disabled={
                                                            pregunta.tipo === "desarrollar" ||
                                                            estado.readOnly
                                                          }
                                                        >
                                                          <Icon>add</Icon>
                                                        </SoftButton>
                                                      </Tooltip>
                                                      <Tooltip
                                                        placement="top"
                                                        title="Eliminar pregunta"
                                                        onClick={() =>
                                                          handleRemovePregunta(
                                                            grupoIndex,
                                                            preguntaIndex
                                                          )
                                                        }
                                                        disabled={
                                                          grupo.preguntas.length === 1 ||
                                                          estado.readOnly
                                                        }
                                                      >
                                                        <SoftButton
                                                          color="primary"
                                                          circular
                                                          iconOnly
                                                        >
                                                          <Icon>delete</Icon>
                                                        </SoftButton>
                                                      </Tooltip>
                                                    </SoftBox>
                                                  </Grid>
                                                  {pregunta.tipo === "multipleChoice" &&
                                                    pregunta.opciones.map((opcion, opcionIndex) => (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        key={opcionIndex}
                                                      >
                                                        <SoftBox mr={1}>
                                                          <Icon>subdirectory_arrow_right</Icon>
                                                        </SoftBox>
                                                        <SoftBox sx={{ width: "100%" }} mr={2}>
                                                          <SoftInput
                                                            label="opcion"
                                                            placeholder="Ingrese una opcion"
                                                            type="text"
                                                            name="opcion"
                                                            readOnly={estado.readOnly}
                                                            value={opcion.opcion || ""}
                                                            onChange={(e) =>
                                                              dispatch({
                                                                type: "UPDATE_GRUPO",
                                                                index: grupoIndex,
                                                                payload: {
                                                                  preguntas: grupo.preguntas.map(
                                                                    (p, i) =>
                                                                      i === preguntaIndex
                                                                        ? {
                                                                            ...p,
                                                                            opciones:
                                                                              p.opciones.map(
                                                                                (o, j) =>
                                                                                  j === opcionIndex
                                                                                    ? {
                                                                                        ...o,
                                                                                        opcion:
                                                                                          e.target
                                                                                            .value,
                                                                                      }
                                                                                    : o
                                                                              ),
                                                                          }
                                                                        : p
                                                                  ),
                                                                },
                                                              })
                                                            }
                                                          />
                                                        </SoftBox>
                                                        <SoftBox
                                                          display="flex"
                                                          justifyContent="flex-end"
                                                          gap={1}
                                                        >
                                                          <Tooltip
                                                            placement="top"
                                                            title={
                                                              opcion.correcta
                                                                ? "Respuesta correcta"
                                                                : estado.readOnly
                                                                ? ""
                                                                : "Marcar como correcta"
                                                            }
                                                          >
                                                            <SoftButton
                                                              color={
                                                                opcion.correcta
                                                                  ? "uproGreen"
                                                                  : "secondary"
                                                              }
                                                              circular
                                                              iconOnly
                                                              disabled={
                                                                estado.readOnly && !opcion.correcta
                                                              }
                                                              onClick={() =>
                                                                estado.readOnly ||
                                                                handleSetCorrecta(
                                                                  grupoIndex,
                                                                  preguntaIndex,
                                                                  opcionIndex
                                                                )
                                                              }
                                                            >
                                                              <Icon>done</Icon>
                                                            </SoftButton>
                                                          </Tooltip>
                                                          <Tooltip
                                                            placement="top"
                                                            title="Eliminar opcion"
                                                            disabled={estado.readOnly}
                                                            onClick={() =>
                                                              handleRemoveOpcion(
                                                                grupoIndex,
                                                                preguntaIndex,
                                                                opcionIndex
                                                              )
                                                            }
                                                          >
                                                            <SoftButton
                                                              color="primary"
                                                              circular
                                                              iconOnly
                                                            >
                                                              <Icon>delete</Icon>
                                                            </SoftButton>
                                                          </Tooltip>
                                                        </SoftBox>
                                                      </Grid>
                                                    ))}
                                                </Grid>
                                              </SoftBox>
                                            </SoftBox>
                                          </Card>
                                        </Grid>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </Grid>
                              )}
                            </Droppable>
                          </SoftBox>
                        </SoftBox>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <SinDatos />
                  </Grid>
                )}
              </DragDropContext>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                  <SoftBox mr={2}>
                    <Link to="/carreras">
                      <SoftButton circular color="primary">
                        <Icon>arrow_back</Icon>
                        &nbsp;{estado.readOnly ? "Volver" : "Cancelar"}
                      </SoftButton>
                    </Link>
                  </SoftBox>
                  <SoftBox>
                    <SoftButton
                      circular
                      color="uproGreen"
                      disabled={loading || !materia.id || estado.readOnly}
                      onClick={() => {
                        validateForm() &&
                          saveExamen({
                            variables: {
                              input: {
                                id: estado.id || null,
                                titulo: estado.titulo,
                                //Guardar los textos formateados con saltos de linea
                                textos: estado.textos,
                                tipo: estado.tipo,
                                modalidad: estado.modalidad,
                                ordenAleatorioPreguntas: estado.ordenAleatorioPreguntas === 1,
                                ordenAleatorioRespuestas: estado.ordenAleatorioRespuestas === 1,
                                fecha: dayjs(estado.fecha).format("YYYY-MM-DDTHH:mm:ss"),
                                duracion: estado.duracion,
                                idMateria: materia.id,
                                grupos: estado.grupos.map((grupo, indexGrupo) => ({
                                  orden: indexGrupo + 1,
                                  puntaje: grupo.puntaje,
                                  preguntas: grupo.preguntas.map((pregunta, indexPregunta) => ({
                                    orden: indexPregunta + 1,
                                    titulo: pregunta.titulo,
                                    tipo: pregunta.tipo,
                                    archivo: pregunta.archivo,
                                    opciones: pregunta.opciones.map((opcion) => ({
                                      opcion: opcion.opcion,
                                      correcta: opcion.correcta,
                                    })),
                                  })),
                                })),
                              },
                            },
                          })
                            .then((data) => {
                              setOpenSuccessModal(true);
                              dispatch({
                                type: "SET_EXAMEN_ID",
                                payload: data.data.saveExamen.id,
                              });
                            })
                            .catch((e) => {
                              handleSnackbar(
                                e.message || "Ocurrió un error al guardar el examen.",
                                "error"
                              );
                            });
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          size={15}
                          sx={{
                            marginRight: 0.5,
                          }}
                          color="inherit"
                        />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>save</Icon>
                      )}
                      &nbsp;Guardar
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
        <ModalExito
          open={openSuccessModal}
          handleClose={() => {
            setOpenSuccessModal(false);
          }}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default React.memo(CreadorExamenes);
