// @mui material components
import { Card, Collapse, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MenuContext } from "context/menuContext";
import { UserContext } from "context/user";
import ModalRevision from "layouts/autogestion/components/ModalRevision";
import ModalRevisionAdaptada from "layouts/autogestion/components/ModalRevisionAdaptada";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import ModalInscripcionAdaptada from "../ModalInscripcionAdaptada";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "60%" },
  overflow: "auto",
};

export default function ModalHistoriaAcademica({ open, handleClose, idAlumno }) {
  const [files, setFiles] = useState([]);
  const [materias, setMaterias] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const [expanded, setExpanded] = useState(["Historia Académica"]);
  const [expandedMaterias, setExpandedMaterias] = useState([]);
  const [expandSelectedCarrera, setExpandSelectedCarrera] = useState(false);
  const [openModalVerExamen, setOpenModalVerExamen] = useState(false);
  const [openModalVerExamenAdaptado, setOpenModalVerExamenAdaptado] = useState(false);
  const [openModalInscripcionAdaptada, setOpenModalInscripcionAdaptada] = useState(false);
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [carreras, setCarreras] = useState([]);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const { menu } = useContext(MenuContext);
  const { confirmar } = useContext(ConfirmarContext);
  const [eliminarInscripcion, { loading: loadingEliminarInscripcion }] = useMutation(gql`
    mutation eliminarInscripcion($id: ID!) {
      eliminarInscripcion(id: $id)
    }
  `);
  const { verificarAcceso } = useContext(UserContext);

  const [getUser, { data, loading, refetch }] = useLazyQuery(
    gql`
      query getUser($id: ID!) {
        usuario(id: $id) {
          nombre
          apellido
          documentos {
            id
            nombre
            file
            createdAt
            estado
          }
          inscripcionesExamenes {
            id
            estado
            adaptacionExamen
            examen {
              id
              titulo
              materia {
                id
                nombre
                nivel {
                  planEstudio {
                    carrera {
                      id
                      nombre
                    }
                  }
                }
              }
            }
            acta {
              id
              createdAt
              estado
            }
            createdAt
          }
          cursadoAdaptado
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (idAlumno && open) {
      getUser({ variables: { id: idAlumno } });
    }
  }, [idAlumno, open]);

  useEffect(() => {
    if (data) {
      setFiles(data.usuario.documentos);
      setMaterias(
        Object.values(
          data.usuario.inscripcionesExamenes.reduce((acc, inscripcion) => {
            if (!acc[inscripcion.examen.materia.id]) {
              acc[inscripcion.examen.materia.id] = {
                materia: inscripcion.examen.materia,
                carrera: inscripcion.examen.materia.nivel.planEstudio.carrera,
                examenes: [],
              };
            }
            acc[inscripcion.examen.materia.id].examenes.push(inscripcion);
            return acc;
          }, {})
        )
      );
      setCarreras(
        data.usuario.inscripcionesExamenes.reduce((acc, inscripcion) => {
          if (
            !acc.find(
              (carrera) => carrera.id === inscripcion.examen.materia.nivel.planEstudio.carrera.id
            )
          ) {
            acc.push(inscripcion.examen.materia.nivel.planEstudio.carrera);
          }
          return acc;
        }, [])
      );
    }
  }, [data]);

  useEffect(() => {
    if (carreras.length) {
      setSelectedCarrera(carreras[0]);
    }
  }, [carreras]);

  console.log(data);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
              <SoftTypography variant="h6">Historia Académica</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <SoftBox display="flex" justifyContent="end" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Historia Académica")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">description</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {`Historia académica de ${makeNiceText(
                            `${data?.usuario?.apellido} ${data?.usuario?.nombre}`,
                            "nombre"
                          )}`}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                        {data?.usuario?.cursadoAdaptado && verificarAcceso(27) && (
                          <Tooltip title="Agregar nota de exámen adaptado" placement="top">
                            <SoftButton
                              color="primary"
                              circular
                              onClick={() => setOpenModalInscripcionAdaptada(true)}
                            >
                              Examen adaptado&nbsp;
                              <Icon>add</Icon>
                            </SoftButton>
                          </Tooltip>
                        )}
                        <SoftButton
                          color="uproGreen"
                          circular
                          onClick={(event) => {
                            setExpandSelectedCarrera(!expandSelectedCarrera);
                            menu({
                              open: event.currentTarget,
                              align: "right",
                              options: carreras.map((carrera) => ({
                                name: carrera.nombre,
                                icon: {
                                  icon: "school",
                                  color:
                                    selectedCarrera.id === carrera.id
                                      ? getUproColor("uproPink")
                                      : getUproColor("uproGreen"),
                                },
                                onClick: () => {
                                  setSelectedCarrera(carrera);
                                  setExpandSelectedCarrera(false);
                                },
                              })),
                              handleClose: () => {},
                              onClose: () => {
                                setExpandSelectedCarrera(false);
                              },
                            });
                          }}
                        >
                          <Icon
                            sx={{
                              rotate: expandSelectedCarrera ? "180deg" : "0deg",
                              transition: "all 0.5s",
                            }}
                          >
                            expand_more
                          </Icon>
                          &nbsp;{selectedCarrera?.nombre || "Seleccione una carrera"}&nbsp;
                          <Icon>school</Icon>
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Historia Académica")}>
                      {loading ? (
                        <Loading />
                      ) : materias?.length > 0 ? (
                        materias?.map((materia, index) => (
                          <SoftBox key={materia.materia.id}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  materias?.length - 1 === index &&
                                  !expandedMaterias.includes(materia.materia.id)
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox display="flex" justifyContent="space-between">
                                <SoftBox display="flex" alignItems="center" pl={2}>
                                  <SoftBox mr={1}>
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox mr={1}>
                                    <Tooltip title={"Unidad de competencia"} placement="top">
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproYellow"),
                                        }}
                                      >
                                        file_present
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">
                                    {materia.materia.nombre}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                  <Tooltip
                                    title={
                                      expandedMaterias.includes(materia.materia.id)
                                        ? "Contraer materia"
                                        : "Expandir materia"
                                    }
                                    placement="top"
                                  >
                                    <SoftButton
                                      color="primary"
                                      circular
                                      iconOnly
                                      disabled={!materia.examenes?.length}
                                      onClick={() =>
                                        setExpandedMaterias(
                                          expandedMaterias.includes(materia.materia.id)
                                            ? expandedMaterias.filter(
                                                (i) => i !== materia.materia.id
                                              )
                                            : [...expandedMaterias, materia.materia.id]
                                        )
                                      }
                                    >
                                      <Icon
                                        sx={{
                                          rotate: expandedMaterias.includes(materia.materia.id)
                                            ? "180deg"
                                            : "0deg",
                                          transition: "all 0.5s",
                                        }}
                                      >
                                        expand_more
                                      </Icon>
                                    </SoftButton>
                                  </Tooltip>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                            <Collapse in={expandedMaterias.includes(materia.materia.id)}>
                              {materia.examenes?.map((examen, indexEx) => (
                                <SoftBox
                                  key={examen.id}
                                  px={2}
                                  py={1}
                                  sx={{
                                    borderBottom:
                                      !expandedMaterias.includes(materia.materia.id) &&
                                      !materias?.examenes?.length
                                        ? "none"
                                        : "1px solid rgba(0, 0, 0, 0.12)",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" justifyContent="space-between">
                                    <SoftBox display="flex" alignItems="center" pl={4}>
                                      <SoftBox mr={1}>
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproYellow"),
                                          }}
                                        >
                                          subdirectory_arrow_right
                                        </Icon>
                                      </SoftBox>
                                      <SoftBox mr={1}>
                                        <Tooltip title="Examen" placement="top">
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproGreen"),
                                            }}
                                          >
                                            quizz
                                          </Icon>
                                        </Tooltip>
                                      </SoftBox>
                                      <SoftTypography variant="h6">
                                        {examen.examen.titulo}
                                      </SoftTypography>
                                      <SoftBox ml={1}>
                                        <SoftBadge
                                          variant="color"
                                          color={
                                            examen?.estado === "Habilitado"
                                              ? getUproColor("uproPink")
                                              : examen?.estado === "En curso"
                                              ? getUproColor("uproYellow")
                                              : examen?.estado === "Finalizado"
                                              ? getUproColor("uproGreen")
                                              : examen?.estado === "Deshabilitado"
                                              ? getUproColor("secondary")
                                              : examen?.estado === "Ausente"
                                              ? getUproColor("uproBlue")
                                              : getUproColor("secondary")
                                          }
                                          badgeContent={examen.estado}
                                        />
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="flex-end"
                                      alignItems="center"
                                      gap={1}
                                    >
                                      {examen.acta && (
                                        <SoftBox>
                                          <Tooltip title="Ver examen" placement="top">
                                            <SoftButton
                                              color="uproGreen"
                                              circular
                                              iconOnly
                                              disabled={
                                                !examen.acta || examen?.estado === "Ausente"
                                              }
                                              onClick={() => {
                                                setSelectedExamen(examen);
                                                examen.adaptacionExamen
                                                  ? setOpenModalVerExamenAdaptado(true)
                                                  : setOpenModalVerExamen(true);
                                              }}
                                            >
                                              <Icon>visibility</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      )}
                                      {verificarAcceso(28) && (
                                        <SoftBox>
                                          <Tooltip title="Dar de baja inscripción" placement="top">
                                            <SoftButton
                                              color="uproYellow"
                                              disabled={loadingEliminarInscripcion}
                                              circular
                                              iconOnly
                                              onClick={() => {
                                                confirmar({
                                                  title: "Dar de baja inscripción",
                                                  message: `¿Está seguro que quieres eliminar la inscripción al examen ${materia?.materia?.nombre} - ${examen?.examen?.titulo}?`,
                                                  icon: "quiz",
                                                  func: () => {
                                                    eliminarInscripcion({
                                                      variables: { id: examen.id },
                                                    })
                                                      .then((response) => {
                                                        if (response?.data?.eliminarInscripcion) {
                                                          getUser({ variables: { id: idAlumno } });
                                                          handleSnackbar(
                                                            "Inscripción eliminada correctamente",
                                                            "success"
                                                          );
                                                        } else {
                                                          handleSnackbar(
                                                            "Ha ocurrido un error al intentar comenzar el examen",
                                                            "error"
                                                          );
                                                        }
                                                      })
                                                      .catch((e) => {
                                                        handleSnackbar(
                                                          e.message ||
                                                            "Ha ocurrido un error al intentar comenzar el examen",
                                                          "error"
                                                        );
                                                      });
                                                  },
                                                });
                                              }}
                                            >
                                              <Icon>arrow_downward</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      )}
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              ))}
                            </Collapse>
                          </SoftBox>
                        ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
            <ModalRevision
              open={openModalVerExamen}
              handleClose={() => setOpenModalVerExamen(false)}
              idInscripcion={selectedExamen?.id}
              refetch={refetch}
              readOnly={true}
            />
            <ModalRevisionAdaptada
              open={openModalVerExamenAdaptado}
              handleClose={() => setOpenModalVerExamenAdaptado(false)}
              idInscripcion={selectedExamen?.id}
              refetch={refetch}
              readOnly={true}
            />
            <ModalInscripcionAdaptada
              open={openModalInscripcionAdaptada}
              handleClose={() => setOpenModalInscripcionAdaptada(false)}
              idAlumno={idAlumno}
              idCarrera={selectedCarrera?.id}
              refetch={refetch}
            />
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalHistoriaAcademica.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idAlumno: PropTypes.string,
};
