// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, CircularProgress, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import dataInscripciones from "layouts/autogestion-docente/data/dataInscripciones";
import ModalRevision from "../ModalRevision";
import ModalVerificarExamen from "../ModalVerificarExamen";
import { MenuContext } from "context/menuContext";
import { getUproColor } from "utils/colors";
import ModalActa from "../ModalActa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "80%" },
  overflowY: "auto",
};

export default function ModalExamen({ open, handleClose, refetch, selectedExamen, comisiones }) {
  const [rows, setRows] = useState([]);
  const { columns, getRows } = dataInscripciones;
  const [selectedInscripcion, setSelectedInscripcion] = useState(null);
  const [openModalVerificacion, setOpenModalVerificacion] = useState(false);
  const [openModalVerExamen, setOpenModalVerExamen] = useState(false);
  const [inscriptos, setInscriptos] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [selectedComision, setSelectedComision] = useState(null);
  const [expandComisiones, setExpandComisiones] = useState(false);
  const [openModalActa, setOpenModalActa] = useState(false);

  const { menu } = useContext(MenuContext);

  const [cerrarActasExamen, { loading: loadingCerrarActas }] = useMutation(
    gql`
      mutation cerrarActasExamen($idExamen: ID!, $idComision: ID!) {
        cerrarActasExamen(idExamen: $idExamen, idComision: $idComision) {
          id
          estado
          nota
        }
      }
    `
  );

  const [finalizarExamen, { loading: loadingFinalizar }] = useMutation(
    gql`
      mutation finalizarExamen($idInscripcion: ID!) {
        finalizarExamen(idInscripcion: $idInscripcion)
      }
    `
  );
  const { handleSnackbar } = useContext(MessageManager);
  const { confirmar } = useContext(ConfirmarContext);

  const [habilitarExamen, { loading: loadingVerificar }] = useMutation(gql`
    mutation habilitarExamen($idInscripcion: ID!) {
      habilitarExamen(idInscripcion: $idInscripcion)
    }
  `);

  const handleHabilitarExamen = (inscripcion) => {
    setSelectedInscripcion(inscripcion);
    confirmar({
      title: "Habilitar examen",
      message: `¿Está seguro que desea habilitar el examen para el alumno ${inscripcion.alumno.nombre} ${inscripcion.alumno.apellido}? Una vez habilitado, el alumno podrá realizar el examen desde su autogestión.`,
      icon: "done_all",
      func: () => {
        habilitarExamen({
          variables: {
            idInscripcion: inscripcion.id,
          },
        })
          .then((res) => {
            if (res.data.habilitarExamen) {
              handleSnackbar("Alumno habilitado para el examen correctamente", "success");
              getInscriptos({
                variables: {
                  id: selectedExamen.id,
                  filter: { idComision: selectedComision.id },
                },
              }).then((res) => {
                setInscriptos(res.data.examen.inscripciones);
                setPagesInfo({
                  total: res.data.examen.inscripciones.length,
                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                });
              });
            }
          })
          .catch((err) => {
            handleSnackbar(err.message || "Ha ocurrido un error al habilitar el examen", "error");
          });
      },
    });
    // setOpenModalVerificacion(true);
  };

  const handleVerExamen = (inscripcion) => {
    setSelectedInscripcion(inscripcion);
    setOpenModalVerExamen(true);
  };

  const handleFinalizarExamen = (inscripcion) => {
    setSelectedInscripcion(inscripcion);
    confirmar({
      title: "Finalizar examen",
      message: `¿Está seguro que desea finalizar el examen? El alumno no podrá continuar con el mismo. Esta acción no se puede deshacer.`,
      icon: "done_all",
      func: () => {
        finalizarExamen({ variables: { idInscripcion: inscripcion.id } })
          .then(() => {
            getInscriptos({
              variables: {
                id: selectedExamen.id,
                filter: { idComision: selectedComision.id },
              },
            }).then((res) => {
              setInscriptos(res.data.examen.inscripciones);
              setPagesInfo({
                total: res.data.examen.inscripciones.length,
                pages: Math.ceil(res.data.examen.inscripciones.length / 10),
              });
            });
            setSelectedInscripcion(null);
            handleSnackbar("Examen finalizado correctamente", "success");
          })
          .catch((error) => {
            handleSnackbar(error.message || "Ha ocurrido un error al finalizar el examen", "error");
          });
      },
    });
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [getInscriptos, { data, loading }] = useLazyQuery(
    gql`
      query getExamen($id: ID!, $filter: JSON) {
        examen(id: $id) {
          id
          estado
          inscripciones(filter: $filter) {
            id
            estado
            nota
            examen {
              id
              fecha
            }
            alumno {
              id
              nombre
              apellido
              dni
            }
            acta {
              id
              estado
              nota
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getActas, { data: dataActas }] = useLazyQuery(
    gql`
      query actas($idExamen: ID!, $idComision: ID!) {
        actas(idExamen: $idExamen, idComision: $idComision) {
          id
          examen {
            id
            titulo
            fecha
            materia {
              id
              nombre
              codigo
              nivel {
                id
                nombre
                codigo
                planEstudio {
                  codigo
                  nombre
                  carrera {
                    id
                    nombre
                    codigo
                    tipo
                  }
                }
              }
            }
          }
          comision {
            id
            codigo
            nombre
          }
          docente {
            id
            nombre
            apellido
          }
          cantidadAlumnos
          cantidadAprobados
          cantidadDesaprobados
          createdAt
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open && selectedExamen && selectedComision) {
      getInscriptos({
        variables: {
          id: selectedExamen.id,
          filter: { idComision: selectedComision.id },
        },
      }).then((res) => {
        setInscriptos(res.data.examen.inscripciones);
        setPagesInfo({
          total: res.data.examen.inscripciones.length,
          pages: Math.ceil(res.data.examen.inscripciones.length / 10),
        });
      });
      getActas({
        variables: {
          idExamen: selectedExamen.id,
          idComision: selectedComision.id,
        },
      });
    }
  }, [open, selectedExamen, selectedComision]);

  useEffect(() => {
    if (inscriptos) {
      let inscriptosFiltered = inscriptos;
      if (search) {
        inscriptosFiltered = inscriptosFiltered.filter((inscripcion) => {
          const nombre = `${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`;
          return nombre.toLowerCase().includes(search.toLowerCase());
        });
      }
      inscriptosFiltered = inscriptosFiltered.slice((page - 1) * 10, page * 10);

      setRows(
        getRows(inscriptosFiltered, handleHabilitarExamen, handleVerExamen, handleFinalizarExamen)
      );
    }
  }, [inscriptos, search, page]);

  useEffect(() => {
    if (comisiones && comisiones.length > 0) {
      setSelectedComision(comisiones[0]);
    }
  }, [comisiones]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftBox display="flex" alignItems="center" gap={1}>
                        <SoftTypography variant="h6">
                          Inscripciones al examen {selectedExamen?.titulo}
                        </SoftTypography>
                        {data?.examen?.estado === "Finalizado" && (
                          <SoftBadge color={"primary"} badgeContent={data?.examen.estado} />
                        )}
                      </SoftBox>

                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                        mr={2}
                      >
                        <Tooltip
                          title={
                            dataActas?.actas?.length > 0
                              ? "Ver actas de examen"
                              : "Cerrar actas de examen"
                          }
                          placement="top"
                        >
                          <SoftButton
                            color={dataActas?.actas?.length > 0 ? "primary" : "uproGreen"}
                            circular
                            iconOnly
                            disabled={
                              inscriptos.some((inscripcion) => inscripcion.estado === "En curso") ||
                              data?.examen.estado === "Finalizado" ||
                              loading ||
                              loadingCerrarActas ||
                              !selectedComision ||
                              rows.length === 0
                            }
                            onClick={
                              dataActas?.actas?.length > 0
                                ? () => {
                                    setOpenModalActa(true);
                                  }
                                : () => {
                                    confirmar({
                                      title: "Cerrar actas de examen",
                                      message: `¿Está seguro que desea cerrar las actas de examen? Una vez cerradas, no podrá habilitar a los alumnos para rendir el examen. Esta acción no se puede deshacer.`,
                                      icon: "done_all",
                                      func: () => {
                                        cerrarActasExamen({
                                          variables: {
                                            idExamen: selectedExamen.id,
                                            idComision: selectedComision.id,
                                          },
                                        })
                                          .then(() => {
                                            handleSnackbar(
                                              "Actas cerradas correctamente",
                                              "success"
                                            );
                                            refetch();
                                            getInscriptos({
                                              variables: {
                                                id: selectedExamen.id,
                                                filter: { idComision: selectedComision.id },
                                              },
                                            }).then((res) => {
                                              setInscriptos(res.data.examen.inscripciones);
                                              setPagesInfo({
                                                total: res.data.examen.inscripciones.length,
                                                pages: Math.ceil(
                                                  res.data.examen.inscripciones.length / 10
                                                ),
                                              });
                                            });
                                          })
                                          .catch((error) => {
                                            handleSnackbar(
                                              error.message ||
                                                "Ha ocurrido un error al cerrar las actas",
                                              "error"
                                            );
                                          });
                                      },
                                    });
                                  }
                            }
                          >
                            {loadingCerrarActas ? (
                              <CircularProgress size={15} color="inherit" />
                            ) : (
                              <Icon>
                                {dataActas?.actas?.length > 0 ? "description" : "done_all"}
                              </Icon>
                            )}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip title="Actualizar tabla" placement="top">
                          <SoftButton
                            color="uproYellow"
                            circular
                            iconOnly
                            onClick={() => {
                              getInscriptos({
                                variables: {
                                  id: selectedExamen.id,
                                  filter: { idComision: selectedComision.id },
                                },
                              }).then((res) => {
                                setInscriptos(res.data.examen.inscripciones);
                                setPagesInfo({
                                  total: res.data.examen.inscripciones.length,
                                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                                });
                              });
                            }}
                          >
                            <Icon>refresh</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftBox
                        mr={{
                          xs: 0,
                          sm: 2,
                        }}
                      >
                        <Tooltip title="Seleccione la comisión" placement="top">
                          <SoftButton
                            color="uproYellow"
                            circular
                            sx={{
                              textWrap: "nowrap",
                            }}
                            fullWidth={{
                              xs: true,
                              sm: false,
                            }}
                            disabled={loading || !comisiones || comisiones.length === 0}
                            onClick={(event) => {
                              setExpandComisiones(true);
                              menu({
                                open: event.currentTarget,
                                align: "right",
                                onClose: () => {
                                  setExpandComisiones(false);
                                },
                                options: comisiones.map((comision) => ({
                                  name: `${comision.codigo} - ${comision.nombre}`,
                                  icon: {
                                    icon: "school",
                                    color:
                                      selectedComision?.id === comision.id
                                        ? getUproColor("uproYellow")
                                        : getUproColor("uproGreen"),
                                  },
                                  onClick: () => {
                                    setSelectedComision(comision);
                                  },
                                })),
                              });
                            }}
                          >
                            {loading ? (
                              <CircularProgress size={15} color="inherit" />
                            ) : (
                              <>
                                <Icon>school</Icon>
                                &nbsp;
                                {selectedComision?.nombre
                                  ? `${selectedComision?.codigo} - ${selectedComision?.nombre}`
                                  : "Seleccione una comision"}
                                &nbsp;
                                <Icon
                                  sx={{
                                    rotate: expandComisiones ? "180deg" : "0deg",
                                    transition: "all 0.5s",
                                  }}
                                >
                                  expand_more
                                </Icon>
                              </>
                            )}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        mr={{
                          xs: 0,
                          sm: 2,
                        }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "auto",
                          },
                        }}
                      >
                        <SoftInput
                          placeholder="Escribe aquí..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                        />
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                        mr={2}
                      >
                        <Tooltip
                          title={
                            dataActas?.actas?.length > 0
                              ? "Ver actas de examen"
                              : "Cerrar actas de examen"
                          }
                          placement="top"
                        >
                          <SoftButton
                            color={dataActas?.actas?.length > 0 ? "primary" : "uproGreen"}
                            circular
                            disabled={
                              inscriptos.some((inscripcion) => inscripcion.estado === "En curso") ||
                              data?.examen.estado === "Finalizado" ||
                              loading ||
                              loadingCerrarActas ||
                              !selectedComision ||
                              rows.length === 0
                            }
                            onClick={
                              dataActas?.actas?.length > 0
                                ? () => {
                                    setOpenModalActa(true);
                                  }
                                : () => {
                                    confirmar({
                                      title: "Cerrar actas de examen",
                                      message: `¿Está seguro que desea cerrar las actas de examen? Una vez cerradas, no podrá habilitar a los alumnos para rendir el examen. Esta acción no se puede deshacer.`,
                                      icon: "done_all",
                                      func: () => {
                                        cerrarActasExamen({
                                          variables: {
                                            idExamen: selectedExamen.id,
                                            idComision: selectedComision.id,
                                          },
                                        })
                                          .then(() => {
                                            handleSnackbar(
                                              "Actas cerradas correctamente",
                                              "success"
                                            );
                                            getInscriptos({
                                              variables: {
                                                id: selectedExamen.id,
                                                filter: { idComision: selectedComision.id },
                                              },
                                            }).then((res) => {
                                              setInscriptos(res.data.examen.inscripciones);
                                              setPagesInfo({
                                                total: res.data.examen.inscripciones.length,
                                                pages: Math.ceil(
                                                  res.data.examen.inscripciones.length / 10
                                                ),
                                              });
                                            });
                                          })
                                          .catch((error) => {
                                            handleSnackbar(
                                              error.message ||
                                                "Ha ocurrido un error al cerrar las actas",
                                              "error"
                                            );
                                          });
                                      },
                                    });
                                  }
                            }
                          >
                            {loadingCerrarActas ? (
                              <CircularProgress size={15} color="inherit" />
                            ) : (
                              <Icon>
                                {dataActas?.actas?.length > 0 ? "description" : "done_all"}
                              </Icon>
                            )}
                            &nbsp;{dataActas?.actas?.length > 0 ? "Ver actas" : "Cerrar actas"}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                      >
                        <Tooltip title="Actualizar tabla" placement="top">
                          <SoftButton
                            color="uproYellow"
                            circular
                            iconOnly
                            onClick={() => {
                              getInscriptos({
                                variables: {
                                  id: selectedExamen.id,
                                  filter: { idComision: selectedComision.id },
                                },
                              }).then((res) => {
                                setInscriptos(res.data.examen.inscripciones);
                                setPagesInfo({
                                  total: res.data.examen.inscripciones.length,
                                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                                });
                              });
                            }}
                          >
                            <Icon>refresh</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading || loadingCerrarActas ? (
                  <Loading />
                ) : rows?.length > 0 && selectedComision ? (
                  <DataTable columns={columns} rows={rows} />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={rows?.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
          </SoftBox>
          <ModalVerificarExamen
            open={openModalVerificacion}
            handleClose={() => {
              setOpenModalVerificacion(false);
              setSelectedInscripcion(null);
            }}
            inscripcion={selectedInscripcion}
            refetch={() => {
              getInscriptos({
                variables: {
                  id: selectedExamen.id,
                  filter: { idComision: selectedComision.id },
                },
              }).then((res) => {
                setInscriptos(res.data.examen.inscripciones);
                setPagesInfo({
                  total: res.data.examen.inscripciones.length,
                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                });
              });
            }}
          />
          <ModalRevision
            open={openModalVerExamen}
            handleClose={() => setOpenModalVerExamen(false)}
            idInscripcion={selectedInscripcion?.id}
            refetch={() => {
              getInscriptos({
                variables: {
                  id: selectedExamen.id,
                  filter: { idComision: selectedComision.id },
                },
              }).then((res) => {
                setInscriptos(res.data.examen.inscripciones);
                setPagesInfo({
                  total: res.data.examen.inscripciones.length,
                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                });
              });
            }}
            readOnly={data?.examen?.estado === "Finalizado" || dataActas?.actas?.length > 0}
          />
          <ModalActa
            open={openModalActa}
            handleClose={() => setOpenModalActa(false)}
            acta={dataActas?.actas[0]}
            alumnos={inscriptos}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalExamen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedExamen: PropTypes.object,
  comisiones: PropTypes.array,
};
